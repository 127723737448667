import axios from "axios";

import { setIsLoggedIn, updateUserData } from "../redux/authSlice";
import store from "../redux/store";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
});

instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Dispatch a Redux action to update the authentication state
        store.dispatch(setIsLoggedIn(false));
        store.dispatch(updateUserData({}));
        sessionStorage.clear();
      }
      return Promise.reject(error);
    }
  );


export default instance