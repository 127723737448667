import HighchartsReact from "highcharts-react-official";
import Highcharts, { chart } from "highcharts/highstock";
import { objectMerge } from "../../utils/Utils";

function LineChart({ options }) {
  options.credits = "enabled: false,";

  Highcharts.setOptions({
    chart: {
      style: {
        fontFamily: "Ubuntu, sans-serif",
      },
    },
  });

  const defaultOptions = {
    chart: {
      height: 260,
      type: "line",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    exporting: {
      sourceWidth: 400,
      sourceHeight: 260,
      chartOptions: {
        subtitle: null,
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    tooltip: {
      backgroundColor: "#FFFFFF",
      borderColor: "rgba(51,51,51,0.1)",
      borderRadius: 10,
      padding: 12,
      useHTML: true,
      style: {
        pointerEvents: "auto",
      },
    },
    title: {
      text: null,
    },

    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      y: 5,
      itemMarginBottom: 9,
      itemStyle: {
        fontSize: "16px",
        fontWeight: "normal",
      },
    },
  };

  const mergedOptions = objectMerge(defaultOptions, options);

  return (
    <div className="grow">
      <div>
        <HighchartsReact highcharts={Highcharts} options={mergedOptions} />
      </div>
    </div>
  );
}

export default LineChart;
