import {
  pdf,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import { minTommss } from "../../../utils/Utils";

Font.register({
  family: "Ubuntu",
  fonts: [
    {
      src: "https://pa-cdn.s3.eu-west-2.amazonaws.com/assets/fonts/ubuntu/Ubuntu-Regular.ttf",
    },
    {
      src: "https://pa-cdn.s3.eu-west-2.amazonaws.com/assets/fonts/ubuntu/Ubuntu-Bold.ttf",
      fontWeight: "bold",
    },
    {
      src: "https://pa-cdn.s3.eu-west-2.amazonaws.com/assets/fonts/ubuntu/Ubuntu-Light.ttf",
      fontWeight: "300",
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  // cover: {
  //   backgroundColor: "#F8F8F8",
  //   fontFamily: "Ubuntu",
  // },
  // logoBg: {
  //   display: "flex",
  //   alignSelf: "left",
  //   width: "120",
  // },
  // coverSection: {
  //   height: "100%",
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   flexDirection: "row",
  // },
  introContainer: {
    display: "flex",
    alignSelf: "center",
    alignItems: "center",
    marginTop: "-50",
  },
  paLogo: {
    width: "120",
    marginBottom: "35",
  },
  introCompany: {
    color: "black",
    alignSelf: "center",
  },
  introText: {
    color: "black",
    alignSelf: "center",
    fontSize: 14,
    marginTop: 15,
  },
  introDate: {
    color: "black",
    alignSelf: "center",
    fontSize: "14",
    marginTop: "5",
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#F8F8F8",
    fontSize: 12,
    padding: 30,
    fontFamily: "Ubuntu",
    color: "#333333",
    fontWeight: "300",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  rowView: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 7,
    paddingBottom: 7,
    fontSize: 11,
  },
  oddRow: {
    backgroundColor: "#f9f9fa",
  },
  colKey: {
    width: "70%",
  },
  colValue: {
    width: "30%",
  },
  topHeading: {
    marginBottom: 15,
    fontSize: 17,
    color: "#ec6401",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  heading: {
    marginBottom: 10,
    fontSize: 14,
    color: "#4c3c61",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  card: {
    backgroundColor: "white",
    padding: 12,
    border: "1px solid #E5E5E5",
    borderRadius: 4,
  },
  companyLogo: {
    maxHeight: 60,
    maxWidth: 120,
    width: "auto",
    height: "auto",
  },
  companyInfo: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 15,
    width: "100%",
    flexWrap: "no-wrap",
    justifyContent: "space-between",
  },
  companyHighlights: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 7,
  },
  highlightContainer: {
    marginLeft: 10,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  highlightNumber: {
    fontSize: 15,
    fontWeight: "bold",
    color: "#e94a3e",
    marginBottom: 5,
  },
  highlightTxt: {
    fontSize: 10,
  },
  flexRow: {
    flexDirection: "row",
  },
});

// Create Document Component
const PdfDocument = ({
  data: {
    companyData,
    articleData,
    videoData,
    introData,
    articleSummaryChart,
    articleDeviceDoughnut,
    articleNewReturnDoughnut,
    videoSummaryChart,
    videoAgeDoughnut,
    videoGenderDoughnut,
  },
}) => {
  // const today = new Date().toISOString().slice(0, 10);

  const Br = () => "\n";

  const Table = ({ data, headers }) => {
    return (
      <View style={styles.table}>
        <View style={[styles.rowView, { borderBottom: "2px solid #E0E0E0" }]}>
          <Text
            style={[styles.colKey, { color: "#4c3c61", fontWeight: "bold" }]}
          >
            {headers[0]}
          </Text>
          <Text
            style={[styles.colValue, { color: "#4c3c61", fontWeight: "bold" }]}
          >
            {headers[1]}
          </Text>
        </View>
        {data.map((tableData, index) => (
          <View
            key={index}
            style={[
              styles.rowView,
              index % 2 === 0 ? styles.evenRow : styles.oddRow,
            ]}
          >
            <Text style={styles.colKey}>{Object.values(tableData)[0]}</Text>
            <Text style={styles.colValue}>{Object.values(tableData)[1]}</Text>
          </View>
        ))}
      </View>
    );
  };

  // Performance table
  // function renderTableData(performanceData, field) {
  //   return Object.values(performanceData).map((item, index) => {
  //     return (
  //       <Text
  //         style={{
  //           width: 100 / (Object.values(performanceData).length + 1) + "%",
  //         }}
  //         key={index}
  //       >
  //         {item[field] === 0 ? "-" : item[field].toLocaleString()}
  //       </Text>
  //     );
  //   });
  // }

  let topThreeVisitors = "";
  if (articleData.latest_visitors && articleData.latest_visitors.length) {
    for (let i = 0; i < 3 && i < articleData.latest_visitors.length; i++) {
      topThreeVisitors += articleData.latest_visitors[i].name;
      if (i < 2 && i < articleData.latest_visitors.length - 1) {
        topThreeVisitors += ", ";
      }
    }
  }

  function transformData(data) {
    return data.map(({ business_id, ...rest }) => rest);
  }

  // Total Views calc
  const videoViews = videoData.total_views ? videoData.total_views : 0;

  return (
    <Document>
      {/* Cover page */}
      {/* <Page size="A4" style={styles.cover}>
        <View style={styles.coverSection}>
          <View style={styles.introContainer}>
            <Image style={styles.paLogo} src={encodedLogo} />
            <Text style={styles.introCompany}>{companyData.name}</Text>
            <Text style={styles.introText}>Proactive Analytics Report</Text>
            <Text style={styles.introDate}>{today}</Text>
          </View>
        </View>
      </Page> */}
      {/* Company Overview page */}
      <Page size="A4" style={styles.page}>
        <View
          style={[
            styles.card,
            { marginBottom: 20, justifyContent: "flex-start" },
          ]}
        >
          <View style={styles.companyInfo}>
            <View style={{ marginRight: 15, justifyContent: "center" }}>
              <Image style={styles.companyLogo} src={companyData.logo} />
            </View>

            {/* Company Stats */}
            <View style={styles.companyHighlights}>
              {articleData.total_views && articleData.total_impressions && (
                <View style={styles.highlightContainer}>
                  <Text style={styles.highlightNumber}>
                    {(
                      articleData.total_views +
                      articleData.total_impressions +
                      videoViews
                    ).toLocaleString()}
                  </Text>
                  <Text style={[styles.highlightTxt, { textAlign: "center" }]}>
                    <Text>
                      Total Views
                      <Br />
                      and Impressions
                    </Text>
                  </Text>
                </View>
              )}

              {articleData.total_views &&
                articleData.total_impressions &&
                videoViews > 0 && (
                  <View style={styles.highlightContainer}>
                    <Text style={styles.highlightNumber}>
                      {(
                        articleData.total_views + articleData.total_impressions
                      ).toLocaleString()}
                    </Text>
                    <Text
                      style={[styles.highlightTxt, { textAlign: "center" }]}
                    >
                      Article Views
                      <Br /> and Impressions
                    </Text>
                  </View>
                )}

              {videoData.total_views && (
                <View
                  style={[
                    styles.highlightContainer,
                    {
                      maxWidth: 100,
                      whiteSpace: "no-wrap",
                      textAlign: "center",
                    },
                  ]}
                >
                  <Text style={styles.highlightNumber}>
                    {videoData.total_views.toLocaleString()}
                  </Text>
                  <Text style={[styles.highlightTxt, { textAlign: "center" }]}>
                    Video Views
                  </Text>
                </View>
              )}
            </View>
          </View>
          <View style={{ justifyContent: "center" }}>
            <Text style={styles.heading}>
              {companyData.name} - ({companyData.ticker})
            </Text>
          </View>
          <Text style={{ marginBottom: 10 }}>
            Welcome to Proactive Analytics, your dashboard for analytics and
            metrics on your content appearing on our websites.{" "}
          </Text>
          <Text style={{ marginBottom: 10 }}>
            Cumulatively across all of the media published,{" "}
            {introData?.items?.length > 0 && (
              <>
                <Text
                  style={{ fontWeight: "bold" }}
                >{`${introData.items[0].value}%`}</Text>{" "}
                of visits to {companyData.name && companyData.name} originated
                in{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {introData.items[0].key}
                </Text>
              </>
            )}
            {introData?.items?.length > 1 && (
              <>
                , followed by{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {introData.items[1].key}
                </Text>{" "}
                with{" "}
                <Text
                  style={{ fontWeight: "bold" }}
                >{`${introData.items[1].value}%`}</Text>
              </>
            )}
            {introData?.items?.length > 2 && (
              <>
                , and{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {introData.items[2].key}
                </Text>{" "}
                with{" "}
                <Text
                  style={{ fontWeight: "bold" }}
                >{`${introData.items[2].value}%`}</Text>
              </>
            )}
            .
          </Text>
          {topThreeVisitors && (
            <Text style={{ marginBottom: 10 }}>
              We are also able to identify interest in your articles from{" "}
              {topThreeVisitors}.
            </Text>
          )}
        </View>

        {/* Performance section */}
        {/* {typeof performanceData === "object" &&
            performanceData !== null &&
            performanceData.items && (
              <View style={[styles.card, { width: "100%", marginBottom: 25 }]}>
                <Text style={styles.heading}>Performance Summary</Text>
                <View style={styles.table}>
                  <View
                    style={[
                      styles.rowView,
                      { borderBottom: "2px solid #E0E0E0" },
                    ]}
                  >
                    <Text
                      style={{
                        color: "#4c3c61",
                        fontWeight: "bold",
                        width:
                          100 / (Object.keys(performanceData.items).length + 1) +
                          "%",
                      }}
                    >
                      Metrics
                    </Text>
                    {performanceData.items &&
                      Object.keys(performanceData.items).length > 0 &&
                      Object.keys(performanceData.items).map((item, index) => {
                        return (
                          <Text
                            key={index}
                            style={{
                              color: "#4c3c61",
                              fontWeight: "bold",
                              width:
                                100 /
                                  (Object.keys(performanceData.items).length +
                                    1) +
                                "%",
                            }}
                          >
                            {item}
                          </Text>
                        );
                      })}
                  </View>
  
                  <View style={[styles.rowView, styles.evenRow]}>
                    <Text
                      style={{
                        width:
                          100 / (Object.keys(performanceData.items).length + 1) +
                          "%",
                      }}
                    >
                      Article Views
                    </Text>
                    {renderTableData(performanceData.items, "article_views")}
                  </View>
  
                  <View style={[styles.rowView, styles.oddRow]}>
                    <Text
                      style={{
                        width:
                          100 / (Object.keys(performanceData.items).length + 1) +
                          "%",
                      }}
                    >
                      Article Impressions
                    </Text>
                    {renderTableData(
                      performanceData.items,
                      "article_impressions"
                    )}
                  </View>
  
                  <View style={[styles.rowView, styles.evenRow]}>
                    <Text
                      style={{
                        width:
                          100 / (Object.keys(performanceData.items).length + 1) +
                          "%",
                      }}
                    >
                      Article Views and Impressions
                    </Text>
                    {renderTableData(
                      performanceData.items,
                      "article_views_and_impressions"
                    )}
                  </View>
  
                  <View style={[styles.rowView, styles.oddRow]}>
                    <Text
                      style={{
                        width:
                          100 / (Object.keys(performanceData.items).length + 1) +
                          "%",
                      }}
                    >
                      Video Views
                    </Text>
                    {renderTableData(performanceData.items, "video_views")}
                  </View>
  
                  <View style={[styles.rowView, styles.evenRow]}>
                    <Text
                      style={{
                        width:
                          100 / (Object.keys(performanceData.items).length + 1) +
                          "%",
                      }}
                    >
                      Total Views
                    </Text>
                    {renderTableData(performanceData.items, "total_views")}
                  </View>
  
                  <View style={[styles.rowView, styles.oddRow]}>
                    <Text
                      style={{
                        width:
                          100 / (Object.keys(performanceData.items).length + 1) +
                          "%",
                      }}
                    >
                      Total Views and Impressions
                    </Text>
                    {renderTableData(
                      performanceData.items,
                      "total_views_and_impressions"
                    )}
                  </View>
                </View>
              </View>
            )} */}

        {/* Articles Section */}
        {typeof articleData === "object" && articleData !== null && (
          <Text style={styles.topHeading}>Articles</Text>
        )}

        {/* Article Stats Row */}
        <View style={[styles.flexRow, { marginBottom: 7 }]}>
          {articleData.total_views && articleData.total_impressions && (
            <View
              style={[
                styles.card,
                { width: "33%", marginRight: 7, flexGrow: 1 },
              ]}
            >
              <Text style={styles.highlightNumber}>
                {(
                  articleData.total_views + articleData.total_impressions
                ).toLocaleString()}
              </Text>
              <Text style={styles.highlightTxt}>Views and Impressions</Text>
            </View>
          )}

          {articleData.total_impressions && (
            <View
              style={[
                styles.card,
                { width: "33%", marginRight: 7, flexGrow: 1 },
              ]}
            >
              <Text style={styles.highlightNumber}>
                {articleData.total_impressions.toLocaleString()}
              </Text>
              <Text style={styles.highlightTxt}>Page Impressions</Text>
            </View>
          )}

          {articleData.total_views && (
            <View style={[styles.card, { width: "33%", flexGrow: 1 }]}>
              <Text style={styles.highlightNumber}>
                {articleData.total_views.toLocaleString()}
              </Text>
              <Text style={styles.highlightTxt}>Page Views</Text>
            </View>
          )}
        </View>

        {/* Article Summary Chart */}
        {articleSummaryChart && articleData.status !== 0 && (
          <View style={[styles.card, { width: "100%" }]}>
            <Text style={styles.heading}>Cumulative Views</Text>
            <Image src={articleSummaryChart} />
          </View>
        )}
      </Page>
      <Page wrap size="A4" style={styles.page}>
        <View
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {/* Article Doughnuts */}
          <View style={[styles.flexRow, { marginBottom: 7 }]}>
            {articleNewReturnDoughnut && articleData.status !== 0 && (
              <View
                style={[
                  styles.card,
                  { width: "50%", marginRight: 7, flexGrow: 1 },
                ]}
              >
                <Text style={styles.heading}>New VS. Returning</Text>
                <Image src={articleNewReturnDoughnut} style={styles.doughnut} />
              </View>
            )}
            {articleDeviceDoughnut && (
              <View style={[styles.card, { width: "50%", flexGrow: 1 }]}>
                <Text style={styles.heading}>User Devices</Text>
                <Image src={articleDeviceDoughnut} style={styles.doughnut} />
              </View>
            )}
          </View>

          {/* Article Tables */}
          <View style={[styles.flexRow, { marginBottom: 7 }]}>
            {articleData.top_countries && articleData.top_countries.length && (
              <View
                style={[
                  styles.card,
                  { width: "50%", marginRight: 7, flexGrow: 1 },
                ]}
              >
                <Text style={styles.heading}>Top 5 Visitor Countries</Text>
                <Table
                  data={articleData.top_countries}
                  headers={["Country", "%"]}
                />
              </View>
            )}

            {articleData.top_referrers && articleData.top_referrers.length && (
              <View style={[styles.card, { width: "50%", flexGrow: 1 }]}>
                <Text style={styles.heading}>Top 5 External Referral</Text>
                <Table
                  data={articleData.top_referrers}
                  headers={["Referral Source", "%"]}
                />
              </View>
            )}
          </View>
        </View>

        {articleData.latest_visitors && articleData.latest_visitors.length && (
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <View style={[styles.card, { width: "100%" }]}>
              <Text style={styles.heading}>Corporate Visitors</Text>
              <Table
                data={transformData(articleData.latest_visitors)}
                headers={["Companies"]}
              />
            </View>
          </View>
        )}
      </Page>
      {companyData.playlist && (
        <Page size="A4" style={styles.page} wrap>
          {/* Video Section */}
          <Text style={styles.topHeading}>Videos</Text>

          {/* Video Stats Row */}
          <View style={[styles.flexRow, { marginBottom: 7 }]}>
            {videoData.total_views && (
              <View style={[styles.card, { width: "33%", marginRight: 7 }]}>
                <Text style={styles.highlightNumber}>
                  {videoData.total_views.toLocaleString()}
                </Text>
                <Text style={styles.highlightTxt}>Total Views</Text>
              </View>
            )}
            {videoData.total_watch_time && (
              <View style={[styles.card, { width: "33%", marginRight: 7 }]}>
                <Text style={styles.highlightNumber}>
                  {videoData.total_watch_time}
                </Text>
                <Text style={styles.highlightTxt}>
                  Total Watch Time (hours)
                </Text>
              </View>
            )}
            {videoData.total_views && videoData.total_watch_time && (
              <View style={[styles.card, { width: "33%" }]}>
                <Text style={styles.highlightNumber}>
                  {minTommss(
                    (videoData.total_watch_time * 60) / videoData.total_views
                  )}
                </Text>
                <Text style={styles.highlightTxt}>
                  Average View Duration (min)
                </Text>
              </View>
            )}
          </View>

          {/* Video Summary Chart */}
          {videoSummaryChart && videoData.status !== 0 && (
            <View style={[styles.card, { width: "100%", marginBottom: 7 }]}>
              <Text style={styles.heading}>Cumulative Views</Text>
              <Image src={videoSummaryChart} />
            </View>
          )}

          {/* Video Doughnuts */}
          <View style={[styles.flexRow, { marginBottom: 7 }]}>
            {videoAgeDoughnut && videoData.status !== 0 && (
              <View
                style={[
                  styles.card,
                  { width: "50%", marginRight: 7, flexGrow: 1 },
                ]}
              >
                <Text style={styles.heading}>Age Ranges</Text>
                <Image src={videoAgeDoughnut} style={styles.doughnut} />
              </View>
            )}
            {videoGenderDoughnut && videoData.status !== 0 && (
              <View style={[styles.card, { width: "50%", flexGrow: 1 }]}>
                <Text style={styles.heading}>Gender</Text>
                <Image src={videoGenderDoughnut} style={styles.doughnut} />
              </View>
            )}
          </View>

          {/* Video Tables */}

          <View style={styles.flexRow}>
            {videoData.countries && videoData.countries.length && (
              <View
                style={[
                  styles.card,
                  { width: "50%", marginRight: 7, flexGrow: 1 },
                ]}
              >
                <Text style={styles.heading}>Top 5 Viewer Countries</Text>
                <Table
                  data={transformData(videoData.countries)}
                  headers={["Sources", "%"]}
                />
              </View>
            )}
            {videoData.traffic_source && videoData.traffic_source.length && (
              <View style={[styles.card, { width: "50%", flexGrow: 1 }]}>
                <Text style={styles.heading}>Top 5 External Referral</Text>
                <Table
                  data={transformData(videoData.traffic_source)}
                  headers={["Sources", "%"]}
                />
              </View>
            )}
          </View>
        </Page>
      )}
    </Document>
  );
};

export default PdfDocument;
