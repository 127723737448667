import { useSelector } from "react-redux";
import { NavLink, useLocation, useParams } from "react-router-dom";

const AnalyticsLinks = () => {
  const { currentCompanyId } = useSelector((state) => state.auth);

  const location = useLocation();
  const { pathname } = location;

  const { id } = useParams();

  let linkID;
  if (currentCompanyId) {
    linkID = currentCompanyId;
  } else {
    linkID = id;
  }

  return (
    <div className="space-y-8">
      {/* Pages group */}
      <div>
        <h3 className="text-sm uppercase text-white font-semibold pl-3">
          <span className="block">Pages</span>
        </h3>
        <ul className="mt-3">
          {/* Dashboard */}
          <li
            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
              pathname === "/analytics/" + linkID && "bg-secondary-dark"
            }`}
          >
            <NavLink
              end
              to={"/analytics/" + linkID}
              className={`group block text-white truncate`}
            >
              <div className="flex items-center">
                <svg width="24" height="21" viewBox="0 0 24 21">
                  <path
                    d="M19.727,22.488a2.744,2.744,0,1,1,2.727-2.744,2.739,2.739,0,0,1-2.727,2.744m0-4.391a1.646,1.646,0,1,0,1.636,1.646A1.643,1.643,0,0,0,19.727,18.1"
                    transform="translate(-7.727 -7.67)"
                    className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                      pathname === "/analytics/" + linkID && "!text-primary"
                    }`}
                  />
                  <path
                    d="M0,0,6.783-.021l0,1.094L0,1.094Z"
                    transform="translate(13.157 10.134) rotate(-45)"
                    className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                      pathname === "/analytics/" + linkID && "!text-primary"
                    }`}
                  />
                  <path
                    d="M20.1,21l-4.243-4.269.386-.388a6.071,6.071,0,0,0,1.043-7.127l.961-.521a7.19,7.19,0,0,1,.843,3.379,7.106,7.106,0,0,1-1.706,4.642l2.7,2.722a10.909,10.909,0,1,0-16.18,0l2.7-2.722A7.114,7.114,0,0,1,15.39,5.806l-.522.964a6.029,6.029,0,0,0-7.111,9.574l.386.388L3.9,21l-.386-.388a12,12,0,1,1,16.971,0Z"
                    className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                      pathname === "/analytics/" + linkID && "!text-primary"
                    }`}
                  />
                </svg>

                <span className="text-base font-regular ml-3 duration-200">
                  Dashboard
                </span>
              </div>
            </NavLink>
          </li>
          {/* Articles */}
          <li
            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
              pathname.includes("news") && "bg-secondary-dark"
            }`}
          >
            <NavLink
              end
              to={"/analytics/" + linkID + "/news"}
              className={`group block text-white truncate`}
            >
              <div className="flex items-center">
                <svg width="24" height="20.8" viewBox="0 0 24 20.8">
                  <path
                    d="M24 .607a.609.609 0 0 0-.612-.606H3.619a.609.609 0 0 0-.612.607V5.05H.612A.609.609 0 0 0 0 5.657V18.732a2.109 2.109 0 0 0 2.084 2.069h19.809a2.111 2.111 0 0 0 2.074-1.9.6.6 0 0 0 .033-.2ZM1.224 18.701V6.263h1.783v12.438a.892.892 0 0 1-1.784 0m20.638.885H4.033a2.071 2.071 0 0 0 .2-.853V1.214h18.543v17.323a.6.6 0 0 0-.023.165.889.889 0 0 1-.892.885"
                    className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                      pathname.includes("news") && "!text-primary"
                    }`}
                  />
                  <path
                    d="M6.598 12.744h4.772a.609.609 0 0 0 .612-.607v-5.07a.609.609 0 0 0-.612-.607H6.598a.609.609 0 0 0-.612.607v5.07a.609.609 0 0 0 .612.607m.612-5.07h3.547v3.856H7.209ZM20.41 3.186H6.598a.607.607 0 1 0 0 1.214H20.41a.607.607 0 1 0 0-1.214M20.41 15.3H6.598a.607.607 0 1 0 0 1.213H20.41a.607.607 0 1 0 0-1.213M20.41 6.461h-5.908a.607.607 0 1 0 0 1.214h5.908a.607.607 0 1 0 0-1.214M20.41 8.995h-5.908a.607.607 0 1 0 0 1.214h5.908a.607.607 0 1 0 0-1.214M20.41 11.53h-5.908a.607.607 0 1 0 0 1.214h5.908a.607.607 0 1 0 0-1.214"
                    className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                      pathname.includes("news") && "!text-primary"
                    }`}
                  />
                </svg>
                <span className="text-base font-regular ml-3 duration-200">
                  Articles
                </span>
              </div>
            </NavLink>
          </li>
          {/* Videos */}
          <li
            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
              pathname.includes("videos") && "bg-secondary-dark"
            }`}
          >
            <NavLink
              end
              to={"/analytics/" + linkID + "/videos"}
              className={`group block text-white truncate`}
            >
              <div className="flex items-center">
                <svg width="24" height="17.999" viewBox="0 0 24 17.999">
                  <path
                    d="M1.5,1.5h15v15H1.5ZM0,18H18V0H0Z"
                    className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                      pathname.includes("videos") && "!text-primary"
                    }`}
                  />
                  <path
                    d="M23.5,12.632V6.06L28,3.81V14.882ZM22,5.132v8.427l7.5,3.751V1.382Z"
                    transform="translate(-5.501 -0.346)"
                    className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                      pathname.includes("videos") && "!text-primary"
                    }`}
                  />
                </svg>

                <span className="text-base font-regular ml-3 duration-200">
                  Videos
                </span>
              </div>
            </NavLink>
          </li>
          {/* Forensics */}
          <li
            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
              pathname.includes("forensics") && "bg-secondary-dark"
            }`}
          >
            <NavLink
              end
              to={"/analytics/" + linkID + "/forensics"}
              className={`group block text-white truncate`}
            >
              <div className="flex items-center">
                <svg width="20.529" height="26.4" viewBox="0 0 20.529 26.4">
                  <g
                    strokeWidth=".4"
                    className={`fill-current stroke-current text-white ${
                      pathname.includes("forensics") && "!text-primary"
                    }`}
                  >
                    <path
                      d="M.917 16.014v-4.489a.356.356 0 0 0-.362-.352.354.354 0 0 0-.356.352v4.489a.357.357 0 0 0 .361.353.355.355 0 0 0 .357-.353"
                      className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                        pathname.includes("forensics") && "!text-primary"
                      }`}
                    />
                    <path
                      d="M10.264.2a10.084 10.084 0 0 0-6.811 2.636A9.906 9.906 0 0 0 .239 9.285a.352.352 0 0 0 .092.27.358.358 0 0 0 .262.114h.006a.351.351 0 0 0 .353-.319 9.368 9.368 0 0 1 16.8-4.712.351.351 0 0 0 .283.14.363.363 0 0 0 .2-.063.351.351 0 0 0 .087-.5A10.152 10.152 0 0 0 10.264.2"
                      className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                        pathname.includes("forensics") && "!text-primary"
                      }`}
                    />
                    <path
                      d="M20.328 16.146v-5.977a9.8 9.8 0 0 0-.835-3.975.356.356 0 0 0-.327-.211.363.363 0 0 0-.2.063.348.348 0 0 0-.129.431 9.086 9.086 0 0 1 .776 3.691v6.129c0 .36-.013.723-.035 1.081a.328.328 0 0 0 .088.252.35.35 0 0 0 .256.107.325.325 0 0 0 .334-.3 9.968 9.968 0 0 0 .074-1.208Z"
                      className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                        pathname.includes("forensics") && "!text-primary"
                      }`}
                    />
                    <path
                      d="M2.859 16.233v-6.065a7.275 7.275 0 0 1 3.566-6.269.353.353 0 0 0 .139-.442.357.357 0 0 0-.512-.165 7.982 7.982 0 0 0-3.91 6.877v6.1a1.591 1.591 0 0 1-1.24 1.492c-.051.011-.105.02-.16.026a.355.355 0 0 0 .048.708.318.318 0 0 0 .045 0 2.155 2.155 0 0 0 .214-.036 2.294 2.294 0 0 0 1.81-2.226"
                      className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                        pathname.includes("forensics") && "!text-primary"
                      }`}
                    />
                    <path
                      d="M17.354 20.975a.36.36 0 0 0-.436.241 16.831 16.831 0 0 1-.936 2.356l-.011.024a.351.351 0 0 0 .017.348.374.374 0 0 0 .31.172.358.358 0 0 0 .224-.08l.009-.007a.375.375 0 0 0 .1-.127 17.66 17.66 0 0 0 .98-2.482.345.345 0 0 0-.029-.273.361.361 0 0 0-.228-.173"
                      className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                        pathname.includes("forensics") && "!text-primary"
                      }`}
                    />
                    <path
                      d="M10.264 2.122a8.186 8.186 0 0 0-2.07.265.356.356 0 0 0 .095.7.36.36 0 0 0 .091-.012 7.449 7.449 0 0 1 1.809-.241h.074a7.428 7.428 0 0 1 7.408 7.431v5.971a16.972 16.972 0 0 1-.243 2.869.354.354 0 0 0 .264.4.36.36 0 0 0 .093.012.356.356 0 0 0 .352-.3 17.76 17.76 0 0 0 .252-2.989v-6.06a7.947 7.947 0 0 0-2.383-5.686 8.1 8.1 0 0 0-5.741-2.36"
                      className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                        pathname.includes("forensics") && "!text-primary"
                      }`}
                    />
                    <path
                      d="M11.108 21.687a.351.351 0 0 0-.185-.211.357.357 0 0 0-.158-.037.35.35 0 0 0-.306.178 11.214 11.214 0 0 1-3.045 3.494l-.134.1a.337.337 0 0 0-.141.239.317.317 0 0 0 .227.331l.094.027a.313.313 0 0 0 .089.013.324.324 0 0 0 .193-.063c.119-.088.239-.18.378-.292a11.93 11.93 0 0 0 2.961-3.5.346.346 0 0 0 .028-.275"
                      className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                        pathname.includes("forensics") && "!text-primary"
                      }`}
                    />
                    <path
                      d="M7.417 20.544a7.96 7.96 0 0 0 1.266-4.31v-6.065a1.583 1.583 0 0 1 3.165 0v6.134a11.108 11.108 0 0 1-.537 3.355.342.342 0 0 0 .175.415l.02.01a.348.348 0 0 0 .484-.2 11.76 11.76 0 0 0 .576-3.646v-6.065a2.3 2.3 0 0 0-4.6 0V16.3a7.372 7.372 0 0 1-4.307 6.6l-.123.055a.372.372 0 0 0-.2.187.353.353 0 0 0 .088.4.361.361 0 0 0 .242.093.352.352 0 0 0 .142-.029c.1-.044.21-.094.321-.148a8.143 8.143 0 0 0 3.286-2.916"
                      className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                        pathname.includes("forensics") && "!text-primary"
                      }`}
                    />
                    <path
                      d="M9.906 10.01v2.244a.354.354 0 0 0 .356.352h.006a.354.354 0 0 0 .355-.352V10.01a.359.359 0 0 0-.717 0"
                      className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                        pathname.includes("forensics") && "!text-primary"
                      }`}
                    />
                    <path
                      d="M10.624 16.233v-1.771a.359.359 0 0 0-.717 0v1.771a9.3 9.3 0 0 1-4.565 7.952l-.144.084a.332.332 0 0 0-.169.288.326.326 0 0 0 .164.285l.053.03a.338.338 0 0 0 .167.044.331.331 0 0 0 .166-.044c.122-.069.241-.141.353-.212a9.912 9.912 0 0 0 4.693-8.427"
                      className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                        pathname.includes("forensics") && "!text-primary"
                      }`}
                    />
                    <path
                      d="M6.723 16.701a.342.342 0 0 0-.093-.263.364.364 0 0 0-.269-.114.356.356 0 0 0-.354.325 5.423 5.423 0 0 1-3.653 4.7L2.2 21.4a.359.359 0 0 0-.233.226.343.343 0 0 0 .048.316.359.359 0 0 0 .294.152.37.37 0 0 0 .109-.017c.1-.031.2-.065.3-.1a6.162 6.162 0 0 0 4.008-5.272"
                      className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                        pathname.includes("forensics") && "!text-primary"
                      }`}
                    />
                    <path
                      d="M14.505 10.168A4.155 4.155 0 0 0 13.262 7.2a4.248 4.248 0 0 0-7.239 2.968v4.3a.359.359 0 0 0 .717 0v-4.3a3.511 3.511 0 0 1 3.524-3.49h.084a3.561 3.561 0 0 1 3.44 3.556v6a12.956 12.956 0 0 1-3.879 9.262l-.093.092a.352.352 0 0 0-.083.388.36.36 0 0 0 .337.224.363.363 0 0 0 .257-.105l.092-.091c.1-.1.2-.2.288-.294a13.676 13.676 0 0 0 3.794-9.476Z"
                      className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                        pathname.includes("forensics") && "!text-primary"
                      }`}
                    />
                    <path
                      d="M4.799 16.234v-6.066a5.467 5.467 0 0 1 10.912-.445.353.353 0 0 0 .36.324.36.36 0 0 0 .263-.114.348.348 0 0 0 .093-.265 6.089 6.089 0 0 0-1.792-3.828 6.192 6.192 0 0 0-10.554 4.328v6.118a3.5 3.5 0 0 1-2.646 3.329 2.544 2.544 0 0 1-.173.039.362.362 0 0 0-.247.184.348.348 0 0 0-.01.308.355.355 0 0 0 .328.212.339.339 0 0 0 .073-.007c.087-.018.18-.04.277-.066a4.22 4.22 0 0 0 3.116-4.051"
                      className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                        pathname.includes("forensics") && "!text-primary"
                      }`}
                    />
                    <path
                      d="M16.446 11.904a.359.359 0 0 0-.717 0v4.33a14.793 14.793 0 0 1-3 8.96l-.031.042a.365.365 0 0 0-.048.371.358.358 0 0 0 .327.213.407.407 0 0 0 .112-.017.359.359 0 0 0 .187-.129c.143-.188.281-.379.41-.565a15.52 15.52 0 0 0 2.759-8.875Z"
                      className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                        pathname.includes("forensics") && "!text-primary"
                      }`}
                    />
                  </g>
                </svg>
                <span className="text-base font-regular ml-3 duration-200">
                  Corporate Visitors
                </span>
              </div>
            </NavLink>
          </li>
          {/* Distribution */}
          <li
            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
              pathname.includes("distribution") && "bg-secondary-dark"
            }`}
          >
            <NavLink
              end
              to={"/analytics/" + linkID + "/distribution"}
              className={`group block text-white truncate`}
            >
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26.8"
                  height="26.7"
                  viewBox="0 0 26.8 26.7"
                >
                  <path
                    fill="none"
                    stroke="#FFF"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    d="M22.875 13.818v5.1l-8.9 4.3-8.9-4.3v-5.1M4.975 9.718v-.6l8.9-4.3 8.9 4.3v.6"
                    className={`group-hover:text-primary stroke-current text-white transition duration-250 ${
                      pathname.includes("distribution") && "!text-primary"
                    }`}
                  />
                  <path
                    fill="none"
                    stroke="#FFF"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    d="m22.875 9.118-8.9 4.3-9-4.3M13.875 12.918v10.2"
                    className={`group-hover:text-primary stroke-current text-white transition duration-250 ${
                      pathname.includes("distribution") && "!text-primary"
                    }`}
                  />
                  <path
                    fill="none"
                    stroke="#FFF"
                    strokeWidth="2"
                    strokeLinecap="square"
                    strokeMiterlimit="10"
                    d="m13.475 13.218-1.9 3.8-9.2-4.3 2-2.9"
                    className={`group-hover:text-primary stroke-current text-white transition duration-250 ${
                      pathname.includes("distribution") && "!text-primary"
                    }`}
                  />
                  <path
                    fill="none"
                    stroke="#FFF"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    d="m22.975 9.018 2.4 3.6-9.2 4.5-2.1-4.5M13.875 4.818v5.1M25.472 5.53V1.289h-4.243M1.43 21.087v4.243h4.243M1.43 5.53V1.289h4.243M25.472 21.087v4.243h-4.243"
                    className={`group-hover:text-primary stroke-current text-white transition duration-250 ${
                      pathname.includes("distribution") && "!text-primary"
                    }`}
                  />
                </svg>
                <span className="text-base font-regular ml-3 duration-200">
                  Distribution
                </span>
              </div>
            </NavLink>
          </li>
          {/* FAQ */}
          {/* <li
            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
              pathname.includes("faq") && "bg-secondary-dark"
            }`}
          >
            <NavLink
              end
              to={"/analytics/" + linkID + "/faq"}
              className={`group block text-white truncate`}
            >
              <div className="flex items-center">
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <path
                    d="M12 1.5a10.5 10.5 0 0 1 8.669 16.42l-.452.661.3.743.793 1.983-1.983-.793-.743-.3-.661.452A10.5 10.5 0 1 1 12 1.5M12 0a12 12 0 1 0 6.767 21.907L24 24l-2.093-5.233A12 12 0 0 0 12 0"
                    className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                      pathname.includes("faq") && "!text-primary"
                    }`}
                  />
                  <text
                    dataname="?"
                    transform="translate(0 3)"
                    fontSize="15"
                    fontFamily="Ubuntu-Medium, Ubuntu"
                    fontWeight="500"
                    className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                      pathname.includes("faq") && "!text-primary"
                    }`}
                  >
                    <tspan x="8.82" y="14">
                      ?
                    </tspan>
                  </text>
                </svg>

                <span className="text-base font-regular ml-3 duration-200">
                  FAQ
                </span>
              </div>
            </NavLink>
          </li>
          {/* Contact Us */}
          <li
            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
              pathname.includes("contact") && "bg-secondary-dark"
            }`}
          >
            <NavLink
              end
              to={"/analytics/" + linkID + "/contact"}
              className={`group block text-white truncate`}
            >
              <div className="flex items-center">
                <svg width="24" height="16.5" viewBox="0 0 24 16.5">
                  <path
                    d="M1.5 1.5h21V15h-21ZM0 16.5h24V0H0Z"
                    className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                      pathname.includes("contact") && "!text-primary"
                    }`}
                  />
                  <path
                    d="M12 9.508 1.242.183.259 1.317 12 11.492 23.742 1.317 22.759.183Z"
                    className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                      pathname.includes("contact") && "!text-primary"
                    }`}
                  />
                  <path
                    className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                      pathname.includes("contact") && "!text-primary"
                    }`}
                    d="m.22 15.22 8.25-8.25 1.06 1.06-8.25 8.25z"
                  />
                  <path
                    className={`group-hover:text-primary fill-current text-white transition duration-250 ${
                      pathname.includes("contact") && "!text-primary"
                    }`}
                    d="m14.47 8.03 1.06-1.06 8.25 8.25-1.06 1.06z"
                  />
                </svg>
                <span className="text-base font-regular ml-3 duration-200">
                  Contact Us
                </span>
              </div>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AnalyticsLinks;
