import React from "react";

import BaseCard from "../../../components/base/BaseCard";
import StockChart from "../../../components/charts/StockChart";
import {
  formatDataForSeries,
  formatDataForFlags,
  hexToRGB,
} from "../../../utils/Utils";

function VideosSummaryChart({ data, partial }) {
  const chartData = {
    chart: {
      minheight: "400px",
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
      flags: {
        accessibility: {
          exposeAsGroupOnly: true,
          description: "Flagged events.",
        },
      },
      areaspline: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 1,
            y2: 1,
          },
          stops: [
            [0, `rgba(${hexToRGB("#E9EDFF")}, 0.2)`],
            [1, `rgba(${hexToRGB("#4C3C61")}, 100)`],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 2,
        lineColor: "#4C3C61",
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        threshold: null,
      },
    },
    xAxis: {
      type: "datetime",
    },
    series: [
      {
        id: "views",
        name: "Views",
        data: formatDataForSeries(data.chart_data),
        cumulative: true,
        type: "areaspline",
      },
      ...(!partial
        ? [
            {
              type: "flags",
              name: "Videos",
              data: formatDataForFlags(data.items),
              onSeries: "views",
              shape:
                "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEwIDEwIj4NCiAgPGcgaWQ9IkVsbGlwc2VfMjAiIGRhdGEtbmFtZT0iRWxsaXBzZSAyMCIgZmlsbD0iI2ZmZiIgc3Ryb2tlPSIjNGMzYzYxIiBzdHJva2Utd2lkdGg9IjIiPg0KICAgIDxjaXJjbGUgY3g9IjUiIGN5PSI1IiByPSI1IiBzdHJva2U9Im5vbmUiLz4NCiAgICA8Y2lyY2xlIGN4PSI1IiBjeT0iNSIgcj0iNCIgZmlsbD0ibm9uZSIvPg0KICA8L2c+DQo8L3N2Zz4NCg==)",
              title: "",
              y: -10,
              allowOverlapX: true,
            },
          ]
        : []),
    ],
    navigator: {
      enabled: false,
    },
    yAxis: {
      offset: 30,
      title: {
        textAlign: "right",
      },
    },
    scrollbar: {
      enabled: false,
    },
  };

  return (
    <BaseCard className="flex flex-col col-span-full xl:col-span-10 mb-0 px-5 py-4 videos-summary-chart">
      <StockChart
        className="h-full"
        options={chartData}
        title="CUMULATIVE VIEWS"
        zoom={partial ? 5 : 2}
        chartType="videoSummaryChart"
      />
    </BaseCard>
  );
}

export default VideosSummaryChart;
