import PropTypes from "prop-types";

import BaseCard from "./BaseCard";

const BaseStats = ({
  id,
  name,
  subtext,
  number = 0,
  numberFormatting = true,
  ...props
}) => {
  let { className, ...otherProps } = props;

  return (
    <BaseCard
      id={id}
      className={`${className || ""}`}
      {...otherProps}
    >
      <span className="text-3xl text-red font-bold lg:text-[1.8rem]">
        {numberFormatting ? parseInt(number)?.toLocaleString() : number}
      </span>
      <div className="flex flex-col items-left pt-4">
        <span className="font-regular text-secondary lg:text-lg !leading-[1.2]">
          {name}
        </span>
        {subtext && (
          <span className="text-sm font-regular text-secondary line !leading-[1.2] pt-1">
            {subtext}
          </span>
        )}
      </div>
    </BaseCard>
  );
};

BaseStats.propTypes = {
  name: PropTypes.string.isRequired,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default BaseStats;
