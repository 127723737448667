import {useState} from "react";

function Accordion({
  // accordionOpen,
  // toggle,
  logo,
  title,
  text,
  children,
}) {

 const [open, setOpen] = useState(false)
  return (
    <div
      className={`accordion mb-4 ${
        !open && "duration-500 delay-200 transition-all"
      }`}
    >
      <button
        className={`px-5 py-4 flex items-center justify-between w-full group bg-[#f1f1ee] ${
          open && "border-b-1"
        }`}
        aria-expanded={open}
        onClick={() => setOpen(open => !open)}
      >
        <div className="flex text-2xl text-violet font-medium items-center">
          <div className="w-[150px] max-h-[59px] flex items-center mr-14">
            <img className="max-h-[59px]" src={logo} alt={title} />
          </div>
          <div className="flex flex-col items-start">
            {title}
            {text && (
              <span className="block font-normal text-lg text-black">
                {text}
              </span>
            )}
          </div>
        </div>
        <svg
          className={`w-14 h-14 shrink-0 fill-current text-primary group-hover:text-primary-dark ml-3 duration-500 transition-all ${
            open && "rotate-180"
          }`}
          viewBox="0 0 32 32"
        >
          <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
        </svg>
      </button>
      <div
        className={`max-h-0  ${
          open && "max-h-[200rem] py-10"
        } overflow-hidden duration-500 transition-all px-5`}
      >
        {children}
      </div>
    </div>
  );
}

export default Accordion;
