import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import Select from "react-select";

import axios from "../../api/AxiosHttp";
import {
  updateGaData,
  updateGaProperty,
  updateGaPropertyRange,
} from "./../../redux/googleSlice";

import BaseTitle from "../../components/base/BaseTitle";
import BaseCard from "../../components/base/BaseCard";
import ButtonGroup from "../../components/ButtonGroup";
import GoogleDashboardDetails from "../../partials/internal-analytics/GoogleDashboardDetails";

const GoogleDashboard = () => {
  const dispatch = useDispatch();

  const { gaProperty, gaPropertyRange, gaData } = useSelector(
    (state) => state.google
  );

  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    fetchGoogleData();
  }, [gaProperty, gaPropertyRange]);

  // Function to get article data from API and update redux store if older than 5 minutes
  const fetchGoogleData = async () => {
    setLoadingPage(true);
    dispatch(updateGaData({}));

    try {
      const endPoint = `/api/internal_stats/${gaProperty.value}/${gaPropertyRange}`;
      const { data } = await axios.get(endPoint);

      dispatch(updateGaData(data));
      setLoadingPage(false);
    } catch (error) {
      dispatch(updateGaData({ status: 0 }));
      setLoadingPage(false);
    }
  };

  const buttonGroupOptions = [
    {
      label: "1 Month",
      value: 1,
    },
    {
      label: "3 Months",
      value: 3,
    },
    {
      label: "6 Months",
      value: 6,
    },
    {
      label: "12 Months",
      value: 12,
    },
  ];

  const handleGaProperty = (e) => {
    dispatch(updateGaProperty({ label: e.label, value: e.value }));
  };

  const handleGaRange = (e) => {
    dispatch(updateGaPropertyRange(e.target.value));
  };

  return (
    <>
      <BaseTitle heading="Google Analytics" />

      <div className="grid gap-5 md:grid-cols-3 mb-12">
        <div className="w-96">
          <label className="block text-sm font-medium mb-1" htmlFor="property">
            Property Name
          </label>
          <Select
            id="property"
            className="w-full fselect"
            options={[
              {
                value: process.env.REACT_APP_GA4_GLOBAL,
                label: process.env.REACT_APP_GA4_GLOBAL_LABEL,
              },
              {
                value: process.env.REACT_APP_GA4_UK,
                label: process.env.REACT_APP_GA4_UK_LABEL,
              },
              {
                value: process.env.REACT_APP_GA4_US,
                label: process.env.REACT_APP_GA4_US_LABEL,
              },
              {
                value: process.env.REACT_APP_GA4_AU,
                label: process.env.REACT_APP_GA4_AU_LABEL,
              },
              {
                value: process.env.REACT_APP_GA4_CA,
                label: process.env.REACT_APP_GA4_CA_LABEL,
              },
            ]}
            name="property"
            required
            onChange={handleGaProperty}
            defaultValue={{
              value: gaProperty.value,
              label: gaProperty.label,
            }}
          />
        </div>
        <div className="w-96">
          <label className="block text-sm font-medium mb-1">Date Range</label>
          <ButtonGroup
            type="button"
            options={buttonGroupOptions}
            active={gaPropertyRange}
            onClick={handleGaRange}
          />
        </div>
      </div>

      {!loadingPage ? (
        <>
          {gaData && Object.keys(gaData).length > 0 && gaData.status !== 0 ? (
            <GoogleDashboardDetails data={gaData} />
          ) : (
            <BaseCard>There is no data for google analytics.</BaseCard>
          )}
        </>
      ) : (
        <div className="flex flex-grow justify-center items-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#e94a3e"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

export default GoogleDashboard;
