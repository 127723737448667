import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

import authSlice from "./authSlice";
import companySlice from "./companySlice";
import articleSlice from "./articleSlice";
import videoSlice from "./videoSlice";
import forensicSlice from "./forensicSlice";
import performanceSlice from "./performanceSlice";
import introSlice from "./introSlice";
import googleSlice from "./googleSlice";
import youtubeSlice from "./youtubeSlice";
import clientSlice from "./clientSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: authSlice,
  company: companySlice,
  article: articleSlice,
  video: videoSlice,
  forensic: forensicSlice,
  performance: performanceSlice,
  intro: introSlice,
  google: googleSlice,
  youtube: youtubeSlice,
  client: clientSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
