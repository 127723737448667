import BaseButton from "./base/BaseButton";

const ButtonGroup = ({ options, active = false, ...props }) => {
  return (
    <div className="flex flex-wrap -space-x-px">
      {options.map((option, index) => {
        return (
          <BaseButton
            key={index}
            className={`${
              active == option.value && "!bg-dark-grey text-white"
            } rounded-none border-l-light-grey font-bold first:border-l-transparent first:rounded-l-full last:rounded-r-full first:border-r-transparent`}
            label={option.label}
            value={option.value}
            styleOptions={{ color: "grey" }}
            {...props}
          />
        );
      })}
    </div>
  );
};

export default ButtonGroup;
