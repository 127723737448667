import { createSlice } from "@reduxjs/toolkit";

export const companySlice = createSlice({
  name: "company",
  initialState: {
    companyData: {},
  },
  reducers: {
    updateCompanyData: (state, action) => {
      state.companyData = action.payload;
    },
  },
});

export const { updateCompanyData } = companySlice.actions;

export default companySlice.reducer;