import PropTypes from "prop-types";
import ReactDataGrid from "@inovua/reactdatagrid-community";

const BaseTable = ({
  idProperty,
  dataSource,
  columns,
  className,
  pagination,
  defaultSortInfo,
  defaultFilterValue,
  style,
  loading,
  loadingText,
  scrollHide,
  handle,
  rowHeight,
  rowStyle,
  defaultLimit
}) => {

  const scrollProps = Object.assign({}, ReactDataGrid.defaultProps.scrollProps, {
    autoHide: scrollHide
  })

  return (
    <ReactDataGrid
      idProperty={idProperty}
      className={className}
      style={style}
      columns={columns}
      dataSource={dataSource}
      pagination={pagination}
      defaultSortInfo={defaultSortInfo}
      defaultFilterValue={defaultFilterValue}
      loading={loading}
      loadingText={loadingText}
      showActiveRowIndicator={false}
      enableColumnAutosize={true}
      scrollProps={scrollProps}
      emptyText="Not enough data"
      showCellBorders={false}
      handle={handle}
      allowUnsort={false}
      rowHeight={rowHeight}
      minRowHeight={40}
      rowStyle={rowStyle}
      defaultLimit={defaultLimit}
    />
  );
};

BaseTable.propTypes = {
  idProperty: PropTypes.string.isRequired,
  dataSource: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  height: PropTypes.number,
  className: PropTypes.string,
  pagination: PropTypes.bool,
  defaultSortInfo: PropTypes.object,
  defaultFilterValue: PropTypes.array,
  style: PropTypes.object,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  scrollProps: PropTypes.bool
};

export default BaseTable;
