import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import Select from "react-select";

import axios from "../../api/AxiosHttp";
import {
  updateClientSector,
  updateClientExchange,
  updateClientRange,
  updateClientData,
} from "./../../redux/clientSlice";

import BaseTitle from "../../components/base/BaseTitle";
import BaseCard from "../../components/base/BaseCard";
import ButtonGroup from "../../components/ButtonGroup";
import ClientDashboardDetails from "../../partials/internal-analytics/ClientDashboardDetails";

const ClientDashboard = () => {
  const dispatch = useDispatch();

  const { clientData, clientExchange, clientRange, clientSector } = useSelector(
    (state) => state.client
  );

  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    fetchClientData();
  }, [clientSector, clientExchange, clientRange]);

  // Function to get article data from API and update redux store if older than 5 minutes
  const fetchClientData = async () => {
    setLoadingPage(true);
    dispatch(updateClientData({}));

    try {
      const endPoint = `/api/internal_leaderboard/sector/${clientSector?.value}/exchange/${clientExchange?.value}/period/${clientRange}`;
      const { data } = await axios.get(endPoint);

      dispatch(updateClientData(data));

      setLoadingPage(false);
    } catch (error) {
      dispatch(updateClientData({ status: 0 }));
      setLoadingPage(false);
    }
  };

  const handleClientSector = (e) => {
    dispatch(updateClientSector({ label: e.label, value: e.value }));
  };

  const handleClientExchange = (e) => {
    dispatch(updateClientExchange({ label: e.label, value: e.value }));
  };

  const buttonGroupOptions = [
    {
      label: "6 Month",
      value: 6,
    },
    {
      label: "12 Months",
      value: 12,
    },
    {
      label: "All Time",
      value: 0,
    },
  ];

  const handleClientRange = (e) => {
    dispatch(updateClientRange(e.target.value));
  };

  return (
    <>
      <BaseTitle heading="Client Analytics" />

      <BaseCard>
        <p>
          The 'All Time' data range covers articles published since October 1,
          2021, and videos released since January 1, 2009.
        </p>
      </BaseCard>

      <div className="grid gap-5 md:grid-cols-3 mb-12">
        <div className="w-96">
          <label className="block text-sm font-medium mb-1" htmlFor="property">
            Sectors
          </label>
          <Select
            id="sectors"
            className="w-full fselect"
            options={[
              { value: 0, label: "All Sectors" },
              ...(clientData?.sectors?.map((sector) => {
                return { value: sector.id, label: sector.title };
              }) || []),
            ]}
            name="property"
            required
            onChange={handleClientSector}
            defaultValue={{
              value: clientSector.value,
              label: clientSector.label,
            }}
          />
        </div>
        <div className="w-96">
          <label className="block text-sm font-medium mb-1">Exchanges</label>
          <Select
            id="exchanges"
            className="w-full fselect"
            options={[
              { value: 0, label: "All Exchanges" },
              ...(clientData?.exchanges?.map((item) => {
                return { value: item.exchange, label: item.exchange };
              }) || []),
            ]}
            name="property"
            required
            onChange={handleClientExchange}
            defaultValue={{
              value: clientExchange.value,
              label: clientExchange.label,
            }}
          />
        </div>
        <div className="w-96">
          <label className="block text-sm font-medium mb-1">Date Range</label>
          <ButtonGroup
            type="button"
            options={buttonGroupOptions}
            active={clientRange}
            onClick={handleClientRange}
          />
        </div>
      </div>

      {!loadingPage ? (
        <>
          {clientData &&
          Object.keys(clientData).length > 0 &&
          clientData.status !== 0 ? (
            <ClientDashboardDetails data={clientData} range={clientRange} />
          ) : (
            <BaseCard>There is no data for client analytics.</BaseCard>
          )}
        </>
      ) : (
        <div className="flex flex-grow justify-center items-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#e94a3e"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

export default ClientDashboard;
