import Select from "react-select";

const SelectDefaultValue = (props) => {
  return (
    <Select
      id={props.id}
      className="w-full fselect"
      defaultValue={props.assignedCompanies}
      name={props.name}
      isMulti
      options={props.activeCompanies}
      required={props.required}
    />
  );
};

export default SelectDefaultValue;
