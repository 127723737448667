import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from "../../../api/AxiosHttp";
import { formatDate, truncate } from "../../../utils/Utils";
import VideoDetails from "./VideoDetails";

import BaseTable from "../../../components/base/BaseTable";
import BaseButton from "../../../components/base/BaseButton";

const SEPARATOR = ",";

const VideosTable = ({ tableData }) => {
  const { id } = useParams();

  const { companyData } = useSelector((state) => state.company);

  const [gridRef, setGridRef] = useState(null);
  const [videoData, setVideoData] = useState([]);
  const [updateVideo, setUpdateVideo] = useState(Date());
  const [videoMeta, setVideoMeta] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    loadFirstVideoDetails(tableData);
  }, []);

  const loadFirstVideoDetails = async (tableData) => {
    setLoadingPage(true);
    const endPoint = `/api/companies/active/${id}/youtube/${tableData[0].video_id}`;
    const { data } = await axios.get(endPoint);
    setVideoData(data);
    setVideoMeta(
      tableData.filter((val) => val.video_id == tableData[0].video_id)[0]
    );
    setUpdateVideo(Date());
    setLoadingPage(false);
  };

  // onClick video from table, get details regarding individual video, update state and pass to child component
  const loadVideoDetails = async (e) => {
    e.preventDefault();

    setLoadingPage(true);

    const videoID = e.currentTarget.getAttribute("data-id");

    const endPoint = `/api/companies/active/${id}/youtube/${videoID}`;
    const { data } = await axios.get(endPoint);

    setVideoData(data);
    setVideoMeta(tableData.filter((val) => val.video_id == videoID)[0]);
    setUpdateVideo(Date());
    setLoadingPage(false);
  };

  const exportCSV = () => {
    const columns = gridRef.current.allColumns;

    const header = columns.map((c) => c.name).join(SEPARATOR);
    const rows = gridRef.current.dataSource.map((data) =>
      columns
        .map((c) =>
          typeof data[c.id] === "string" || data[c.id] instanceof String
            ? data[c.id].replace(/,/g, ";")
            : data[c.id]
        )
        .join(SEPARATOR)
    );

    const contents = [header].concat(rows).join("\n");
    const blob = new Blob([contents], { type: "text/csv;charset=utf-8;" });

    downloadBlob(blob);
  };

  const downloadBlob = (blob, fileName = companyData.name + " - Videos.csv") => {
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.position = "absolute";
    link.style.visibility = "hidden";

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  // ReactDataGrid settings
  const defaultSortInfo = { name: "date", dir: -1 };

  const rowStyle = ({ data }) => {
    return {
      backgroundColor: data.video_id == videoMeta.video_id ? "#d5dadd" : "",
    };
  };

  // ReactDataGrid Column with custom render
  const columns = [
    {
      name: "date",
      header: "Date",
      defaultFlex: 0.5,
      render: ({ data }) => {
        return formatDate(data.date, { month: "short" });
      },
    },
    {
      name: "title",
      header: "Title",
      defaultFlex: 1.1,
      render: ({ data }) => {
        return (
          <button
            title={data.title}
            data-id={data.video_id}
            onClick={loadVideoDetails}
            className="text-left"
          >
            {truncate(data.title, 90)}
          </button>
        );
      },
    },
    { name: "views", header: "Views", defaultFlex: 0.4, type: "number" },
  ];

  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 gap-y-8 xl:gap-8">
      {tableData && (
        <div>
          <BaseTable
            className="breakline"
            style={{ minHeight: 648 }}
            defaultSortInfo={defaultSortInfo}
            rowStyle={rowStyle}
            idProperty="videos"
            pagination={true}
            columns={columns}
            dataSource={tableData}
            rowHeight={null}
            handle={setGridRef}
          />
          <BaseButton
            label="Export CSV"
            className="mt-6 mb-6 self-start"
            onClick={exportCSV}
          />
        </div>
      )}

      {!loadingPage ? (
        <>
          {videoData.length !== 0 && (
            <VideoDetails
              key={updateVideo}
              data={videoData}
              videoMeta={videoMeta}
            />
          )}
        </>
      ) : (
        <div className="col-span-2 flex flex-grow justify-center items-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#e94a3e"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </div>
  );
};

export default VideosTable;
