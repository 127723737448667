import { createSlice } from "@reduxjs/toolkit";

export const clientSlice = createSlice({
  name: "client",
  initialState: {
    clientSector: { value: 0, label: "All Sectors" },
    clientExchange: { value: 0, label: "All Exchanges" },
    clientRange: parseInt(process.env.REACT_APP_CLIENT_RANGE_DEFAULT),
    clientData: {},
  },
  reducers: {
    updateClientSector: (state, action) => {
      state.clientSector = action.payload;
    },
    updateClientExchange: (state, action) => {
      state.clientExchange = action.payload;
    },
    updateClientRange: (state, action) => {
      state.clientRange = action.payload;
    },
    updateClientData: (state, action) => {
      state.clientData = action.payload;
    },
  },
});

export const { updateClientSector, updateClientExchange, updateClientRange, updateClientData } =
  clientSlice.actions;

export default clientSlice.reducer;
