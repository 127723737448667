import { createSlice } from "@reduxjs/toolkit";

export const performanceSlice = createSlice({
  name: "performance",
  initialState: {
    performanceData: {},
  },
  reducers: {
    updatePerformanceData: (state, action) => {
      state.performanceData = action.payload;
    },
  },
});

export const { updatePerformanceData } = performanceSlice.actions;

export default performanceSlice.reducer;