import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../api/AxiosHttp";
import { useSelector } from "react-redux";
import Noty from "../../lib/Noty";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

import ModalFeedback from "../../components/ModalFeedback";
import BaseTitle from "../../components/base/BaseTitle";
import BaseTable from "../../components/base/BaseTable";
import BaseButton from "../../components/base/BaseButton";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dangerModalOpen, setDangerModalOpen] = useState(false);
  const [dangerModalData, setDangerModalData] = useState({});

  const { userData } = useSelector((state) => state.auth);

  // Call the function on first load and everytime users state changes
  useEffect(() => {
    if (users.length == 0) {
      getUsers();
    }
  }, [users]);

  // Function to get the users then store it in states
  const getUsers = () => {
    const endPoint = "api/users";
    axios
      .get(endPoint)
      .then((result) => {
        setUsers(result.data);
        setLoading(false);
      })
      .catch((error) => {
        var er = error.response.data.error;
        var notymessage = er[Object.keys(er)[0]];

        new Noty({
          type: "error",
          text: notymessage,
        }).show();
      });
  };

  // Open Modal with a cancel or confirm delete action
  const handleDeleteDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const userId = e.currentTarget.getAttribute("data-id");
    const name = e.currentTarget.getAttribute("data-name");

    setDangerModalData({ user_id: userId, name: name });
    setDangerModalOpen(true);
  };

  // Delete user by calling endpoint with user ID
  const handleDelete = (e) => {
    e.preventDefault();

    const userId = e.currentTarget.getAttribute("data-id");
    const name = e.currentTarget.getAttribute("data-name");

    const endPoint = "api/users/" + userId;

    axios
      .delete(endPoint)
      .then((result) => {
        setDangerModalOpen(false);
        getUsers();

        new Noty({
          text: name + " was successfully deleted",
          type: "success",
          timeout: 3000,
          modal: true,
          progressBar: true,
        }).show();
      })
      .catch((error) => {
        var er = error.response.data.error;
        var notymessage = er[Object.keys(er)[0]];

        new Noty({
          type: "error",
          text: notymessage,
        }).show();
      });
  };

  // ReactDataGrid settings
  const defaultSortInfo = { name: "name", dir: 1 };

  // ReactDataGrid Columns with custom render
  const columns = [
    { name: "name", header: "Name",  defaultFlex: 0.8 },
    { name: "email", header: "Email", defaultFlex: 0.8 },
    {
      name: "region",
      header: "Region",
      defaultFlex: 0.4,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All Regions",
        dataSource: [
          { id: "UK", label: "UK" },
          { id: "US", label: "US" },
          { id: "CA", label: "CA" },
          { id: "AU", label: "AU" },
          { id: "Other", label: "Other" },
        ],
      },
    },
    {
      name: "role",
      header: "Role",
      defaultFlex: 0.4,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All Roles",
        dataSource: [
          {
            id: process.env.REACT_APP_CLIENT,
            label: process.env.REACT_APP_CLIENT,
          },
          {
            id: process.env.REACT_APP_STAFF,
            label: process.env.REACT_APP_STAFF,
          },
          {
            id: process.env.REACT_APP_ADMIN,
            label: process.env.REACT_APP_ADMIN,
          },
        ],
      },
    },
    { name: "companies", header: "Companies", defaultFlex: 0.75 },
    {
      name: "action",
      header: "Action",
      defaultFlex: 0.3,
      render: ({ data }) => {
        return (
          <div className="flex">
            <Link
              className="mr-2 bg-gray-500 rounded-md p-0 border-gray-500 border-2 hover:bg-gray-600 hover:border-gray-600"
              to={"/user/" + data.id + "/activity"}
              data-id={data.id}
              title="Activity"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#fff"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z" />
                <path d="M9 7l6 0" />
                <path d="M9 11l6 0" />
                <path d="M9 15l4 0" />
              </svg>
            </Link>
            <Link
              className="mr-2 bg-gray-500 rounded-md p-0 border-gray-500 border-2 hover:bg-gray-600 hover:border-gray-600"
              to={data.name === userData.name ? "/profile" : "/user/" + data.id}
              data-id={data.id}
              title="Edit"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="1"
                stroke="#fff"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
                <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
              </svg>
            </Link>
            {data.name !== userData.name && (
              <button
                className="bg-rose-500 rounded-md p-0 border-rose-500 border-2 hover:bg-rose-600 hover:border-rose-600"
                title="Delete"
                data-id={data.id}
                data-name={data.name}
                onClick={handleDeleteDialog}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-trash"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#fff"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="4" y1="7" x2="20" y2="7" />
                  <line x1="10" y1="11" x2="10" y2="17" />
                  <line x1="14" y1="11" x2="14" y2="17" />
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                </svg>
              </button>
            )}
          </div>
        );
      },
    },
  ];

  // ReactDataGrid filter settings for columns
  const filterValue = [
    { name: "name", operator: "contains", type: "string", value: "" },
    { name: "email", operator: "contains", type: "string", value: "" },
    { name: "region", operator: "inlist", type: "select", value: "" },
    { name: "companies", operator: "contains", type: "string", value: "" },
    { name: "role", operator: "startsWith", type: "string", value: "" },
  ];

  return (
    <>
      <ModalFeedback
        id="confirm-delete-user"
        modalOpen={dangerModalOpen}
        setModalOpen={setDangerModalOpen}
      >
        <div className="p-5 flex space-x-4">
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100">
            <svg
              className="w-4 h-4 shrink-0 fill-current text-rose-500"
              viewBox="0 0 16 16"
            >
              <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
            </svg>
          </div>
          <div>
            <div className="mb-2">
              <div className="text-lg font-semibold text-slate-800">
                Delete {dangerModalData.name}?
              </div>
            </div>
            <div className="text-sm mb-10">
              <div className="space-y-2 mr-2">
                <p>
                  This will remove the users access to the dashboard and delete
                  the user from our systems permanently.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap justify-end space-x-2">
              <button
                className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
                onClick={(e) => {
                  e.stopPropagation();
                  setDangerModalOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn-sm bg-rose-500 hover:bg-rose-600 text-white"
                data-id={dangerModalData.user_id}
                data-name={dangerModalData.name}
                onClick={handleDelete}
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </ModalFeedback>
      <div className="columns-2">
        <BaseTitle heading="Users" />
        <Link to="/user/add">
          <BaseButton label="Add User" className="float-right" type="button" />
        </Link>
      </div>
      <BaseTable
        className="mt-8"
        idProperty="user-table"
        columns={columns}
        dataSource={users}
        pagination={true}
        defaultSortInfo={defaultSortInfo}
        defaultFilterValue={filterValue}
        style={{ minHeight: 740 }}
        loading={loading}
        loadingText="Fetching users"
      />
    </>
  );
};

export default Users;
