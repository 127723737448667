import { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import { getUserData } from "./api/Auth";
import GoogleDashboard from "./pages/internal-analytics/GoogleDashboard";
import YoutubeDashboard from "./pages/internal-analytics/YoutubeDashboard";
import ClientDashboard from "./pages/internal-analytics/ClientDashboard";
import NewsLookupDashboard from "./pages/internal-analytics/NewsLookupDashboard";
import LiveDashboard from "./pages/internal-analytics/LiveDashboard";
import ActivityOverview from "./pages/thor-activity/ActivityOverview";
import Analytics from "./pages/analytics";
import Articles from "./pages/analytics/Articles";
import Faq from "./pages/analytics/Faq";
import Contact from "./pages/analytics/Contact";
import Dashboard from "./pages/analytics/Dashboard";
import Distribution from "./pages/analytics/Distribution";
import Forensics from "./pages/analytics/Forensics";
import Videos from "./pages/analytics/Videos";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Login from "./pages/auth/Login";
import ResetPassword from "./pages/auth/ResetPassword";
import ActiveCompanies from "./pages/companies/ActiveCompanies";
import Companies from "./pages/companies/Companies";
import PageNotFound from "./pages/page-not-found";
import Profile from "./pages/profile";
import Users from "./pages/users";
import AddUser from "./pages/users/AddUser";
import UserDetails from "./pages/users/UserDetails";
import UserActivity from "./pages/users/UserActivity";
import Layout from "./partials/layout";
import ProtectedRoute from "./partials/routes/ProtectedRoute";
import UnprotectedRoute from "./partials/routes/UnprotectedRoute";

// Import style
import "./css/tailwind-base-component.css";
import "./css/style.css";
import "./css/tailwind-utility.css";

// Render App
function App() {
  const location = useLocation();

  useEffect(() => {
    checkLoggedIn();
  }, []);

  async function checkLoggedIn() {
    if (
      location.pathname !== "/login" &&
      location.pathname !== "/forgot-password"
    ) {
      getUserData();
    }
  }

  return (
    <Routes>
      {/* Protected Routes: Logged in */}
      <Route
        element={
          <ProtectedRoute
            required={[
              `${process.env.REACT_APP_ADMIN}`,
              `${process.env.REACT_APP_STAFF}`,
              `${process.env.REACT_APP_CLIENT}`,
            ]}
          />
        }
      >
        <Route
          path="*"
          element={
            <Layout>
              <PageNotFound />
            </Layout>
          }
        />
        <Route
          path="profile"
          element={
            <Layout>
              <Profile />
            </Layout>
          }
        />
        <Route
          path="analytics/:id"
          element={
            <Layout type="analytics">
              <Analytics />
            </Layout>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="news" element={<Articles />} />
          <Route path="videos" element={<Videos />} />
          <Route path="forensics" element={<Forensics />} />
          <Route path="distribution" element={<Distribution />} />
          <Route path="faq" element={<Faq />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Route>

      {/* Protected Routes: Admin */}
      <Route
        element={
          <ProtectedRoute
            required={[
              `${process.env.REACT_APP_ADMIN}`,
              `${process.env.REACT_APP_STAFF}`,
            ]}
          />
        }
      >
        <Route path="/" element={<Navigate replace to="/companies/active" />} />
        <Route
          path="google"
          element={
            <Layout>
              <GoogleDashboard />
            </Layout>
          }
        />
        <Route
          path="youtube"
          element={
            <Layout>
              <YoutubeDashboard />
            </Layout>
          }
        />
        <Route
          path="client"
          element={
            <Layout>
              <ClientDashboard />
            </Layout>
          }
        />
        <Route
          path="news-lookup"
          element={
            <Layout>
              <NewsLookupDashboard />
            </Layout>
          }
        />
        <Route
          path="live"
          element={
            <Layout>
              <LiveDashboard />
            </Layout>
          }
        />
        <Route
          path="activity-overview"
          element={
            <Layout>
              <ActivityOverview />
            </Layout>
          }
        />
        <Route
          path="users"
          element={
            <Layout>
              <Users />
            </Layout>
          }
        />
        <Route
          path="user/:id"
          element={
            <Layout>
              <UserDetails />
            </Layout>
          }
        />
        <Route
          path="user/:id/activity"
          element={
            <Layout>
              <UserActivity />
            </Layout>
          }
        />
        <Route
          path="user/add"
          element={
            <Layout>
              <AddUser />
            </Layout>
          }
        />
        <Route
          path="companies"
          element={
            <Layout>
              <Companies />
            </Layout>
          }
        />
        <Route
          path="companies/active"
          element={
            <Layout>
              <ActiveCompanies />
            </Layout>
          }
        />
      </Route>

      {/* Unprotected Routes */}
      <Route element={<UnprotectedRoute />}>
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:token" element={<ResetPassword />} />
        <Route path="login" element={<Login />} />
      </Route>
    </Routes>
  );
}

export default App;
