import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import axios from "../../api/AxiosHttp";

import BaseTitle from "../../components/base/BaseTitle";
import BaseCard from "../../components/base/BaseCard";

const Faq = () => {
  const { companyData } = useSelector((state) => state.company);
  const { userData } = useSelector((state) => state.auth);

  useEffect(() => {
    logActivity();
  }, []);

  const logActivity = () => {
    if (
      userData.role === process.env.REACT_APP_CLIENT &&
      !sessionStorage.getItem("view_faq_" + companyData.company_id)
    ) {
      const endPoint = `/api/users/${userData.id}/activity`;
      axios.post(endPoint, {
        model_id: companyData.company_id,
        action: "view_faq",
        type: "company",
      });
      sessionStorage.setItem("view_faq_" + companyData.company_id, true);
    }
  };

  return (
    <>
      <BaseTitle heading="FAQ" subtitle={companyData.name} />

      <BaseCard>
        <div>
          <article className="py-4 border-b border-slate-200">
            <header className="flex items-start mb-2">
              <div className="mt-2 mr-3">
                <svg
                  className="w-4 h-4 shrink-0 fill-current"
                  viewBox="0 0 16 16"
                >
                  <path
                    className="text-indigo-300"
                    d="M4 8H0v4.9c0 1 .7 1.9 1.7 2.1 1.2.2 2.3-.8 2.3-2V8z"
                  />
                  <path
                    className="text-indigo-500"
                    d="M15 1H7c-.6 0-1 .4-1 1v11c0 .7-.2 1.4-.6 2H13c1.7 0 3-1.3 3-3V2c0-.6-.4-1-1-1z"
                  />
                </svg>
              </div>
              <h3 className="text-xl leading-snug text-slate-800 font-bold">
                How can the widget to my website?
              </h3>
            </header>
            <div className="pl-7">
              <div className="mb-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua ut
                enim ad minim veniam, quis nostrud exercitation ullamco.
              </div>
            </div>
          </article>
          
          <article className="py-4 border-b border-slate-200">
            <header className="flex items-start mb-2">
              <div className="mt-2 mr-3">
                <svg
                  className="w-4 h-4 shrink-0 fill-current"
                  viewBox="0 0 16 16"
                >
                  <path
                    className="text-indigo-300"
                    d="M4 8H0v4.9c0 1 .7 1.9 1.7 2.1 1.2.2 2.3-.8 2.3-2V8z"
                  />
                  <path
                    className="text-indigo-500"
                    d="M15 1H7c-.6 0-1 .4-1 1v11c0 .7-.2 1.4-.6 2H13c1.7 0 3-1.3 3-3V2c0-.6-.4-1-1-1z"
                  />
                </svg>
              </div>
              <h3 className="text-xl leading-snug text-slate-800 font-bold">
                What would happen if I choose not to pay after the usage?
              </h3>
            </header>
            <div className="pl-7">
              <div className="mb-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua ut
                enim ad minim veniam, quis nostrud exercitation ullamco.
              </div>
            </div>
          </article>
          
          <article className="py-4 border-b border-slate-200">
            <header className="flex items-start mb-2">
              <div className="mt-2 mr-3">
                <svg
                  className="w-4 h-4 shrink-0 fill-current"
                  viewBox="0 0 16 16"
                >
                  <path
                    className="text-indigo-300"
                    d="M4 8H0v4.9c0 1 .7 1.9 1.7 2.1 1.2.2 2.3-.8 2.3-2V8z"
                  />
                  <path
                    className="text-indigo-500"
                    d="M15 1H7c-.6 0-1 .4-1 1v11c0 .7-.2 1.4-.6 2H13c1.7 0 3-1.3 3-3V2c0-.6-.4-1-1-1z"
                  />
                </svg>
              </div>
              <h3 className="text-xl leading-snug text-slate-800 font-bold">
                What limitations do trial accounts have?
              </h3>
            </header>
            <div className="pl-7">
              <div className="mb-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua ut
                enim ad minim veniam, quis nostrud exercitation ullamco.
              </div>
            </div>
          </article>
          
          <article className="py-4 border-b border-slate-200">
            <header className="flex items-start mb-2">
              <div className="mt-2 mr-3">
                <svg
                  className="w-4 h-4 shrink-0 fill-current"
                  viewBox="0 0 16 16"
                >
                  <path
                    className="text-indigo-300"
                    d="M4 8H0v4.9c0 1 .7 1.9 1.7 2.1 1.2.2 2.3-.8 2.3-2V8z"
                  />
                  <path
                    className="text-indigo-500"
                    d="M15 1H7c-.6 0-1 .4-1 1v11c0 .7-.2 1.4-.6 2H13c1.7 0 3-1.3 3-3V2c0-.6-.4-1-1-1z"
                  />
                </svg>
              </div>
              <h3 className="text-xl leading-snug text-slate-800 font-bold">
                Is there any difference between Standard and Plus licenses?
              </h3>
            </header>
            <div className="pl-7">
              <div className="mb-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua ut
                enim ad minim veniam, quis nostrud exercitation ullamco.
              </div>
            </div>
          </article>
          
          <article className="py-4 border-b border-slate-200">
            <header className="flex items-start mb-2">
              <div className="mt-2 mr-3">
                <svg
                  className="w-4 h-4 shrink-0 fill-current"
                  viewBox="0 0 16 16"
                >
                  <path
                    className="text-indigo-300"
                    d="M4 8H0v4.9c0 1 .7 1.9 1.7 2.1 1.2.2 2.3-.8 2.3-2V8z"
                  />
                  <path
                    className="text-indigo-500"
                    d="M15 1H7c-.6 0-1 .4-1 1v11c0 .7-.2 1.4-.6 2H13c1.7 0 3-1.3 3-3V2c0-.6-.4-1-1-1z"
                  />
                </svg>
              </div>
              <h3 className="text-xl leading-snug text-slate-800 font-bold">
                Is my personal information protected?
              </h3>
            </header>
            <div className="pl-7">
              <div className="mb-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua ut
                enim ad minim veniam, quis nostrud exercitation ullamco.
              </div>
            </div>
          </article>
          
          <article className="py-4 border-b border-slate-200">
            <header className="flex items-start mb-2">
              <div className="mt-2 mr-3">
                <svg
                  className="w-4 h-4 shrink-0 fill-current"
                  viewBox="0 0 16 16"
                >
                  <path
                    className="text-indigo-300"
                    d="M4 8H0v4.9c0 1 .7 1.9 1.7 2.1 1.2.2 2.3-.8 2.3-2V8z"
                  />
                  <path
                    className="text-indigo-500"
                    d="M15 1H7c-.6 0-1 .4-1 1v11c0 .7-.2 1.4-.6 2H13c1.7 0 3-1.3 3-3V2c0-.6-.4-1-1-1z"
                  />
                </svg>
              </div>
              <h3 className="text-xl leading-snug text-slate-800 font-bold">
                What can I create with with this product?
              </h3>
            </header>
            <div className="pl-7">
              <div className="mb-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua ut
                enim ad minim veniam, quis nostrud exercitation ullamco.
              </div>
            </div>
          </article>
        </div>

      </BaseCard>
    </>
  );
};

export default Faq;
