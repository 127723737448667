import resolveConfig from "tailwindcss/resolveConfig";

import Noty from "../lib/Noty";

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig("./tailwind.config.js");
};

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const handleErrorNoty = (error) => {
  if (error.response) {
    const er = error.response.data.errors;
    const notymessage = er[Object.keys(er)[0]];
    new Noty({
      type: "error",
      text: notymessage,
    }).show();
  }
};

export const handleSuccessNoty = (msg) => {
  new Noty({
    type: "success",
    text: msg,
    timeout: 3000,
    modal: true,
    progressBar: true,
  }).show();
};

export const formatDataForSeries = (data) => {
  if (data) {
    return Object.entries(data).map(([date, value]) => {
      const timestamp = new Date(date).getTime();
      return [timestamp, value];
    });
  } else {
    return [];
  }
};

export const formatDataForFlags = (data) => {
  if (data) {
    return data.map((item) => ({
      x: new Date(item.date).getTime(),
      text: item.title,
      title: "",
      style: {
        color: "rgba(0,0,0,0)",
      },
    }));
  } else {
    return [];
  }
};

export const formatDateToTimestamp = (date) => {
  return new Date(date).getTime();
};

export const isURL = (str) => {
  // This pattern matches domain names with optional subdomains
  const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return domainPattern.test(str);
};

export const toCamel = (string) => {
  if (isURL(string)) {
    return string;
  }

  return string.toLowerCase().replace(/(?:_| |\b)(\w)/g, function ($1) {
    return $1.toUpperCase().replace("_", " ");
  });
};

export const formatDate = (date, dateOptions) => {
  const defaultOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  dateOptions = { ...defaultOptions, ...dateOptions };

  return new Date(date).toLocaleDateString("en-GB", dateOptions);
};

export const formatTime = (date) => {
  return new Date(date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

export const minTommss = (minutes) => {
  var sign = minutes < 0 ? "-" : "";
  var min = Math.floor(Math.abs(minutes));
  var sec = Math.floor((Math.abs(minutes) * 60) % 60);
  return sign + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
};

export const truncate = (str, n) => {
  return str && str.length > n
    ? str.slice(0, n).split(" ").slice(0, -1).join(" ") + "..."
    : str;
};

export const formatThousands = (value) => {
  return value ? parseInt(value)?.toLocaleString() : 0;
};

export const objectMerge = (current, update) => {
  Object.keys(update).forEach(function (key) {
    // if update[key] exist, and it's not a string or array,
    // we go in one level deeper
    if (
      current.hasOwnProperty(key) &&
      typeof current[key] === "object" &&
      !(current[key] instanceof Array)
    ) {
      objectMerge(current[key], update[key]);

      // if update[key] doesn't exist in current, or it's a string
      // or array, then assign/overwrite current[key] to update[key]
    } else {
      current[key] = update[key];
    }
  });
  return current;
};

// export const formatThousands = (value) => {
//   return value
//     ? Intl.NumberFormat("en-US", {
//         maximumSignificantDigits: 3,
//         notation: "compact",
//       }).format(value)
//     : 0;
// };

export const formatObjectToArrOfObjects = (
  dataObject,
  customKey,
  customValue
) => {
  const formattedArray = [];

  if (dataObject) {
    for (const key in dataObject) {
      formattedArray.push({
        [customKey]: toCamel(key).replace(/YT /i, ""),
        [customValue]: dataObject[key],
      });
    }
  }

  return formattedArray;
};

export const formatObjectToArrOfArrays = (dataObject) => {
  const formattedArray = [];

  if (dataObject) {
    for (const key in dataObject) {
      formattedArray.push([toCamel(key).replace(/YT /i, ""), dataObject[key]]);
    }
  }

  return formattedArray;
};
