import BaseCard from "../../components/base/BaseCard";
import BaseTable from "../../components/base/BaseTable";

import { formatThousands } from "../../utils/Utils";

const LiveDashboardDetails = ({ data }) => {
  const tableCols = [
    {
      name: "url",
      header: "URL",
      defaultFlex: 5,
      render: ({ data }) => {
        return (
          <a
            title={data.title}
            className="text-primary"
            href={`https://${data.url}`}
            target="_blank"
          >
            {data.title}
          </a>
        );
      },
    },
    {
      name: "count",
      header: "Views",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return formatThousands(data.count);
      },
    },
  ];

  return (
    <>
      {data &&
        Object.keys(data).map((domain) => {
          if (domain !== "updated_at") {
            const domainData = data[domain];
            return (
              <>
                <h3 className="text-violet font-bold uppercase flex items-center text-xl mb-6">
                  {domain}
                </h3>
                <div className="grid grid-cols-3 gap-x-8">
                  <BaseCard>
                    <h4 className="text-xl mb-4 uppercase text-violet font-bold w-fit">
                      Activity
                    </h4>

                    <h5 className="text-lg mb-4 text-violet font-bold">
                      Last 30 Minutes
                    </h5>

                    <div className="grid gap-4 grid-cols-3">
                      <div>
                        <span className="text-3xl text-red font-bold lg:text-[1.8rem]">
                          {formatThousands(domainData.page_views_30m)}
                        </span>
                        <h5 className="text-sm text-slate-500">Page Views</h5>
                      </div>

                      <div>
                        <span className="text-3xl text-red font-bold lg:text-[1.8rem]">
                          {formatThousands(domainData.unique_page_views_30m)}
                        </span>
                        <h5 className="text-sm text-slate-500">
                          Unique Page Views
                        </h5>
                      </div>

                      <div>
                        <span className="text-3xl text-red font-bold lg:text-[1.8rem]">
                          {formatThousands(domainData.visitors_30m)}
                        </span>
                        <h5 className="text-sm text-slate-500">Visitors</h5>
                      </div>
                    </div>

                    <h5 className="text-lg mt-8 mb-4 text-violet font-bold">
                      Since Midnight
                    </h5>

                    <div className="grid gap-4 grid-cols-3">
                      <div>
                        <span className="text-3xl text-red font-bold lg:text-[1.8rem]">
                          {formatThousands(domainData.page_views_day_total)}
                        </span>
                        <h5 className="text-sm text-slate-500">Page Views</h5>
                      </div>

                      <div>
                        <span className="text-3xl text-red font-bold lg:text-[1.8rem]">
                          {formatThousands(
                            domainData.unique_page_views_day_total
                          )}
                        </span>
                        <h5 className="text-sm text-slate-500">
                          Unique Page Views
                        </h5>
                      </div>

                      <div>
                        <span className="text-3xl text-red font-bold lg:text-[1.8rem]">
                          {formatThousands(domainData.visitors_day_total)}
                        </span>
                        <h5 className="text-sm text-slate-500">Visitors</h5>
                      </div>
                    </div>
                  </BaseCard>

                  <BaseCard>
                    <h4 className="text-xl mb-4 uppercase text-violet font-bold w-fit">
                      Top Pages
                    </h4>
                    <BaseTable
                      idProperty="user-cities"
                      dataSource={domainData.top_pages}
                      columns={tableCols}
                      style={{ minHeight: 283 }}
                      className="mt-6 no-border"
                      scrollHide={false}
                    />
                  </BaseCard>

                  <BaseCard>
                    <h4 className="text-xl mb-4 uppercase text-violet font-bold w-fit">
                      Entry Pages
                    </h4>
                    <BaseTable
                      idProperty="user-cities"
                      dataSource={domainData.entry_pages}
                      columns={tableCols}
                      style={{ minHeight: 283 }}
                      className="mt-6 no-border"
                      scrollHide={false}
                    />
                  </BaseCard>
                </div>
              </>
            );
          }
        })}
    </>
  );
};

export default LiveDashboardDetails;
