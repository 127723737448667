import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import axios from "../../api/AxiosHttp";
import BaseButton from "../../components/base/BaseButton";
import Noty from "../../lib/Noty";
import {
  updateUserData,
  setIsLoggedIn,
  setCurrentCompanyId,
} from "../../redux/authSlice";

import paLogo from "../../images/pa-analytics-logo.svg";
import authIllustration from "../../images/auth-illustration.svg";

const Login = () => {
  const [twoFA, setTwoFA] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const email = useRef(null);
  const password = useRef(null);
  const code = useRef(null);

  const dispatch = useDispatch();

  // Handle login form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitLoading(true);

    try {
      await axios.get("/sanctum/csrf-cookie");
    } catch (error) {
      console.error(error);
      new Noty({ type: "error", text: "Failed to fetch CSRF cookie." }).show();
      setSubmitLoading(false);
      return;
    }

    try {
      const { data } = await axios.post("/api/login", {
        email: email.current.value,
        password: password.current.value,
      });

      if (data?.two_factor === true) {
        setSubmitLoading(false);
        setTwoFA(true);
      } else {
        dispatch(updateUserData(data));
        dispatch(setIsLoggedIn(true));
        dispatch(
          setCurrentCompanyId(
            data.active_companies?.length > 0
              ? data.active_companies[0].id
              : null
          )
        );
        return;
      }
    } catch (error) {
      setSubmitLoading(false);
      if (error.response) {
        const {
          data: { errors },
        } = error.response;
        const notymessage = errors[Object.keys(errors)[0]];
        new Noty({ type: "error", text: notymessage }).show();
      } else {
        console.error(error);
      }
    }
  };

  // If 2FA is enabled, send number field to 2FA endpoint
  const handleCode = async (e) => {
    e.preventDefault();

    setSubmitLoading(true);

    try {
      await axios.post("api/two-factor-challenge", {
        code: code.current.value,
      });
      const result = await axios.get("/api/me");
      dispatch(updateUserData(result.data));
      dispatch(setIsLoggedIn(true));
      dispatch(
        setCurrentCompanyId(
          result.data.active_companies?.length > 0
            ? result.data.active_companies[0].id
            : null
        )
      );
    } catch (error) {
      setSubmitLoading(false);
      if (error.response) {
        const {
          data: { errors },
        } = error.response;
        const notymessage = errors[Object.keys(errors)[0]];
        new Noty({ type: "error", text: notymessage }).show();
      } else {
        console.error(error);
      }
    }
  };

  return (
    <main className="bg-white min-h-screen">
      <div className="relative md:flex">
        <div
          className="hidden md:flex md:flex-col md:items-center md:justify-center md:w-1/2 md:h-screen bg-[#f9f9f9]"
          aria-hidden="true"
        >
          <img className="w-[70%] max-w-[650px]" src={authIllustration} />
          <div className="px-4 py-8 md:py-0 md:px-0 text-left mt-24 max-w-md mx-auto">
            <h3 className="text-[1.9rem] text-slate-800 font-[500] mb-6">
              Welcome to our digital analytics platform!
            </h3>
            <p>
              We use Google Analytics, Search Console and YouTube Analytics data
              to inform our clients about their content and performance. Our
              goal is to help you understand your users, improve your
              understanding, and increase your online visibility.
            </p>
          </div>
        </div>

        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            <div className="flex-1"></div>

            <div className="max-w-md mx-auto px-4 py-8 w-full">
              <div className="flex items-center justify-between py-12">
                <img
                  src={paLogo}
                  alt="Proactive Investors"
                  className="w-52"
                ></img>
              </div>
              <h1 className="text-3xl text-slate-800 font-bold mb-2">
                Welcome back
              </h1>
              <p className="mb-8">Please enter your credentials to sign in</p>
              {!twoFA ? (
                <form onSubmit={handleSubmit}>
                  <div className="space-y-4">
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="email"
                      >
                        Email Address
                      </label>
                      <input
                        id="email"
                        className="form-input w-full"
                        type="email"
                        placeholder="example@example.com"
                        ref={email}
                        autoComplete="true"
                        required
                      />
                    </div>
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password"
                      >
                        Password
                      </label>
                      <input
                        id="password"
                        className="form-input w-full"
                        type="password"
                        placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                        autoComplete="on"
                        ref={password}
                        minLength="6"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-between mt-2">
                    <div className="mr-1 mb-4 self-end">
                      <Link className="text-sm" to="/forgot-password">
                        Forgot Password?
                      </Link>
                    </div>
                    <BaseButton
                      label="Sign In"
                      type="submit"
                      loading={submitLoading}
                      styleOptions={{ isFullWidth: true, size: "large" }}
                    />
                  </div>
                </form>
              ) : (
                <form onSubmit={handleCode}>
                  <div className="mr-1 mb-4 self-end">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="code"
                    >
                      Two Factor Authentication
                    </label>
                    <input
                      id="code"
                      className="form-input w-full"
                      type="number"
                      placeholder="Enter Code"
                      ref={code}
                      required
                    />
                  </div>
                  <div className="flex flex-col items-center justify-between mt-2">
                    <BaseButton
                      label="Verify"
                      type="submit"
                      loading={submitLoading}
                      styleOptions={{ isFullWidth: true, size: "large" }}
                    />
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
