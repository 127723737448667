import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import axios from "../../../api/AxiosHttp";

import { updatePerformanceData } from "../../../redux/performanceSlice";
import BaseCard from "../../../components/base/BaseCard";

const PerformanceTable = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { performanceData } = useSelector((state) => state.performance);
  const { companyData } = useSelector((state) => state.company);
  const { userData } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPerformanceBreakdown();
  }, [companyData]);

  const getPerformanceBreakdown = async () => {
    if (
      performanceData &&
      performanceData.active_company_id &&
      performanceData.active_company_id == id &&
      Date.now() - performanceData.timestamp < 300000
    ) {
      return;
    }

    setLoading(true);
    dispatch(updatePerformanceData({}));

    try {
      const endPoint = `/api/companies/active/${id}/performance`;
      const { data } = await axios.get(endPoint);
      dispatch(updatePerformanceData({ ...data, timestamp: Date.now() }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  function renderTableData(performanceData, field) {
    return Object.values(performanceData).map((item, index) => {
      return (
        <th key={index}>
          {item[field] === 0 ? "-" : item[field].toLocaleString()}
        </th>
      );
    });
  }

  function tableToCSV() {
    if (userData.role === process.env.REACT_APP_CLIENT) {
      const endPoint = `/api/users/${userData.id}/activity`;
      axios.post(endPoint, {
        model_id: companyData.company_id,
        action: "export_performance_summary",
        type: "company",
      });
    }

    // Variable to store the final csv data
    var csv_data = [];

    // Get each row data
    var rows = document.getElementsByTagName("tr");
    for (var i = 0; i < rows.length; i++) {
      // Get each column data
      var cols = rows[i].querySelectorAll("td,th");

      // Stores each csv row data
      var csvrow = [];
      for (var j = 0; j < cols.length; j++) {
        // Get the text data of each cell
        // of a row and push it to csvrow
        //remove comas from content to avoid csv formating issue
        var content = cols[j].innerHTML.replace(",", "");

        csvrow.push(content);
      }

      // Combine each column value with comma
      csv_data.push(csvrow.join(","));
    }

    // Combine each row data with new line character
    csv_data = csv_data.join("\n");

    // Call this function to download csv file
    downloadCSVFile(csv_data);
  }

  function downloadCSVFile(csv_data) {
    // Create CSV file object and feed
    // our csv_data into it
    const CSVFile = new Blob([csv_data], {
      type: "text/csv",
    });

    // Create to temporary link to initiate
    // download process
    var temp_link = document.createElement("a");

    // Download csv file
    temp_link.download = "Performance Summary.csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    // Automatically click the link to
    // trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  }

  return (
    <>
      {!loading ? (
        <>
          {performanceData && Object.keys(performanceData).length > 0 ? (
            <BaseCard className="mb-12">
              <div className="mb-4 sm:mb-8 flex justify-between items-center">
                <h4 className="text-xl mb-4 uppercase text-violet font-bold w-fit">
                  Performance Summary
                </h4>
                <button
                  className="btn text-white bg-primary hover:bg-primary-dark"
                  onClick={tableToCSV}
                >
                  Export
                </button>
              </div>

              {typeof performanceData === "object" &&
                performanceData !== null && (
                  <div className="overflow-x-scroll md:overflow-hidden">
                    <table className="performance-table">
                      <thead>
                        <tr>
                          <th>Metrics</th>
                          {performanceData.items &&
                            Object.keys(performanceData.items).length > 0 &&
                            Object.keys(performanceData.items).map(
                              (item, index) => {
                                return <th key={index}>{item}</th>;
                              }
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Article Views</th>
                          {renderTableData(
                            performanceData.items,
                            "article_views"
                          )}
                        </tr>
                        <tr>
                          <th>Article Impressions</th>
                          {renderTableData(
                            performanceData.items,
                            "article_impressions"
                          )}
                        </tr>
                        <tr>
                          <th>Article Views and Impressions</th>
                          {renderTableData(
                            performanceData.items,
                            "article_views_and_impressions"
                          )}
                        </tr>
                        <tr>
                          <th>Video Views</th>
                          {renderTableData(
                            performanceData.items,
                            "video_views"
                          )}
                        </tr>
                        <tr>
                          <th>Total Views</th>
                          {renderTableData(
                            performanceData.items,
                            "total_views"
                          )}
                        </tr>
                        <tr>
                          <th>Total Views and Impressions</th>
                          {renderTableData(
                            performanceData.items,
                            "total_views_and_impressions"
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              <p className="mt-4 italic text-sm">
                Views and impressions are grouped by quarter based on the date
                when the article or video was released. This means that the data
                you see represents the performance of the content during the
                quarter in which it was published.
              </p>
            </BaseCard>
          ) : (
            <BaseCard>There is no data for performance.</BaseCard>
          )}
        </>
      ) : (
        <div className="flex flex-grow justify-center items-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#e94a3e"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

export default PerformanceTable;
