import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import axios from "../../../api/AxiosHttp";
import DetailedStats from "../../../components/DetailedStats";
import BaseCard from "../../../components/base/BaseCard";
import BaseTable from "../../../components/base/BaseTable";
import DoughnutChart from "../../../components/charts/DoughnutChart";
import StockChart from "../../../components/charts/StockChart";
import Tippy from "../../../lib/Tipy";
import {
  formatDataForSeries,
  formatDateToTimestamp,
  formatDate,
  formatTime,
  toCamel,
  hexToRGB,
} from "../../../utils/Utils";

const ArticleDetails = ({ data }) => {
  const [forensicsCompany, setForensicsCompany] = useState(
    data?.lead_forensics[0]
  );

  const { companyData } = useSelector((state) => state.company);
  const { userData } = useSelector((state) => state.auth);

  useEffect(() => {
    Tippy("[data-tippy-content]");
    logActivity();
  }, []);

  const logActivity = async () => {
    if (
      userData.role === process.env.REACT_APP_CLIENT &&
      !sessionStorage.getItem(
        "view_article_" + data.news_id + companyData.company_id
      )
    ) {
      const endPoint = `/api/users/${userData.id}/activity`;
      axios.post(endPoint, {
        model_id: data.news_id,
        details: data.details.title,
        action: "view_article",
        type: "news",
      });
      sessionStorage.setItem(
        "view_article_" + data.news_id + companyData.company_id,
        true
      );
    }
  };

  const countriesTable = [
    { name: "key", header: "Country", defaultFlex: 1.1 },
    {
      name: "value",
      header: "%",
      defaultFlex: 0.3,
      render: ({ data }) => {
        return `${data.value}%`;
      },
    },
  ];
  const referralTable = [
    { name: "key", header: "Referral Source", defaultFlex: 1.1 },
    {
      name: "value",
      header: "%",
      defaultFlex: 0.3,
      render: ({ data }) => {
        return `${data.value}%`;
      },
    },
  ];

  const forensicsTable = [
    {
      name: "name",
      header: "Companies",
      defaultFlex: 1.1,
      render: ({ data }) => {
        return (
          <button
            data-id={data.id}
            onClick={showForensicsCompany}
            className={data.id == forensicsCompany.id && "text-primary"}
          >
            {data.name}
          </button>
        );
      },
    },
  ];

  const viewsOverTimechartData = {
    plotOptions: {
      areaspline: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, `rgba(${hexToRGB("#FDC700")}, 0.4)`],
            [1, `rgba(${hexToRGB("#FDC700")}, 0.1)`],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 2,
        lineColor: "#FF9800",
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        threshold: null,
      },
    },
    xAxis: {
      min: formatDateToTimestamp(Object.keys(data.chart_data)[0]),
      max: formatDateToTimestamp(Object.keys(data.chart_data)[0]) + 12096e5,
    },
    scrollbar: {
      barBackgroundColor: "#777777",
      barBorderWidth: 0,
      buttonArrowColor: "#D5DADD",
      rifleColor: "#D5DADD",
      buttonBackgroundColor: "#777777",
      trackBackgroundColor: "#F4F4F4",
      height: 23,
    },
    navigator: {
      maskFill: "rgba(51, 51, 51, .15)",
      outlineColor: "#333333",
      handles: {
        backgroundColor: "#777777",
        borderColor: "#D5DADD",
        height: 20,
        width: 10,
      },
      series: {
        color: "#FF9800",
        fillColor: "#fff",
      },
    },
    series: [
      {
        name: "Views",
        data: formatDataForSeries(data.chart_data),
        type: "areaspline",
      },
    ],
  };

  var newReturnChartDataColors = ["#FFA100", "#FF7338", "#FF5167", "#E44289"];
  const newReturnChartData = {
    series: [
      {
        name: "Sessions By Device",
        data: data.news_stats.new_vs_returning.map((item, index) => ({
          name: toCamel(item.key),
          y: parseFloat(item.value),
          color: newReturnChartDataColors[index],
        })),
      },
    ],
  };

  var userDeviceChartDataColors = ["#369440", "#A0BF4D", "#D9DD3F"];
  const userDeviceChartData = {
    series: [
      {
        name: "Sessions By Device",
        data: data.device_breakdown.map((item, index) => ({
          name: toCamel(item.key).replace("Views", ""),
          y: parseFloat(item.value),
          color: userDeviceChartDataColors[index],
        })),
      },
    ],
  };

  if (data.totals[0].value == null) {
    return (
      <div className="col-span-2">
        <BaseCard>
          No data found for this article, please check back later.
        </BaseCard>
      </div>
    );
  }

  const showForensicsCompany = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    setForensicsCompany(
      data.lead_forensics.find((company) => company.id == id)
    );
  };

  const statsTooltip = [
    "Sourced from Google Analytics 4, the total number of views each article has received on Proactive’s platform.",
    "Sourced from Google Search Console, the number of times each article has appeared in search results.",
    // "Sourced from Google Analytics 4, the number of times a user has downloaded a PDF version of the article.",
    // "Sourced from Google Analytics 4, the number of times a logged in user has bookmarked an article within MyProactive.",
    "Sourced from Google Analytics 4, the number of times a user used our website to directly share an article over social media.",
  ];

  return (
    <div className="col-span-2">
      <BaseCard id="articleDetails" className="px-0 py-0">
        <div className="mx-auto sm:max-w-xl md:max-w-full p-4 lg:p-8 lg:pb-12">
          <div className="flex flex-wrap gap-y-6">
            {data.totals.map((item, index) => {
              if (
                !data.details.is_research &&
                (item.key === "research_downloads" ||
                  item.key === "research_views")
              ) {
                return;
              }

              return (
                <DetailedStats
                  key={index}
                  label={item.key}
                  value={item.value}
                  data-tippy-content={statsTooltip[index]}
                />
              );
            })}
          </div>
        </div>

        <div className="border-t border-solid p-4 lg:p-8 lg:pt-12">
          <div className="pb-12 mx-auto sm:max-w-xl md:max-w-full">
            <div>
              <div className="col-span-10 flex flex-col gap-y-4">
                <h2 className="text-3xl font-bold">{data.details.title}</h2>

                <div className="col-span-2 flex-col items-start md:flex-row lg:items-end inline-flex w-full">
                  <p className="text-1xl mt-3 mb-2 text-olive font-bold mr-10">
                    {formatDate(data.details.date)}
                    <span className="ml-6">
                      {formatTime(data.details.date)}
                    </span>
                  </p>
                  <div>
                    <a
                      href={"https://" + data.details.url}
                      className="btn bg-light-grey-dark hover:bg-dark-grey hover:text-white text-dark-grey font-bold rounded-[20px] mr-4"
                      target="_blank"
                    >
                      View Article
                    </a>
                    <a
                      href={`https://www.google.com/search?q="${data.details.title.replace(
                        "&",
                        "%26"
                      )}"`}
                      className="btn bg-light-grey-dark hover:bg-dark-grey hover:text-white text-dark-grey font-bold rounded-[20px]"
                      target="_blank"
                    >
                      Syndication
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb-11 mx-auto sm:max-w-xl md:max-w-full">
            <div className="grid grid-cols-1 gap-8">
              <div>
                <h4
                  className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                  data-tippy-content="Placeholder, need text here"
                >
                  Page views over time
                </h4>
                <div className="grow">
                  <StockChart options={viewsOverTimechartData} />
                </div>
              </div>
            </div>
          </div>

          <div className="pb-12 mx-auto sm:max-w-xl md:max-w-full">
            <div className="grid lg:grid-cols-2 gap-8">
              <div>
                <h4
                  className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                  data-tippy-content="Sourced from Google Analytics 4, the percentage of visitors who are new to our website vs. those who have visited previously. GA4 can track individual visitors across multiple user devices."
                >
                  New vs. Returning
                </h4>
                {data.news_stats.new_vs_returning.length > 0 ? (
                  <DoughnutChart options={newReturnChartData} />
                ) : (
                  <div className="w-full h-full flex flex-col justify-start items-left">
                    <div
                      className="w-full h-full flex items-center justify-center font-bold"
                      style={{
                        borderRadius: "100%",
                        border: "35px solid #f1f5f9",
                        width: "230px",
                        height: "230px",
                        marginTop: "1rem",
                      }}
                    >
                      Not enough data
                    </div>
                  </div>
                )}
              </div>
              <div>
                <h4
                  className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                  data-tippy-content="Sourced from Google Analytics 4, the number of engaged sessions by each user device category."
                >
                  User Devices
                </h4>

                {data.device_breakdown.length > 0 ? (
                  <DoughnutChart options={userDeviceChartData} />
                ) : (
                  <div className="w-full h-full flex flex-col justify-start items-left">
                    <div
                      className="w-full h-full flex items-center justify-center font-bold"
                      style={{
                        borderRadius: "100%",
                        border: "35px solid #f1f5f9",
                        width: "230px",
                        height: "230px",
                        marginTop: "1rem",
                      }}
                    >
                      Not enough data
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="pb-12 mx-auto sm:max-w-xl md:max-w-full">
            <div className="grid-cols-1 grid xl:grid-cols-2 gap-8">
              <div>
                <h4
                  className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                  data-tippy-content="Sourced from Google Analytics 4, displaying the top 10 visitor countries for each article as a percentage of overall traffic."
                >
                  Top 10 Visitor Countries
                </h4>
                <BaseTable
                  idProperty="visitor-countries"
                  dataSource={data.news_stats.countries}
                  columns={countriesTable}
                  style={{ minHeight: 283 }}
                  className="mt-6 no-border"
                  scrollHide={false}
                />
              </div>
              <div>
                <h4
                  className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                  data-tippy-content="Sourced from Google Analytics 4, displaying the top 10 referral traffic sources for each article as a percentage of overall traffic."
                >
                  Top 10 External Referral
                </h4>
                <BaseTable
                  idProperty="referral-sources"
                  dataSource={data.news_stats.referrers}
                  columns={referralTable}
                  style={{ minHeight: 283 }}
                  className="mt-6 no-border"
                  scrollHide={false}
                />
              </div>
            </div>
          </div>

          <div className="pb-12 mx-auto sm:max-w-xl md:max-w-full">
            <h4
              className="text-xl mb-4 uppercase text-violet font-bold w-fit"
              data-tippy-content="Sourced from Lead Forensics, displaying known and identified businesses which have visited each article."
            >
              Corporate Visitors
            </h4>
            <div className="grid-cols-1 grid lg:grid-cols-2 gap-8 mt-6">
              <div>
                <BaseTable
                  idProperty="lead-forensics"
                  dataSource={data.lead_forensics}
                  columns={forensicsTable}
                  className="no-border"
                  style={{ minHeight: 283 }}
                  scrollHide={false}
                />
              </div>
              {forensicsCompany && (
                <div className="bg-[#f8f8f8] py-4 px-6 forensics-box">
                  <ul>
                    <li>
                      <h4>Name:</h4> {forensicsCompany.name}
                    </li>
                    <li>
                      <h4>Industry:</h4> {forensicsCompany.industry}
                    </li>
                    <li>
                      <h4>Location:</h4>{" "}
                      {forensicsCompany.town && forensicsCompany.town + ","}{" "}
                      {forensicsCompany.country}
                    </li>
                    <li>
                      <h4>Website:</h4>{" "}
                      <a
                        href={`https://${forensicsCompany.website}`}
                        target="_blank"
                      >
                        {forensicsCompany.website}
                      </a>
                    </li>
                    <li>
                      <h4>Last Visited:</h4> {formatDate(forensicsCompany.date)}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </BaseCard>
    </div>
  );
};

export default ArticleDetails;
