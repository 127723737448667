import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting";

exporting(Highcharts);

const StockChart = ({ options, title, zoom, chartType }) => {
  options.credits = "enabled: false";

  const chartRef = useRef();

  const { companyData } = useSelector((state) => state.company);

  useEffect(() => {
    updateSummaryChartImage();
  }, [companyData]);

  function highchartsSVGtoImage(chart, callback) {
    var svg = chart.getSVG({
      rangeSelector: {
        enabled: false,
      },
    });

    var canvas = document.createElement("canvas");
    canvas.width = 1120;
    canvas.height = 400;
    var ctx = canvas.getContext("2d");

    var img = document.createElement("img");

    img.onload = function () {
      ctx.drawImage(img, 0, 0);
      callback(canvas.toDataURL("image/png"));
    };

    img.setAttribute(
      "src",
      "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svg)))
    );
  }

  const updateSummaryChartImage = () => {
    // const chartData =
    //   sessionStorage.getItem(chartType + companyData.id) &&
    //   JSON.parse(sessionStorage.getItem(chartType + companyData.id));

    // if (chartData) {
    //   return;
    // }

    if (chartType && chartRef.current.chart) {
      highchartsSVGtoImage(chartRef.current.chart, (img) => {
        sessionStorage.setItem(chartType + companyData.id, JSON.stringify(img));
      });
    }
  };

  Highcharts.setOptions({
    lang: {
      rangeSelectorZoom: title ? title : "",
    },
  });

  const defaultOptions = {
    exporting: {
      sourceWidth: 1120,
      sourceHeight: 400,
      // scale: 2 (default)
      chartOptions: {
        subtitle: null,
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    tooltip: {
      backgroundColor: "#FFFFFF",
      borderColor: "rgba(51,51,51,0.1)",
      borderRadius: 10,
      padding: 12,
      useHTML: true,
      hideDelay: 1000,
      stickOnContact: true,
      style: {
        pointerEvents: "auto",
      },
    },
    rangeSelector: {
      selected: zoom ? zoom : 0,
      height: 50,
      dropdown: false,
      buttonSpacing: 8,
      buttonTheme: {
        width: 35,
        fill: "#D5DADD",
        stroke: "none",
        "stroke-width": 0,
        r: 10,
        style: {
          color: "#777777",
          fontWeight: "bold",
          fontFamily: "Ubuntu",
          fontSize: 14,
        },
        states: {
          hover: {},
          select: {
            fill: "white",
            stroke: "#D5DADD",
            "stroke-width": 1,
            style: {
              color: "#D5DADD",
            },
          },
          // disabled: { ... }
        },
      },
      inputBoxWidth: 120,
      inputBoxHeight: 18,
      inputStyle: {
        color: "#4C3C61",
        fontWeight: "400",
        textTransform: "uppercase",
        fontSize: 19,
        fontFamily: "Ubuntu",
      },
      labelStyle: {
        color: "#4C3C61",
        fontWeight: "600",
        fontSize: 20,
        fontFamily: "Ubuntu",
      },
    },
  };

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={Object.assign(options, defaultOptions)}
        ref={chartRef}
      />
    </>
  );
};

export default StockChart;
