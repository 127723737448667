import PropTypes from "prop-types";

const BaseCard = ({ id = null, children, className, ...props }) => {
  return (
    <div
      id={id}
      className={`card md:-mr-px px-5 py-4 w-full max-w-9xl mx-auto bg-white mb-8 ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

BaseCard.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  className: PropTypes.string,
};

export default BaseCard;
