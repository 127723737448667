import { useEffect } from "react";

import { formatThousands, toCamel } from "../../utils/Utils";
import Tippy from "../../lib/Tipy";
import BaseCard from "../../components/base/BaseCard";
import BaseTable from "../../components/base/BaseTable";
import LineChart from "../../components/charts/LineChart";
import DoughnutChart from "../../components/charts/DoughnutChart";
import BarChart from "../../components/charts/BarChart";

const GoogleDashboardDetails = ({ data }) => {
  const { chart_data: chartData, summary, user_stats: userStats } = data;

  useEffect(() => {
    Tippy("[data-tippy-content]");
  }, []);

  const flags = {
    [process.env.REACT_APP_GA4_UK]: {
      url: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg",
    },
    [process.env.REACT_APP_GA4_US]: {
      url: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg",
    },
    [process.env.REACT_APP_GA4_CA]: {
      url: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg",
    },
    [process.env.REACT_APP_GA4_AU]: {
      url: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg",
    },
  };

  const seriesDataLineChart = {
    total_users: [],
    views: [],
    impressions: [],
  };

  chartData?.forEach((data) => {
    seriesDataLineChart.total_users.push([
      new Date(data.date).getTime(),
      parseInt(data.total_users),
    ]);
    seriesDataLineChart.views.push([
      new Date(data.date).getTime(),
      parseInt(data.views),
    ]);
    seriesDataLineChart.impressions.push([
      new Date(data.date).getTime(),
      parseInt(data.impressions),
    ]);
  });

  const trafficChartOptions = {
    plotOptions: {
      line: {
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        threshold: null,
      },
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    legend: {
      y: 30,
      title: {
        text: '<span style="font-size: 13px; color: #666; font-weight: normal;">Click labels below to<br>toggle chart metrics</span><br>&nbsp;<br>',
      },
    },

    series: [
      {
        name: "Views",
        data: seriesDataLineChart.views,
        type: "line",
        color: "#015366",
      },
      {
        name: "Impressions",
        data: seriesDataLineChart.impressions,
        type: "line",
        color: "#43b5a0",
      },
      {
        name: "Total Users",
        data: seriesDataLineChart.total_users,
        type: "line",
        color: "#FF6933",
      },
    ],
  };

  const seriesDataGenderBarChart = {
    gender: [],
    new_users: [],
    total_users: [],
  };

  userStats?.userGender?.forEach((data) => {
    seriesDataGenderBarChart.gender.push([toCamel(data.gender)]);
    seriesDataGenderBarChart.new_users.push([parseInt(data.new_users)]);
    seriesDataGenderBarChart.total_users.push([parseInt(data.total_users)]);
  });

  const genderChartOptions = {
    xAxis: {
      categories: seriesDataGenderBarChart.gender,
      crosshair: true,
      accessibility: {
        description: "Gender",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
    },
    series: [
      {
        name: "New Users",
        data: seriesDataGenderBarChart.new_users,
        color: "#369440",
      },
      {
        name: "Total Users",
        data: seriesDataGenderBarChart.total_users,
        color: "#A0BF4D",
      },
    ],
  };

  const seriesDataSectorBarChart = [];

  userStats?.sector?.forEach((data) => {
    seriesDataSectorBarChart.push([toCamel(data.sector), parseInt(data.views)]);
  });

  const sectorChartOptions = {
    plotOptions: {
      series: {
        minPointLength: 3,
      },
    },
    xAxis: {
      type: "category",
      crosshair: true,
      accessibility: {
        description: "Sectors",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Views by sector",
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: "Sectors",
        data: seriesDataSectorBarChart?.sort((a, b) => b[1] - a[1]),
        colors: [
          "#003f5c",
          "#2f4b7c",
          "#665191",
          "#a05195",
          "#d45087",
          "#f95d6a",
          "#ff7c43",
          "#ffa600",
        ],
        colorByPoint: true,
      },
    ],
  };

  const userAgeTable = [
    { name: "age", header: "Age", defaultFlex: 2 },
    {
      name: "new_users",
      header: "New Users",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return formatThousands(data.new_users);
      },
    },
    {
      name: "total_users",
      header: "Total Users",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return formatThousands(data.total_users);
      },
    },
  ];

  const userCityTable = [
    { name: "city", header: "City", defaultFlex: 2 },
    {
      name: "new_users",
      header: "New Users",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return formatThousands(data.new_users);
      },
    },
    {
      name: "total_users",
      header: "Total Users",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return formatThousands(data.total_users);
      },
    },
  ];

  const userSourceTable = [
    { name: "source", header: "Source", defaultFlex: 2 },
    {
      name: "total_users",
      header: "Total Users",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return formatThousands(data.total_users);
      },
    },
  ];

  const userBrowserTable = [
    { name: "browser", header: "Browser", defaultFlex: 2 },
    {
      name: "new_users",
      header: "New Users",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return formatThousands(data.new_users);
      },
    },
    {
      name: "total_users",
      header: "Total Users",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return formatThousands(data.total_users);
      },
    },
  ];

  const querydefaultSortInfo = { name: "clicks", dir: -1 };
  const queryTable = [
    { name: "query", header: "Keyword", defaultFlex: 2 },
    {
      name: "clicks",
      header: "Clicks",
      type: "number",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return formatThousands(data.clicks);
      },
    },
    {
      name: "impressions",
      header: "Impressions",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return formatThousands(data.impressions);
      },
    },
    {
      name: "ctr",
      header: "CTR",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return Number(data.ctr).toFixed(4);
      },
    },
    {
      name: "position",
      header: "Position",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return Number(data.position).toFixed(4);
      },
    },
    ...(userStats?.query[0].property_id
      ? [
          {
            name: "property_id",
            header: "Region",
            defaultFlex: 1.7,
            render: ({ data }) => {
              return flags[data.property_id] ? (
                <img
                  width="30"
                  style={{ marginLeft: 8 }}
                  src={flags[data.property_id].url}
                />
              ) : (
                data.property_id
              );
            },
          },
        ]
      : []),
  ];

  const pagedefaultSortInfo = { name: "clicks", dir: -1 };
  const pageTable = [
    {
      name: "url",
      header: "URL",
      defaultFlex: 2,
      render: ({ data }) => {
        return (
          <a className="text-primary" href={data.url} target="_blank">
            {data.url}
          </a>
        );
      },
    },
    {
      name: "clicks",
      header: "Clicks",
      type: "number",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return formatThousands(data.clicks);
      },
    },
    {
      name: "impressions",
      header: "Impressions",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return formatThousands(data.impressions);
      },
    },
    {
      name: "ctr",
      header: "CTR",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return Number(data.ctr).toFixed(4);
      },
    },
    {
      name: "position",
      header: "Position",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return Number(data.position).toFixed(4);
      },
    },
  ];

  var deviceColors = [
    "#12495D",
    "#FFA100",
    "#FF7338",
    "#FF5167",
    "#E44289",
    "#B9419D",
    "#6B4F96",
  ];
  const deviceChartOptions = {
    series: [
      {
        name: "Sessions By Device",
        data:
          "deviceCategory" in userStats
            ? userStats.deviceCategory.map((item, index) => ({
                name: toCamel(item.deviceCategory),
                y: parseFloat(item.views),
                color: deviceColors[index],
              }))
            : [],
      },
    ],
  };

  // var sectorColors = [
  //   "#FFA100",
  //   "#FF7338",
  //   "#FF5167",
  //   "#E44289",
  //   "#228FD7",
  //   "#727795",
  //   "#363438",
  // ];
  // const sectorsChartData = {
  //   series: [
  //     {
  //       name: "Sectors",
  //       data:
  //         "sector" in userStats
  //           ? userStats.sector.map((item, index) => ({
  //               name: toCamel(item.sector),
  //               y: parseFloat(item.views),
  //               color: sectorColors[index],
  //             }))
  //           : [],
  //     },
  //   ],
  // };

  const interestTable = [
    { name: "interest", header: "Interests", defaultFlex: 5 },
    {
      name: "views",
      header: "Views",
      defaultFlex: 1,
      render: ({ data }) => {
        return formatThousands(data.views);
      },
    },
  ];

  const popularTable = [
    {
      name: "url",
      header: "URL",
      defaultFlex: 5,
      render: ({ data }) => {
        return (
          <a
            title={data.title}
            className="text-primary"
            href={`https://${data.url}`}
            target="_blank"
          >
            {data.url}
          </a>
        );
      },
    },
    {
      name: "views",
      header: "Views",
      defaultFlex: 1,
      render: ({ data }) => {
        return formatThousands(data.views);
      },
    },
  ];

  return (
    <>
      <BaseCard>
        <div className="px-5 py-1 mb-8">
          <div className="flex flex-wrap">
            <div className="flex items-center py-2">
              <div
                className="mr-5"
                data-tippy-content="The number of page views for our site(s) (GA4)"
              >
                <div className="flex items-center">
                  <div className="text-3xl font-bold lg:text-[1.7rem] text-red mr-2">
                    {formatThousands(summary.total_views)}
                  </div>
                </div>
                <div className="text-sm text-slate-500">Total Views</div>
              </div>
              <div
                className="hidden md:block w-px h-8 bg-slate-200 mr-5"
                aria-hidden="true"
              ></div>
            </div>

            <div className="flex items-center py-2">
              <div
                className="mr-5"
                data-tippy-content="The number of people who visited our site(s) (GA4)"
              >
                <div className="flex items-center">
                  <div className="text-3xl font-bold lg:text-[1.7rem] text-red mr-2">
                    {formatThousands(summary.total_users)}
                  </div>
                </div>
                <div className="text-sm text-slate-500">Total Users</div>
              </div>
              <div
                className="hidden md:block w-px h-8 bg-slate-200 mr-5"
                aria-hidden="true"
              ></div>
            </div>

            <div className="flex items-center py-2">
              <div
                className="mr-5"
                data-tippy-content="The number of users visiting our site(s) for the first time (GA4)"
              >
                <div className="flex items-center">
                  <div className="text-3xl font-bold lg:text-[1.7rem] text-red mr-2">
                    {formatThousands(summary.total_new_users)}
                  </div>
                </div>
                <div className="text-sm text-slate-500">New Users</div>
              </div>
              <div
                className="hidden md:block w-px h-8 bg-slate-200 mr-5"
                aria-hidden="true"
              ></div>
            </div>

            <div className="flex items-center py-2">
              <div
                className="mr-5"
                data-tippy-content="A session begins when the user opens our site in the foreground, or views a page. It ends (times out) after 30 minutes of user inactivity (GA4)"
              >
                <div className="flex items-center">
                  <div className="text-3xl font-bold lg:text-[1.7rem] text-red mr-2">
                    {formatThousands(summary.total_sessions)}
                  </div>
                </div>
                <div className="text-sm text-slate-500">Total Sessions</div>
              </div>
              <div
                className="hidden md:block w-px h-8 bg-slate-200 mr-5"
                aria-hidden="true"
              ></div>
            </div>

            <div className="flex items-center py-2">
              <div
                className="mr-5"
                data-tippy-content="An averaged measure of 'engagement'. The higher the number, the more pages each user is reading on average. (GA4)"
              >
                <div className="flex items-center">
                  <div className="text-3xl font-bold lg:text-[1.7rem] text-red mr-2">
                    {Number(
                      summary.total_sessions / summary.total_users
                    ).toFixed(2)}
                  </div>
                </div>
                <div className="text-sm text-slate-500">Sessions per user</div>
              </div>
              <div
                className="hidden md:block w-px h-8 bg-slate-200 mr-5"
                aria-hidden="true"
              ></div>
            </div>

            <div className="flex items-center py-2">
              <div
                className="mr-5"
                data-tippy-content="Sourced from Google Search Console, the number of times any URL from our sites appeared in search results viewed by a user (not including paid Google Ads search impressions)"
              >
                <div className="flex items-center">
                  <div className="text-3xl font-bold lg:text-[1.7rem] text-red mr-2">
                    {formatThousands(summary.total_impressions)}
                  </div>
                </div>
                <div className="text-sm text-slate-500">Impressions</div>
              </div>
              <div
                className="hidden md:block w-px h-8 bg-slate-200 mr-5"
                aria-hidden="true"
              ></div>
            </div>

            <div className="flex items-center py-2">
              <div
                className="mr-5"
                data-tippy-content="Sourced from Google Search Console, the number of clicks on your website URLs from a Google Search results page (not including paid Google Ads search results)"
              >
                <div className="flex items-center">
                  <div className="text-3xl font-bold lg:text-[1.7rem] text-red mr-2">
                    {formatThousands(summary.total_clicks)}
                  </div>
                </div>
                <div className="text-sm text-slate-500">URL Clicks</div>
              </div>
              <div
                className="hidden md:block w-px h-8 bg-slate-200 mr-5"
                aria-hidden="true"
              ></div>
            </div>

            <div className="flex items-center">
              <div data-tippy-content="Sourced from Google Search Console, the average Click Through Rate from a user seeing our URL in search results and then clicking on the URL (3% > 30% is considered normal range for #1 spot)">
                <div className="flex items-center">
                  <div className="text-3xl font-bold lg:text-[1.7rem] text-red mr-2">
                    {`${Number(summary.avg_ctr * 100).toFixed(2)}%`}
                  </div>
                </div>
                <div className="text-sm text-slate-500">Average CTR</div>
              </div>
            </div>
          </div>
        </div>

        {/* * <div className="flex items-center">
              <div>
                <div className="flex items-center">
                  <div className="text-3xl font-bold lg:text-[1.8rem] text-red mr-2">
                    {formatThousands(summary.total_client_views)}
                  </div>
                </div>
                <div className="text-sm text-slate-500">Total Client Views</div>
              </div>
            </div>
          </div>
        </div> */}

        {seriesDataLineChart?.views.length > 1 ? (
          <LineChart options={trafficChartOptions} />
        ) : (
          <div className="w-full h-[260px] flex flex-col items-center justify-center text-sm">
            Not enough data
          </div>
        )}
      </BaseCard>

      <div className="grid gap-5 md:grid-cols-2">
        <BaseCard>
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="Where the user found a link to our site; where they were before they visited us (GA4)"
          >
            Source
          </h4>

          <BaseTable
            idProperty="user-cities"
            dataSource={userStats.firstUserSource}
            columns={userSourceTable}
            style={{ minHeight: 283 }}
            className="mt-6 no-border"
            scrollHide={false}
          />
        </BaseCard>
        <BaseCard className="flex flex-col">
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="Each news article is associated with a primary Sector; we track views to each article and total up the hits each primary sector receives in the selected time period"
          >
            Sectors
          </h4>
          {seriesDataSectorBarChart.length > 1 ? (
            <BarChart
              options={sectorChartOptions}
              className="!grow-0 mt-auto"
            />
          ) : (
            <div className="w-full h-[340px] flex flex-col items-center justify-center text-sm">
              Not enough data
            </div>
          )}
        </BaseCard>
      </div>

      <div className="grid gap-5 md:grid-cols-2">
        <BaseCard>
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="The devices used to visit our site(s), where known (GA4)"
          >
            Devices
          </h4>
          {userStats?.deviceCategory.length > 1 ? (
            <DoughnutChart
              options={deviceChartOptions}
              className="max-w-[420px]"
            />
          ) : (
            <div className="w-full h-full flex flex-col justify-start items-left">
              <div
                className="w-full h-full flex items-center justify-center font-bold"
                style={{
                  borderRadius: "100%",
                  border: "35px solid #f1f5f9",
                  width: "230px",
                  height: "230px",
                  marginTop: "1rem",
                  marginLeft: "1rem",
                }}
              >
                Not enough data
              </div>
            </div>
          )}
        </BaseCard>

        <BaseCard>
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="The browsers used to visit our site(s), where known (GA4)"
          >
            Browser
          </h4>
          <BaseTable
            idProperty="user-cities"
            dataSource={userStats.browser}
            columns={userBrowserTable}
            style={{ minHeight: 283 }}
            className="mt-6 no-border"
            scrollHide={false}
          />
        </BaseCard>
      </div>

      <div className="grid gap-5 md:grid-cols-3">
        <BaseCard>
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="The user's location when visiting our site(s), where known (GA4)"
          >
            Location
          </h4>
          <BaseTable
            idProperty="user-cities"
            dataSource={userStats.city}
            columns={userCityTable}
            style={{ minHeight: 283 }}
            className="mt-6 no-border"
            scrollHide={false}
          />
        </BaseCard>

        <BaseCard>
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="The user's age range split across New Users and Total Users (new plus returning), where known (GA4)"
          >
            Age
          </h4>
          <BaseTable
            idProperty="user-age"
            dataSource={userStats.userAgeBracket}
            columns={userAgeTable}
            style={{ minHeight: 283 }}
            className="mt-6 no-border"
            scrollHide={false}
          />
        </BaseCard>

        <BaseCard className="flex flex-col">
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            tippy-data-content="The user's declared gender, where known (GA4)"
          >
            Gender
          </h4>
          {seriesDataGenderBarChart.gender.length > 1 ? (
            <BarChart
              options={genderChartOptions}
              className="!grow-0 mt-auto"
            />
          ) : (
            <div className="w-full h-[340px] flex flex-col items-center justify-center text-sm">
              Not enough data
            </div>
          )}
        </BaseCard>
      </div>
      <div className="grid gap-5 md:grid-cols-2">
        <BaseCard>
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="The top 10 pages on our site(s) in the given time period displayed as URLs (GA4)"
          >
            Popular Pages
          </h4>
          <BaseTable
            idProperty="user-cities"
            dataSource={userStats.popularContent}
            columns={popularTable}
            style={{ minHeight: 283 }}
            className="mt-6 no-border"
            scrollHide={false}
          />
        </BaseCard>
        <BaseCard>
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="The top 10 interests our users have according to insights gathered by GA4, and the number of views associated with each interest (helps us understand our user demographic)"
          >
            Interests
          </h4>
          <BaseTable
            idProperty="user-cities"
            dataSource={userStats.interest}
            columns={interestTable}
            style={{ minHeight: 283 }}
            className="mt-6 no-border"
            scrollHide={false}
          />
        </BaseCard>
      </div>

      <div className="grid gap-5 md:grid-cols-2 mb-12">
        <BaseCard>
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="Sourced from Google Search Console, the top 10 queries users are searching for, before clicking a result and visiting our site(s)"
          >
            Searched Keywords
          </h4>
          <BaseTable
            idProperty="user-cities"
            dataSource={userStats.query}
            columns={queryTable}
            style={{ minHeight: 283 }}
            className="mt-6 no-border"
            scrollHide={false}
            defaultSortInfo={querydefaultSortInfo}
          />
        </BaseCard>
        <BaseCard>
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="Sourced from Google Search Console, the top 10 pages returned by Google after the user submits a query (Search Engine Results Pages)"
          >
            Google Results Pages (SERPs)
          </h4>
          <BaseTable
            idProperty="user-cities"
            dataSource={userStats.page}
            columns={pageTable}
            style={{ minHeight: 283 }}
            className="mt-6 no-border"
            scrollHide={false}
            defaultSortInfo={pagedefaultSortInfo}
          />
        </BaseCard>
      </div>
    </>
  );
};

export default GoogleDashboardDetails;
