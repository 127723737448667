import { useEffect, useState } from "react";

import axios from "../../api/AxiosHttp";

import BaseTitle from "../../components/base/BaseTitle";
import BaseButton from "../../components/base/BaseButton";
import { formatDate, formatTime } from "../../utils/Utils";
import LiveDashboardDetails from "../../partials/internal-analytics/LiveDashboardDetails";
import NewsLookupDashboard from "./NewsLookupDashboard";

const LiveDashboard = () => {
  const [liveData, setLiveData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchLiveData();
    const intervalID = setInterval(fetchLiveData, 300000);
    return () => clearInterval(intervalID);
  }, []);

  const fetchLiveData = async () => {
    setLoading(true);

    try {
      const endPoint = "/api/internal_live_data";
      const { data } = await axios.get(endPoint);

      setLiveData(data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <NewsLookupDashboard blurb={true} />

      <BaseTitle
        heading="Activity"
        rightSideContent={
          <BaseButton
            type="button"
            label="Refresh Manually"
            onClick={fetchLiveData}
            loading={loading}
          />
        }
      />

      <p className="mb-12">
        <div>
          <h5 className="font-bold text-violet text-lg inline">
            Last updated:{" "}
          </h5>
          <span className="inline">
            {!loading ? (
              formatDate(liveData?.updated_at, { month: "short" }) +
              " " +
              formatTime(liveData?.updated_at) +
              " Europe/London"
            ) : (
              <span
                className="inline-block animate-pulse w-24 h-4 inline-block ml-2"
                style={{ background: "rgba(51, 51, 51, 0.1)" }}
              ></span>
            )}
          </span>
        </div>

        <span className="block text-sm">
          (updates every 5 minutes, data is cached for 1 minute)
        </span>
      </p>

      <LiveDashboardDetails data={liveData} />
    </>
  );
};

export default LiveDashboard;
