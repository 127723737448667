import { Link } from "react-router-dom";

import PropTypes from "prop-types";

const BaseTitle = ({
  type = "h1",
  heading,
  subtitle,
  className,
  linkTo,
  rightSideContent,
}) => {
  const Tag = type;
  const size = {
    h1: "text-2xl md:text-[2rem]",
    h2: "text-xl md:text-[1.8rem]",
    h3: "text-lg md:text-[1.6rem]",
  };
  return (
    <div className="mb-4 sm:mb-8 flex justify-between items-center">
      <Tag
        className={`text-primary font-bold uppercase flex items-center ${
          (className, size[type])
        }`}
      >
        {heading}
        {subtitle && (
          <>
            <span className="h-6 w-px inline-block bg-[#DADADA] mr-4 ml-4"></span>
            <span className="normal-case text-violet font-medium">
              {subtitle}
            </span>
          </>
        )}
        {linkTo && (
          <Link to={linkTo}>
            <svg
              className="ml-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="#4c3c61"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />
              <line x1="10" y1="14" x2="20" y2="4" />
              <polyline points="15 4 20 4 20 9" />
            </svg>
          </Link>
        )}
      </Tag>
      {rightSideContent}
    </div>
  );
};

BaseTitle.propTypes = {
  heading: PropTypes.string.isRequired,
};

export default BaseTitle;
