import { createSlice } from "@reduxjs/toolkit";

export const googleSlice = createSlice({
  name: "google",
  initialState: {
    gaProperty: {label: process.env.REACT_APP_GA4_GLOBAL_LABEL, value: parseInt(process.env.REACT_APP_GA4_GLOBAL)},
    gaPropertyRange: parseInt(process.env.REACT_APP_GA4_RANGE_DEFAULT),
    gaData: {},
  },
  reducers: {
    updateGaProperty: (state, action) => {
      state.gaProperty = action.payload;
    },
    updateGaPropertyRange: (state, action) => {
      state.gaPropertyRange = action.payload;
    },
    updateGaData: (state, action) => {
      state.gaData = action.payload;
    },
  },
});

export const { updateGaProperty, updateGaPropertyRange, updateGaData } = googleSlice.actions;

export default googleSlice.reducer;