import { Link } from "react-router-dom";

import PageNotFoundImage from '../../images/404-illustration.svg';

const PageNotFound = () => {
  return (
    <div className="max-w-2xl m-auto mt-16">
      <div className="text-center px-4">
        <div className="inline-flex mb-8">
          <img
            src={PageNotFoundImage}
            width="176"
            height="176"
            alt="404 illustration"
          />
        </div>
        <div className="mb-6">
          Hmm...this page doesn’t exist. Try searching for something else!
        </div>
        <Link
          to="/"
          className="btn bg-primary hover:bg-primary-dark text-white"
        >
          Back To Dashboard
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
