import { createSlice } from "@reduxjs/toolkit";

export const introSlice = createSlice({
  name: "intro",
  initialState: {
    introData: {},
  },
  reducers: {
    updateIntroData: (state, action) => {
      state.introData = action.payload;
    },
  },
});

export const { updateIntroData } = introSlice.actions;

export default introSlice.reducer;