import PropTypes from "prop-types";

import { toCamel } from "../utils/Utils";

const DetailedStats = ({ label, value, ...props }) => {
  if (value === 0) {
    var greyOut = true;
    value = "N/A";
  } else {
    value = value.toLocaleString();
  }

  return (
    <div className="text-center mr-[40px] w-[130px] last:mr-0" {...props}>
      <div className="flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="109"
          height="123"
          viewBox="0 0 109 123"
        >
          <path
            id="timeline_label_bg"
            data-name="timeline label bg"
            d="M54.379,0a5.2,5.2,0,0,0-2.152.525L2.266,29.055A5.01,5.01,0,0,0,0,32.975V90.028a5.016,5.016,0,0,0,2.266,3.92l49.994,28.52a5.1,5.1,0,0,0,4.535,0l49.936-28.523A4.994,4.994,0,0,0,109,90.028V32.975a5,5,0,0,0-2.269-3.92L56.763.525A5.2,5.2,0,0,0,54.611,0Z"
            fill="rgba(76,60,97,0.03)"
          />
        </svg>
        <h6
          className={`absolute text-3xl font-bold lg:text-[1.8rem] ${
            greyOut ? "text-gray-300" : "text-red"
          }`}
        >
          {value}
        </h6>
      </div>
      <p
        className={`text-sm font-regular tracking-widest lg:text-lg !leading-[1.2] ${
          greyOut ? "text-gray-300" : "text-secondary"
        }`}
      >
        {toCamel(label)}
      </p>
    </div>
  );
};

DetailedStats.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  border: PropTypes.bool,
};

export default DetailedStats;
