import { createSlice } from "@reduxjs/toolkit";

export const articleSlice = createSlice({
  name: "article",
  initialState: {
    articleData: {},
  },
  reducers: {
    updateArticleData: (state, action) => {
      state.articleData = action.payload;
    },
  },
});

export const { updateArticleData } = articleSlice.actions;

export default articleSlice.reducer;