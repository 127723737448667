import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";

import PropTypes from "prop-types";

import axios from "../../api/AxiosHttp";
import BaseCard from "../../components/base/BaseCard";
import BaseStats from "../../components/base/BaseStats";
import BaseTable from "../../components/base/BaseTable";
import BaseTitle from "../../components/base/BaseTitle";
import DoughnutChart from "../../components/charts/DoughnutChart";
import Tippy from "../../lib/Tipy";
import ArticlesSummaryChart from "../../partials/analytics/articles/ArticlesSummaryChart";
import ArticlesTable from "../../partials/analytics/articles/ArticlesTable";
import { updateArticleData } from "../../redux/articleSlice";
import { toCamel, formatDate } from "../../utils/Utils";

// Set partial prop true to only show summary section
const Articles = ({ partial = false }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { companyData } = useSelector((state) => state.company);
  const { articleData } = useSelector((state) => state.article);
  const { userData } = useSelector((state) => state.auth);

  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    Tippy("[data-tippy-content]");
    getSummary();
  }, [companyData]);

  const logActivity = async () => {
    if (
      !partial &&
      userData.role === process.env.REACT_APP_CLIENT &&
      !sessionStorage.getItem("view_articles_" + companyData.company_id)
    ) {
      const endPoint = `/api/users/${userData.id}/activity`;
      axios.post(endPoint, {
        model_id: companyData.company_id,
        action: "view_articles",
        type: "company",
      });
      sessionStorage.setItem("view_articles_" + companyData.company_id, true);
    }
  };

  // Function to get article data from API and update redux store if older than 5 minutes
  const getSummary = async () => {
    logActivity();

    if (
      articleData &&
      articleData.active_company_id &&
      articleData.active_company_id == id &&
      Date.now() - articleData.timestamp < 300000
    ) {
      return;
    }

    setLoadingPage(true);
    dispatch(updateArticleData({}));

    try {
      const endPoint = `/api/companies/active/${id}/news`;
      const { data } = await axios.get(endPoint);

      dispatch(updateArticleData({ ...data, timestamp: Date.now() }));
      setLoadingPage(false);
    } catch (error) {
      dispatch(updateArticleData({ status: 0 }));
      setLoadingPage(false);
    }
  };

  const countriesTable = [
    { name: "key", header: "Country", defaultFlex: 1.1 },
    {
      name: "value",
      header: "%",
      defaultFlex: 0.3,
      render: ({ data }) => {
        return `${data.value}%`;
      },
    },
  ];

  const referralTable = [
    { name: "key", header: "Referral Source", defaultFlex: 1.1 },
    {
      name: "value",
      header: "%",
      defaultFlex: 0.3,
      render: ({ data }) => {
        return `${data.value}%`;
      },
    },
  ];

  const latestVisitors = [
    {
      name: "name",
      header: "Companies",
      defaultFlex: 1.1,
    },
  ];

  var newReturnChartDataColors = ["#FFA100", "#FF7338", "#FF5167", "#E44289"];
  const newReturnChartData = {
    series: [
      {
        name: "Sessions By Device",
        data:
          "new_vs_returning" in articleData
            ? articleData.new_vs_returning.map((item, index) => ({
                name: toCamel(item.key),
                y: parseFloat(item.value),
                color: newReturnChartDataColors[index],
              }))
            : [],
      },
    ],
  };

  var userDeviceChartDataColors = ["#369440", "#A0BF4D", "#D9DD3F"];
  const userDeviceChartData = {
    series: [
      {
        name: "Sessions By Device",
        data:
          "device_breakdown" in articleData
            ? articleData.device_breakdown.map((item, index) => ({
                name: toCamel(item.key).replace("Views", ""),
                y: parseFloat(item.value),
                color: userDeviceChartDataColors[index],
              }))
            : [],
      },
    ],
  };

  return (
    <>
      <BaseTitle
        type={!partial ? "h1" : "h2"}
        heading="Articles"
        subtitle={!partial && companyData.name}
        linkTo={partial && `/analytics/${id}/news`}
      />

      {!partial && (
        <BaseCard>
          <p className="mb-3">
            Proactive websites use Google Analytics 4 (GA4) to help us better
            understand our audiences. GA4 tracking is always enabled. Articles
            appear on our regionally focused websites in the{" "}
            <a
              href="https://www.proactiveinvestors.co.uk/"
              rel="noreferrer"
              target="blank"
              className="font-bold"
            >
              United Kingdom
            </a>
            ,{" "}
            <a
              href="https://www.proactiveinvestors.com/"
              rel="noreferrer"
              target="blank"
              className="font-bold"
            >
              United States
            </a>
            ,{" "}
            <a
              href="https://www.proactiveinvestors.ca/"
              rel="noreferrer"
              target="blank"
              className="font-bold"
            >
              Canada
            </a>{" "}
            and{" "}
            <a
              href="https://www.proactiveinvestors.com.au/"
              rel="noreferrer"
              target="blank"
              className="font-bold"
            >
              Australia
            </a>{" "}
            and we collate metrics from each.
          </p>
          <p>
            This platform is updated daily and there may be a slight delay
            between a piece of content receiving views and those views
            displaying here. You can hover over numbers and titles for a tooltip
            popup containing more detail. If you have a question or would like
            to share your feedback, please{" "}
            <Link className="font-bold" to={`/analytics/${id}/contact`}>
              get in touch.
            </Link>
          </p>
        </BaseCard>
      )}

      {!loadingPage ? (
        <>
          {articleData &&
          Object.keys(articleData).length > 0 &&
          articleData.status !== 0 ? (
            <>
              <div className="grid grid-cols-12 xl:gap-8">
                <ArticlesSummaryChart partial={partial} data={articleData} />

                <div className="col-span-12 xl:col-span-2 xl:mb-8">
                  <div className="grid gap-x-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-1">
                    <BaseStats
                      className="w-full"
                      name="Views and Impressions"
                      number={
                        articleData.total_views + articleData.total_impressions
                      }
                      data-tippy-content="A combination of page views data sourced from Google Analytics 4 and impressions data sourced from Google Search Console."
                    />
                    <BaseStats
                      name="Page Impressions"
                      subtext={
                        "(As of " +
                        formatDate(
                          new Date().setDate(new Date().getDate() - 2)
                        ) +
                        ")"
                      }
                      number={articleData.total_impressions}
                      data-tippy-content="Sourced from Google Search Console and updated daily, the number of times your article content has appeared in search results."
                    />
                    <BaseStats
                      className="xl:mb-0"
                      name="Page Views"
                      number={articleData.total_views}
                      data-tippy-content="Sourced from Google Analytics 4 and updated daily, the total number of views your articles have received on Proactive’s platform."
                    />
                  </div>
                </div>
              </div>
              {partial && (
                <>
                  <div className="grid grid-cols-12 gpa-0 md:gap-8 xl:mt-8">
                    <div className="col-span-12 md:col-span-6">
                      <BaseCard>
                        <h4
                          className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                          data-tippy-content="Sourced from Google Analytics 4 and updated weekly, the top 5 visitor countries from all of your articles displayed as average totals. (Does not include mentions)"
                        >
                          Top 5 Visitor Countries
                        </h4>
                        <BaseTable
                          idProperty="referral-sources"
                          dataSource={
                            articleData.top_countries
                              ? articleData.top_countries
                              : []
                          }
                          columns={countriesTable}
                          style={{ minHeight: 250 }}
                          className="mt-6 no-border"
                          scrollHide={false}
                        />
                      </BaseCard>
                    </div>

                    <div className="col-span-12 md:col-span-6">
                      <BaseCard>
                        <h4
                          className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                          data-tippy-content="Sourced from Google Analytics 4 and updated weekly, the external traffic sources from all of your articles displayed as averaged totals. "
                        >
                          Top 5 External Referral
                        </h4>
                        <BaseTable
                          idProperty="referral-sources"
                          dataSource={
                            articleData.top_referrers
                              ? articleData.top_referrers
                              : []
                          }
                          columns={referralTable}
                          style={{ minHeight: 250 }}
                          className="mt-6 no-border"
                          scrollHide={false}
                        />
                      </BaseCard>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-0 gap-x-8 xl:gap-8 mb-4">
                    <BaseCard>
                      <h4
                        className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                        data-tippy-content="Sourced from Google Analytics 4 and updated weekly, an averaged total of visitors who are new to our site compared to those who have visited previously."
                      >
                        New vs. Returning
                      </h4>
                      {"new_vs_returning" in articleData ? (
                        <DoughnutChart
                          options={newReturnChartData}
                          chartType="articleNewReturnDoughnut"
                        />
                      ) : (
                        <div className="w-full h-full flex flex-col justify-start items-left">
                          <div
                            className="w-full h-full flex items-center justify-center font-bold"
                            style={{
                              borderRadius: "100%",
                              border: "35px solid #f1f5f9",
                              width: "230px",
                              height: "230px",
                              marginTop: "1rem",
                            }}
                          >
                            Not enough data
                          </div>
                        </div>
                      )}
                    </BaseCard>
                    <BaseCard>
                      <h4
                        className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                        data-tippy-content="Sourced from Google Analytics 4 and updated weekly, this is an averaged total of the devices your visitors are using on our sites."
                      >
                        User Devices
                      </h4>

                      {"device_breakdown" in articleData ? (
                        <DoughnutChart
                          options={userDeviceChartData}
                          chartType="articleDeviceDoughnut"
                        />
                      ) : (
                        <div className="w-full h-full flex flex-col justify-start items-left">
                          <div
                            className="w-full h-full flex items-center justify-center font-bold"
                            style={{
                              borderRadius: "100%",
                              border: "35px solid #f1f5f9",
                              width: "230px",
                              height: "230px",
                              marginTop: "1rem",
                            }}
                          >
                            Not enough data
                          </div>
                        </div>
                      )}
                    </BaseCard>
                    <BaseCard>
                      <h4
                        className="text-xl mb-4 uppercase text-violet font-bold flex w-fit"
                        data-tippy-content="Sourced from our Corporate Visitors tracking platform, the latest 10 identified businesses to have visited your articles on our sites."
                      >
                        Corporate Visitors
                        <Link to={`/analytics/${id}/forensics`}>
                          <svg
                            className="ml-2"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="#4c3c61"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />
                            <line x1="10" y1="14" x2="20" y2="4" />
                            <polyline points="15 4 20 4 20 9" />
                          </svg>
                        </Link>
                      </h4>
                      <BaseTable
                        idProperty="referral-sources"
                        dataSource={
                          articleData.latest_visitors
                            ? articleData.latest_visitors
                            : []
                        }
                        columns={latestVisitors}
                        style={{ minHeight: 250 }}
                        className="mt-6 no-border"
                        scrollHide={false}
                      />
                    </BaseCard>
                  </div>
                </>
              )}
            </>
          ) : (
            <BaseCard>There is no data for articles.</BaseCard>
          )}

          {!partial && articleData.items && (
            <ArticlesTable tableData={articleData.items} />
          )}
        </>
      ) : (
        <div className="flex flex-grow justify-center items-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#e94a3e"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

Articles.propTypes = {
  partial: PropTypes.bool,
};

export default Articles;
