import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import axios from "../../api/AxiosHttp";

import BaseTitle from "../../components/base/BaseTitle";
import BaseStats from "../../components/base/BaseStats";
import BaseTable from "../../components/base/BaseTable";
import BaseCard from "../../components/base/BaseCard";
import BaseButton from "../../components/base/BaseButton";

const UserActivity = () => {
  const { id } = useParams();

  const [userActivity, setUserActivity] = useState({});

  const [submitloading, setSubmitloading] = useState(false);

  // Call functions on first load
  useEffect(() => {
    getUserActivity();
  }, []);

  const getUserActivity = async () => {
    const endPoint = "/api/users/" + id + "/activity";
    const result = await axios.get(endPoint);

    setUserActivity(result.data);
  };

  const activityTable = [
    { name: "date", header: "Date", defaultFlex: 0.25 },
    {
      name: "action",
      header: "Action",
      defaultFlex: 1.1,
    },
  ];

  return (
    <>
      <BaseTitle
        heading={`User Activity for ${userActivity.user_name}`}
        rightSideContent={
          <Link to="/users">
            <BaseButton label="Users List" />
          </Link>
        }
      />

      <div className="grid grid-cols-12 gap-8">
        <BaseCard className="col-span-full lg:col-span-8 xl:col-span-10">
          <BaseTable
            idProperty="user-logs"
            dataSource={userActivity.items ? userActivity.items : []}
            columns={activityTable}
            style={{ minHeight: 740 }}
            className="no-border "
            scrollHide={false}
          />
        </BaseCard>

        <div className="col-span-12 xl:col-span-2">
          <div className="flex flex-wrap">
            <BaseStats
              name="Last Login"
              number={userActivity.last_login}
              numberFormatting={false}
            />
            <BaseStats
              name="Number of Logins"
              number={userActivity.no_logins}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserActivity;
