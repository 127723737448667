import { useEffect, useState } from "react";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";

import axios from "../../api/AxiosHttp";
import BaseTable from "../../components/base/BaseTable";
import BaseTitle from "../../components/base/BaseTitle";
import Noty from "../../lib/Noty";

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (companies.length == 0) {
      getCompanies();
    }
  }, [companies]);

  const getCompanies = async () => {
    const endPoint = "api/companies";

    try {
      const result = await axios.get(endPoint);
      setCompanies(result.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleAnalytics = async (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    const status = e.currentTarget.getAttribute("data-status");
    const name = e.currentTarget.getAttribute("data-name");
    const endPoint = "api/companies/active";

    let toggleNoty = new Noty({
      type: "info",
      text: `<b>${
        status == 1 ? "Disabling" : "Enabling"
      }</b> analytics for ${name}. <br>Please wait...`,
      timeout: false,
      modal: true,
      progressBar: true,
      closeWith: [],
    });
    toggleNoty.show();

    try {
      await axios.post(endPoint, { id, status });
      await getCompanies();

      toggleNoty.setType("success");
      toggleNoty.setText(
        `Analytics <b>${
          status == 1 ? "disabled" : "enabled"
        }</b> for ${name}. ${
          status == 0
            ? "<br>It may take up to 10 minutes before you see data on the dashboard."
            : ""
        }`
      );
      toggleNoty.setTimeout(4000);
    } catch (error) {
      toggleNoty.setType("error");
      toggleNoty.setText("Failed to activate company, please try again later.");
      toggleNoty.setTimeout(4000);
    }
  };

  const defaultSortInfo = { name: "name", dir: 1 };

  // ReactDataGrid filter for columns
  const filterValue = [
    { name: "name", operator: "startsWith", type: "string", value: "" },
    {
      name: "id",
      operator: "eq",
      type: "number",
      value: null,
      filterEditor: NumberFilter,
    },
    { name: "type", operator: "startsWith", type: "string", value: "" },
    { name: "sector_type", operator: "inlist", type: "select", value: "" },
  ];

  const sectors =
    companies && Object.keys(companies).length > 0
      ? companies.map((item) => item.sector_type)
      : [];

  const uniqueSectors = sectors
    .filter((item, index) => sectors.indexOf(item) === index)
    .sort();

  const columns = [
    { name: "name", header: "Name", defaultFlex: 1.1 },
    {
      name: "id",
      header: "Company ID",
      defaultFlex: 0.5,
      type: "number",
      filterEditor: NumberFilter,
    },
    { name: "type", header: "Company Type", defaultFlex: 0.5 },
    {
      name: "sector_type",
      header: "Sector",
      defaultFlex: 0.5,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        dataSource: uniqueSectors.map((c) => {
          return { id: c, label: c };
        }),
      },
    },
    { name: "expiry", header: "Expiry", defaultFlex: 0.35 },
    {
      name: "status",
      header: "Analytics",
      defaultFlex: 0.25,
      type: "number",
      render: ({ data }) => {
        return data.status == 1 ? (
          <button
            title="Toggle Analytics"
            data-id={data.id}
            data-status={data.status}
            data-name={data.name}
            onClick={toggleAnalytics}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-toggle-right"
              width="42"
              height="42"
              viewBox="0 0 24 24"
              strokeWidth="1"
              stroke="#00b341"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="16" cy="12" r="2" />
              <rect x="2" y="6" width="20" height="12" rx="6" />
            </svg>
          </button>
        ) : (
          <button
            title="Toggle Analytics"
            data-id={data.id}
            data-status={data.status}
            data-name={data.name}
            onClick={toggleAnalytics}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-toggle-left"
              width="42"
              height="42"
              viewBox="0 0 24 24"
              strokeWidth="1"
              stroke="#ff2825"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="8" cy="12" r="2" />
              <rect x="2" y="6" width="20" height="12" rx="6" />
            </svg>
          </button>
        );
      },
    },
  ];

  return (
    <>
      <BaseTitle heading="Companies" />

      <BaseTable
        className="mt-8"
        style={{ minHeight: 740 }}
        defaultSortInfo={defaultSortInfo}
        loading={loading}
        loadingText="Fetching companies from Genera"
        idProperty="companies-table"
        pagination={true}
        enableFiltering
        defaultFilterValue={filterValue}
        columns={columns}
        dataSource={companies}
      />
    </>
  );
};

export default Companies;
