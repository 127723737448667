import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";

import axios from "../../api/AxiosHttp";
import {
  updateYoutubeRange,
  updateYoutubeData,
} from "./../../redux/youtubeSlice";

import BaseTitle from "../../components/base/BaseTitle";
import BaseCard from "../../components/base/BaseCard";
import ButtonGroup from "../../components/ButtonGroup";
import YoutubeDashboardDetails from "../../partials/internal-analytics/YoutubeDashboardDetails";
import YouTube from "../../images/youtube.svg";

const YoutubeDashboard = () => {
  const dispatch = useDispatch();

  const { youtubeRange, youtubeData } = useSelector((state) => state.youtube);

  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    fetchYoutubeData();
  }, [youtubeRange]);

  // Function to get article data from API and update redux store if older than 5 minutes
  const fetchYoutubeData = async () => {
    setLoadingPage(true);
    dispatch(updateYoutubeData({}));

    try {
      const endPoint = `/api/internal_yt_stats/${youtubeRange}`;
      const { data } = await axios.get(endPoint);

      dispatch(updateYoutubeData(data));
      setLoadingPage(false);
    } catch (error) {
      dispatch(updateYoutubeData({ status: 0 }));
      setLoadingPage(false);
    }
  };

  const buttonGroupOptions = [
    {
      label: "1 Month",
      value: 1,
    },
    {
      label: "3 Months",
      value: 3,
    },
    {
      label: "6 Months",
      value: 6,
    },
    {
      label: "12 Months",
      value: 12,
    },
  ];

  const handleYoutubeRange = (e) => {
    dispatch(updateYoutubeRange(e.target.value));
  };

  return (
    <>
      <BaseTitle heading="Youtube Analytics" />

      <div className="grid gap-5 md:grid-cols-3 mb-12">
        <div className="w-96">
          <label className="block text-sm font-medium mb-1" htmlFor="property">
            Channel Name
          </label>
          <div className="flex items-center">
            <img src={YouTube} alt="Youtube" width="40" />
            <span>
              <a
                href="https://www.youtube.com/proactive247"
                target="_blank"
                className="ml-2 font-medium text-violet"
              >
                @Proactive247
              </a>
            </span>
          </div>
        </div>
        <div className="w-96">
          <label className="block text-sm font-medium mb-1">Date Range</label>
          <ButtonGroup
            type="button"
            options={buttonGroupOptions}
            active={youtubeRange}
            onClick={handleYoutubeRange}
          />
        </div>
      </div>

      {!loadingPage ? (
        <>
          {youtubeData &&
          Object.keys(youtubeData).length > 0 &&
          youtubeData.status !== 0 ? (
            <YoutubeDashboardDetails data={youtubeData} />
          ) : (
            <BaseCard>There is no data for youtube analytics.</BaseCard>
          )}
        </>
      ) : (
        <div className="flex flex-grow justify-center items-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#e94a3e"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

export default YoutubeDashboard;
