import { useEffect } from "react";
import { useSelector } from "react-redux";

import axios from "../../../api/AxiosHttp";
import DetailedStats from "../../../components/DetailedStats";
import BaseCard from "../../../components/base/BaseCard";
import BaseTable from "../../../components/base/BaseTable";
import DoughnutChart from "../../../components/charts/DoughnutChart";
import StockChart from "../../../components/charts/StockChart";
import Tippy from "../../../lib/Tipy";

import {
  formatDataForSeries,
  formatDate,
  formatDateToTimestamp,
  formatTime,
  hexToRGB,
  toCamel,
} from "../../../utils/Utils";

const VideoDetails = ({ data, videoMeta }) => {
  const { companyData } = useSelector((state) => state.company);
  const { userData } = useSelector((state) => state.auth);

  useEffect(() => {
    Tippy("[data-tippy-content]");
    logActivity();
  }, []);

  const logActivity = async () => {
    if (
      userData.role === process.env.REACT_APP_CLIENT &&
      !sessionStorage.getItem(
        "view_video_" + videoMeta.video_id + companyData.company_id
      )
    ) {
      const endPoint = `/api/users/${userData.id}/activity`;
      axios.post(endPoint, {
        model_id: videoMeta.video_id,
        details: videoMeta.title,
        action: "view_video",
        type: "youtube",
      });
      sessionStorage.setItem(
        "view_video_" + videoMeta.video_id + companyData.company_id,
        true
      );
    }
  };

  const visitorCountriesTable = [
    { name: "key", header: "Country", defaultFlex: 1.1 },
    {
      name: "value",
      header: "%",
      defaultFlex: 0.3,
      render: ({ data }) => {
        return `${data.value}%`;
      },
    },
  ];

  const externalSourcesTable = [
    { name: "key", header: "Sources", defaultFlex: 1.1 },
    {
      name: "value",
      header: "%",
      defaultFlex: 0.3,
      render: ({ data }) => {
        return `${data.value}%`;
      },
    },
  ];

  const viewsOverTimechartData = {
    plotOptions: {
      areaspline: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, `rgba(${hexToRGB("#FDC700")}, 0.4)`],
            [1, `rgba(${hexToRGB("#FDC700")}, 0.1)`],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 2,
        lineColor: "#FF9800",
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        threshold: null,
      },
    },
    xAxis: {
      min: formatDateToTimestamp(Object.keys(data.chart_data)[0]),
      max: formatDateToTimestamp(Object.keys(data.chart_data)[0]) + 12096e5,
    },
    rangeSelector: {
      selected: 1,
    },
    scrollbar: {
      barBackgroundColor: "#777777",
      barBorderWidth: 0,
      buttonArrowColor: "#D5DADD",
      rifleColor: "#D5DADD",
      buttonBackgroundColor: "#777777",
      trackBackgroundColor: "#F4F4F4",
      height: 23,
    },
    navigator: {
      maskFill: "rgba(51, 51, 51, .15)",
      outlineColor: "#333333",
      handles: {
        backgroundColor: "#777777",
        borderColor: "#D5DADD",
        height: 20,
        width: 10,
      },
      series: {
        color: "#FF9800",
        fillColor: "#fff",
      },
    },
    series: [
      {
        name: "Views",
        data: formatDataForSeries(data.chart_data),
        type: "areaspline",
      },
    ],
  };

  var ageChartDataColors = [
    "#12495D",
    "#FFA100",
    "#FF7338",
    "#FF5167",
    "#E44289",
    "#B9419D",
    "#6B4F96",
  ];
  const ageChartData = {
    series: [
      {
        name: "Age Ranges",
        data: data.age.map((item, index) => ({
          name: toCamel(item.key),
          y: parseFloat(item.value),
          color: ageChartDataColors[index],
        })),
      },
    ],
  };

  var genderChartDataColors = ["#369440", "#A0BF4D", "#D9DD3F"];
  const genderChartData = {
    series: [
      {
        name: "Gender",
        data: data.gender.map((item, index) => ({
          name: toCamel(item.key),
          y: parseFloat(item.value),
          color: genderChartDataColors[index],
        })),
      },
    ],
  };

  if (data.totals && data.totals[0].value == null) {
    return (
      <div className="col-span-2">
        <BaseCard>
          No data found for this video, please check back later.
        </BaseCard>
      </div>
    );
  }

  const statsTooltip = [
    "Sourced from YouTube Analytics, the total number of views the video has received since it was published.",
    "Sourced from YouTube Analytics, the total watch time of your video divided by the total number of video plays, displayed in seconds.",
    "Sourced from YouTube Analytics, the total watch time since your video was published, displayed in minutes.",
  ];

  return (
    <div className="col-span-2">
      <BaseCard id="videoDetails" className="px-0 py-0">
        <div className="mx-auto sm:max-w-xl md:max-w-full p-4 lg:p-8 lg:pb-12">
          <div className="flex flex-wrap gap-y-6 lg:flex-nowrap">
            {data.totals.map((item, index) => {
              return (
                <DetailedStats
                  key={index}
                  label={item.key}
                  value={item.value}
                  data-tippy-content={statsTooltip[index]}
                />
              );
            })}
          </div>
        </div>

        <div className="border-t border-solid p-4 lg:p-8 lg:pt-12">
          <div className="pb-12 mx-auto sm:max-w-xl md:max-w-full">
            <div>
              <div className="col-span-10 flex flex-col gap-y-4">
                <h2 className="text-3xl font-bold">{videoMeta.title}</h2>

                <div className="col-span-2 flex-col items-start md:flex-row lg:items-end inline-flex w-full">
                  <p className="text-1xl mt-3 mb-2 text-olive font-bold mr-10">
                    {formatDate(videoMeta.date)}
                    <span className="ml-6">{formatTime(videoMeta.date)}</span>
                  </p>
                  <div>
                    <a
                      href={videoMeta.url}
                      className="btn bg-light-grey-dark hover:bg-dark-grey hover:text-white text-dark-grey font-bold rounded-[20px] mr-4"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Video
                    </a>
                    <a
                      href={`https://www.google.com/search?q="${videoMeta.title.replace(
                        "&",
                        "%26"
                      )}"`}
                      className="btn bg-light-grey-dark hover:bg-dark-grey hover:text-white text-dark-grey font-bold rounded-[20px]"
                      target="_blank"
                    >
                      Syndication
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb-11 mx-auto sm:max-w-xl md:max-w-full">
            <div className="grid grid-cols-1 gap-8">
              <div>
                <h4
                  className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                  data-tippy-content="Placeholder, need text here"
                >
                  Video views over time
                </h4>
                <div className="grow">
                  <StockChart options={viewsOverTimechartData} />
                </div>
              </div>
            </div>
          </div>

          <div className="pb-12 mx-auto sm:max-w-xl md:max-w-full">
            <div className="grid lg:grid-cols-2 gap-8">
              <div>
                <h4
                  className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                  data-tippy-content="Sourced from YouTube Analytics, the age range of video viewers where known, displayed as a percentage."
                >
                  Age Ranges
                </h4>
                {data.age.length > 0 ? (
                  <DoughnutChart options={ageChartData} />
                ) : (
                  <div className="w-full h-full flex flex-col justify-start items-left">
                    <div
                      className="w-full h-full flex items-center justify-center font-bold"
                      style={{
                        borderRadius: "100%",
                        border: "35px solid #f1f5f9",
                        width: "230px",
                        height: "230px",
                        marginTop: "1rem",
                      }}
                    >
                      Not enough data
                    </div>
                  </div>
                )}
              </div>
              <div>
                <h4
                  className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                  data-tippy-content="Sourced from YouTube Analytics, the gender of video viewers where known, displayed as a percentage."
                >
                  Gender
                </h4>

                {data.gender.length > 0 ? (
                  <DoughnutChart options={genderChartData} />
                ) : (
                  <div className="w-full h-full flex flex-col justify-start items-left">
                    <div
                      className="w-full h-full flex items-center justify-center font-bold"
                      style={{
                        borderRadius: "100%",
                        border: "35px solid #f1f5f9",
                        width: "230px",
                        height: "230px",
                        marginTop: "1rem",
                      }}
                    >
                      Not enough data
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="pb-12 mx-auto sm:max-w-xl md:max-w-full">
            <div className="grid-cols-1 grid xl:grid-cols-2 gap-8">
              <div>
                <h4
                  className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                  data-tippy-content="Sourced from YouTube Analytics, the sources of traffic from websites that embed your videos or link to your videos on YouTube, displayed as a percentage."
                >
                  Top 10 Viewer Countries
                </h4>
                <BaseTable
                  idProperty="visitor-countries"
                  dataSource={data.countries}
                  columns={visitorCountriesTable}
                  style={{ minHeight: 283 }}
                  className="no-border"
                  scrollHide={false}
                />
              </div>
              <div>
                <h4
                  className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                  data-tippy-content="Sourced from YouTube Analytics, the sources of traffic from websites that embed your videos or link to your videos on YouTube, displayed as a percentage."
                >
                  Top 10 External Referral
                </h4>
                <BaseTable
                  idProperty="external-sources"
                  dataSource={data.traffic_source}
                  columns={externalSourcesTable}
                  style={{ minHeight: 283 }}
                  className="no-border"
                  scrollHide={false}
                />
              </div>
            </div>
          </div>
        </div>
      </BaseCard>
    </div>
  );
};

export default VideoDetails;
