import { createSlice } from "@reduxjs/toolkit";

export const forensicSlice = createSlice({
  name: "forensic",
  initialState: {
    forensicData: {},
  },
  reducers: {
    updateForensicData: (state, action) => {
      state.forensicData = action.payload;
    },
  },
});

export const { updateForensicData } = forensicSlice.actions;

export default forensicSlice.reducer;