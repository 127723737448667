import { createSlice } from "@reduxjs/toolkit";

export const youtubeSlice = createSlice({
  name: "youtube",
  initialState: {
    youtubeRange: parseInt(process.env.REACT_APP_YOUTUBE_RANGE_DEFAULT),
    youtubeData: {},
  },
  reducers: {
    updateYoutubeRange: (state, action) => {
      state.youtubeRange = action.payload;
    },
    updateYoutubeData: (state, action) => {
      state.youtubeData = action.payload;
    },
  },
});

export const { updateYoutubeRange, updateYoutubeData } = youtubeSlice.actions;

export default youtubeSlice.reducer;