import { useState, useRef } from "react";
import {
  useNavigate,
  useSearchParams,
  Link,
  useParams,
} from "react-router-dom";
import axios from "../../api/AxiosHttp";
import Noty from "../../lib/Noty";

import BaseButton from "../../components/base/BaseButton";

import paLogo from "../../images/pa-analytics-logo.svg";
import authIllustration from "../../images/auth-illustration.svg";

const ResetPassword = () => {
  const [submitLoading, setSubmitLoading] = useState(false);

  const password = useRef(null);
  const confirmPassword = useRef(null);

  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const email = searchParams.get("email");

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitLoading(false);

    try {
      await axios.get("/sanctum/csrf-cookie");
    } catch (error) {
      console.error(error);
      new Noty({ type: "error", text: "Failed to fetch CSRF cookie." }).show();
      setSubmitLoading(false);
      return;
    }

    try {
      const response = await axios.post("/api/reset-password", {
        email,
        token,
        password: password.current.value,
        password_confirmation: confirmPassword.current.value,
      });

      new Noty({
        type: "success",
        text: response.data.message,
      }).show();

      navigate("/login");
    } catch (error) {
      setSubmitLoading(false);

      if (error.response) {
        const { errors } = error.response.data;
        const notymessage = errors[Object.keys(errors)[0]];

        new Noty({
          type: "error",
          text: notymessage,
        }).show();
      }
    }
  };

  return (
    <div className="bg-white min-h-screen">
      <div className="relative md:flex">
      <div
          className="hidden md:flex md:flex-col md:items-center md:justify-center md:w-1/2 md:h-screen bg-[#f9f9f9]"
          aria-hidden="true"
        >
          <img className="w-[70%] max-w-[650px]" src={authIllustration} />
          <div className="px-4 py-8 md:py-0 md:px-0 text-left mt-24 max-w-md mx-auto">
            <h3 className="text-[1.9rem] text-slate-800 font-[500] mb-6">
              Welcome to our digital analytics platform!
            </h3>
            <p>
              We use Google Analytics, Search Console and YouTube Analytics data
              to inform our clients about their content and performance. Our
              goal is to help you understand your users, improve your
              understanding, and increase your online visibility.
            </p>
          </div>
        </div>

        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            <div className="flex-1"></div>

            <div className="max-w-md mx-auto px-4 py-8 w-full">
              <div className="flex items-center justify-between py-12">
                <img
                  src={paLogo}
                  alt="Proactive Investors"
                  className="w-52"
                ></img>
              </div>
              <h1 className="text-3xl text-slate-800 font-bold mb-2">
                Create your new password
              </h1>
              <p className="mb-8">Please enter your new password!</p>
              <form onSubmit={handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      id="password"
                      className="form-input w-full"
                      type="password"
                      ref={password}
                      placeholder="Enter Password"
                      autoComplete="true"
                      required
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password-confirm"
                    >
                      Confirm Password
                    </label>
                    <input
                      id="password-confirm"
                      className="form-input w-full"
                      type="password"
                      ref={confirmPassword}
                      placeholder="Enter Password"
                      autoComplete="true"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center justify-between mt-2">
                  <div className="mr-1 mb-4 self-end">
                    <Link className="text-sm" to="/login">
                      Back to Login
                    </Link>
                  </div>
                  <BaseButton
                    label="Change Password"
                    type="submit"
                    loading={submitLoading}
                    styleOptions={{ isFullWidth: true, size: "large" }}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
