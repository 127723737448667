import BaseTable from "../../components/base/BaseTable";
import BaseCard from "../../components/base/BaseCard";
import { formatThousands, formatDate } from "../../utils/Utils";
import { useEffect } from "react";

const NewsLookupDashboardDetails = ({ data }) => {
  useEffect(() => {
    addTotal(data.matomo);
    addTotal(data.ga4);
  }, [data]);

  const flags = {
    [process.env.REACT_APP_GA4_UK]: {
      url: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg",
    },
    [process.env.REACT_APP_GA4_US]: {
      url: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg",
    },
    [process.env.REACT_APP_GA4_CA]: {
      url: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg",
    },
    [process.env.REACT_APP_GA4_AU]: {
      url: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg",
    },
  };

  const addTotal = (data) => {
    let total = 0;

    if ("All Regions" in data || data.length === 0) {
      return;
    }
    data.forEach((item) => {
      total += parseInt(item.total_views);
    });

    return data.push({
      property_id: "All Regions",
      total_views: total,
    });
  };

  const matomoTable = [
    {
      name: "property_id",
      header: "Region",
      defaultFlex: 2,
      render: ({ data }) => {
        return flags[data.property_id] ? (
          <img
            width="30"
            style={{ marginLeft: 8 }}
            src={flags[data.property_id].url}
          />
        ) : (
          data.property_id
        );
      },
    },
    {
      name: "total_views",
      header: "Views",
      defaultFlex: 0.4,
      render: ({ data }) => {
        return formatThousands(data.total_views);
      },
    },
  ];

  const gaTable = [
    {
      name: "property_id",
      header: "Region",
      defaultFlex: 2,
      render: ({ data }) => {
        return flags[data.property_id] ? (
          <img
            width="30"
            style={{ marginLeft: 8 }}
            src={flags[data.property_id].url}
          />
        ) : (
          data.property_id
        );
      },
    },
    {
      name: "total_views",
      header: "Views",
      defaultFlex: 0.4,
      render: ({ data }) => {
        return formatThousands(data.total_views);
      },
    },
  ];
  return (
    <div className="grid gap-5 md:grid-cols-2">
      <BaseCard>
        <h4
          className="text-xl mb-1 uppercase text-violet font-bold w-fit"
          data-tippy-content="Where the user found a link to our site; where they were before they visited us (GA4)"
        >
          Matomo
        </h4>

        <p className="text-base">Views for today</p>

        <BaseTable
          idProperty="matomo"
          dataSource={data.matomo}
          columns={matomoTable}
          style={{ minHeight: 255 }}
          className="mt-6 no-border"
          scrollHide={false}
        />
      </BaseCard>
      <BaseCard>
        <h4
          className="text-xl mb-1 uppercase text-violet font-bold w-fit"
          data-tippy-content="Where the user found a link to our site; where they were before they visited us (GA4)"
        >
          Google Analytics
        </h4>

        {data.ga4.length > 1 && (
          <p className="text-base">
            Views from {formatDate(data.publish_date)} -{" "}
            {formatDate(new Date().setDate(new Date().getDate() - 1))}
          </p>
        )}

        <BaseTable
          idProperty="ga4"
          dataSource={data.ga4}
          columns={gaTable}
          style={{ minHeight: 255 }}
          className="mt-6 no-border"
          scrollHide={false}
        />
      </BaseCard>
    </div>
  );
};

export default NewsLookupDashboardDetails;
