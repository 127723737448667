import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import PieChart from "highcharts-react-official";
import Highcharts, { chart } from "highcharts/highstock";

function DoughnutChart({ options, chartType, className }) {
  options.credits = "enabled: false,";

  const doughnutRef = useRef();

  const { companyData } = useSelector((state) => state.company);

  useEffect(() => {
    updateDoughnutImage();
  }, [companyData]);

  function highchartsSVGtoImage(chart, callback) {
    var svg = chart.getSVG();

    var canvas = document.createElement("canvas");
    canvas.width = 400;
    canvas.height = 260;
    var ctx = canvas.getContext("2d");

    var img = document.createElement("img");

    img.onload = function () {
      ctx.drawImage(img, 0, 0);
      callback(canvas.toDataURL("image/png"));
    };

    img.setAttribute(
      "src",
      "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svg)))
    );
  }

  const updateDoughnutImage = () => {
    // const doughnutData =
    //   sessionStorage.getItem(chartType + companyData.id) &&
    //   JSON.parse(sessionStorage.getItem(chartType + companyData.id));

    // if (doughnutData) {
    //   return;
    // }

    if (chartType && doughnutRef.current.chart) {
      highchartsSVGtoImage(doughnutRef.current.chart, (img) => {
        sessionStorage.setItem(chartType + companyData.id, JSON.stringify(img));
      });
    }
  };

  Highcharts.setOptions({
    chart: {
      style: {
        fontFamily: "Ubuntu, sans-serif",
      },
    },
  });

  const defaultOptions = {
    chart: {
      height: 260,
      type: "pie",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    exporting: {
      sourceWidth: 400,
      sourceHeight: 260,
      // scale: 2 (default)
      chartOptions: {
        subtitle: null,
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    tooltip: {
      backgroundColor: "#FFFFFF",
      borderColor: "rgba(51,51,51,0.1)",
      borderRadius: 10,
      padding: 12,
      useHTML: true,
      style: {
        pointerEvents: "auto",
      },
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    title: {
      text: null,
    },
    plotOptions: {
      pie: {
        center: [100, 100],
        size: 230,
        innerSize: "72%",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      y: 5,
      itemMarginBottom: 9,
      itemStyle: {
        fontSize: "16px",
        fontWeight: "normal",
      },
    },
  };

  Object.assign(options, defaultOptions);

  return (
    <div className={`grow ${className}`}>
      <div>
        <PieChart highcharts={Highcharts} options={options} ref={doughnutRef} />
      </div>
    </div>
  );
}

export default DoughnutChart;
