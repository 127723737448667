import { useEffect, useState, useRef } from "react";
import axios from "../../api/AxiosHttp";

import { useSelector, useDispatch } from "react-redux";
import Noty from "../../lib/Noty";
import ModalFeedback from "../../components/ModalFeedback";
import { handleErrorNoty } from "../../utils/Utils";
import { updateUserData } from "../../redux/authSlice";

import BaseTitle from "../../components/base/BaseTitle";
import BaseButton from "../../components/base/BaseButton";

const Profile = () => {
  const [QRSvg, setQRSvg] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [twoFactor, setTwoFactor] = useState();
  const [profileSubmitLoading, setProfileSubmitLoading] = useState(false);
  const [passwordSubmitLoading, setPasswordSubmitLoading] = useState(false);
  const [twoFactorSubmitLoading, setTwoFactorSubmitLoading] = useState(false);

  const closeModal = () => setModalOpen(false);

  const confirm2FACode = useRef(null);
  const { userData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setTwoFactor(!!userData.two_factor_confirmed_at);
  }, [userData]);

  const updateProfile = async (e) => {
    e.preventDefault();

    setProfileSubmitLoading(true);

    const formData = new FormData(e.currentTarget);
    let formObject = Object.fromEntries(formData.entries());

    const endPoint = "api/user/profile-information";

    try {
      await axios.put(endPoint, formObject);
      var newUserData = { ...userData };
      newUserData.name = formObject.name;
      newUserData.email = formObject.email;

      dispatch(updateUserData(newUserData));

      setProfileSubmitLoading(false);

      new Noty({
        type: "success",
        text: "Profile was updated successfully",
        timeout: 3000,
        modal: true,
        progressBar: true,
      }).show();
    } catch (error) {
      setProfileSubmitLoading(false);
      handleErrorNoty(error);
    }
  };

  const confirm2FA = async () => {
    const endPoint = "api/user/confirmed-two-factor-authentication";

    const confirm2FAinput = confirm2FACode.current.value;

    try {
      await axios.post(endPoint, { code: confirm2FAinput });
      closeModal();
      setTwoFactor(true);

      new Noty({
        type: "success",
        text: "Two Factor was successfully added.",
        timeout: 3000,
        modal: true,
        progressBar: true,
      }).show();
    } catch (error) {
      handleErrorNoty(error);
    }
  };

  const getQRCode = async () => {
    const endPoint = "api/user/two-factor-qr-code";

    try {
      const res = await axios.get(endPoint);
      setQRSvg(res.data.svg);
      setModalOpen(true);
    } catch (error) {
      handleErrorNoty(error);
    }
  };

  const enable2FA = async () => {
    const endPoint = "api/user/two-factor-authentication";

    try {
      await axios.post(endPoint);
      getQRCode();
    } catch (error) {
      handleErrorNoty(error);
    }
  };

  const disable2FA = async () => {
    const endPoint = "api/user/two-factor-authentication";
    try {
      await axios.delete(endPoint);
      setTwoFactor(false);
      new Noty({
        type: "success",
        text: "Two Factor was removed, we recommend setting this.",
        timeout: 3000,
        modal: true,
        progressBar: true,
      }).show();
    } catch (error) {
      handleErrorNoty(error);
    }
  };

  const toggleTwoAuth = async (e) => {
    e.preventDefault();

    setTwoFactorSubmitLoading(true);

    const formData = new FormData(e.currentTarget);
    let formObject = Object.fromEntries(formData.entries());
    e.currentTarget.reset();

    const actionType = formObject.action;

    const endPoint = "api/user/confirm-password";

    try {
      await axios.post(endPoint, { password: formObject.password });
      actionType === "enable" ? enable2FA() : disable2FA();
      setTwoFactorSubmitLoading(false);
    } catch (error) {
      handleErrorNoty(error);
      setTwoFactorSubmitLoading(false);
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();

    setPasswordSubmitLoading(true);

    const formData = new FormData(e.currentTarget);
    let formObject = Object.fromEntries(formData.entries());

    const endPoint = "api/user/password";

    try {
      await axios.put(endPoint, formObject);
      setPasswordSubmitLoading(false);
      new Noty({
        type: "success",
        text: "Password was successfully updated.",
        timeout: 3000,
        modal: true,
        progressBar: true,
      }).show();
      e.target.reset();
    } catch (error) {
      handleErrorNoty(error);
      setPasswordSubmitLoading(false);
    }
  };

  return (
    <>
      <ModalFeedback
        id="two-factor-modal"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <div className="p-5 flex space-x-4">
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100">
            <svg
              className="w-4 h-4 shrink-0 fill-current text-primary"
              viewBox="0 0 16 16"
            >
              <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
            </svg>
          </div>
          <div>
            <div className="mb-2">
              <div className="text-lg font-semibold text-slate-800">
                Setup Two Factor Authentication
              </div>
            </div>
            <div className="text-sm mb-6">
              <div className="space-y-2 mr-2">
                <p>
                  Scan the QR code below onto your favorite authentication app.
                  An example being Google Authenticator.
                </p>
              </div>
            </div>
            <div
              className="flex justify-center mb-6"
              style={{ width: "calc(100% - 3.5rem)" }}
              dangerouslySetInnerHTML={{ __html: QRSvg }}
            ></div>
            <div
              className="flex flex-col justify-center mb-6"
              style={{ width: "calc(100% - 3.5rem)" }}
            >
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="confirm2FA"
              >
                Enter the code from your app
              </label>
              <input
                id="confirm2FA"
                name="confirm2FA"
                ref={confirm2FACode}
                className="form-input w-full"
                type="number"
              />
            </div>
            <div className="flex flex-wrap justify-end space-x-2">
              <button
                className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
                onClick={(e) => {
                  e.stopPropagation();
                  setModalOpen(false);
                  disable2FA();
                }}
              >
                Cancel
              </button>
              <button
                className="btn-sm bg-primary hover:bg-primary-dark text-white"
                onClick={() => {
                  confirm2FA();
                }}
              >
                Confirm your 2FA code
              </button>
            </div>
          </div>
        </div>
      </ModalFeedback>
      <BaseTitle heading="Account Settings" />
      <div className="bg-white shadow-lg rounded-sm mb-8">
        <div className="flex flex-col md:flex-row md:-mr-px">
          <div className="grow">
            <div className="p-6">
              <section>
                <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1">
                  User Profile
                </h2>
                <div className="text-sm max-w-screen-sm">
                  You can manage your personal information and preferences to
                  customise your experience on our platform.
                </div>
                <form
                  className="space-y-6"
                  encType="multipart/form-data"
                  onSubmit={updateProfile}
                >
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div className="sm:w-1/2">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="name"
                      >
                        Name
                      </label>
                      <input
                        id="name"
                        name="name"
                        className="form-input w-full"
                        type="text"
                        defaultValue={userData.name}
                      />
                    </div>
                    <div className="sm:w-1/2">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        id="email"
                        name="email"
                        className="form-input w-full"
                        type="text"
                        defaultValue={userData.email}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex self-start">
                      <BaseButton
                        label="Update Profile"
                        type="submit"
                        loading={profileSubmitLoading}
                      />
                    </div>
                  </div>
                </form>
              </section>
              <section className="border-t border-slate-200 mt-10 pt-10">
                <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1">
                  Change Password
                </h2>
                <div className="text-sm max-w-screen-sm">
                  Please make sure to choose a strong and unique password that
                  is not easily guessed.
                </div>
                <form
                  className="space-y-6"
                  encType="multipart/form-data"
                  onSubmit={updatePassword}
                >
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div className="sm:w-1/3">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="current_password"
                      >
                        Current Password
                      </label>
                      <input
                        id="current-password"
                        name="current_password"
                        className="form-input w-full"
                        type="password"
                        required
                      />
                    </div>
                    <div className="sm:w-1/3">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="new-password"
                      >
                        New Password
                      </label>
                      <input
                        id="new-password"
                        name="password"
                        className="form-input w-full"
                        type="password"
                        required
                        minLength="8"
                      />
                    </div>
                    <div className="sm:w-1/3">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password-confirmation"
                      >
                        Confirm Password
                      </label>
                      <input
                        id="password-confirmation"
                        name="password_confirmation"
                        className="form-input w-full"
                        type="password"
                        required
                        minLength="8"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex self-start">
                      <BaseButton
                        label="Update Password"
                        type="submit"
                        loading={passwordSubmitLoading}
                      />
                    </div>
                  </div>
                </form>
              </section>
              <section className="border-t border-slate-200 mt-10 pt-10">
                <div className="flex flex-col mb-4 lg:flex-row items-start lg:items-center">
                  <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1 mr-2">
                    Two Factor Authentication
                  </h2>
                  <div className="text-xs">
                    {twoFactor ? (
                      <span className="bg-green-600 text-white pt-1 pb-1 pl-1 pr-1 rounded">
                        Status: Enabled
                      </span>
                    ) : (
                      <span className="bg-red-600 text-white pt-1 pb-1 pl-1 pr-1 rounded">
                        Not enabled
                      </span>
                    )}
                  </div>
                </div>
                <div className="text-sm max-w-screen-sm">
                  2FA is an extra layer of security for your account. It
                  requires you to provide not only your password, but also a
                  unique code generated by an app on your mobile device.
                </div>
                <form
                  className="space-y-6"
                  encType="multipart/form-data"
                  onSubmit={toggleTwoAuth}
                >
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div className="sm:w-1/2">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="current-password"
                      >
                        Current Password
                      </label>
                      <input
                        id="current-password"
                        name="password"
                        className="form-input w-full"
                        type="password"
                      />
                    </div>
                  </div>
                  <input
                    className="hidden"
                    type="hidden"
                    name="action"
                    value={twoFactor ? "disable" : "enable"}
                  ></input>
                  <div className="flex flex-col">
                    <div className="flex self-start">
                      <BaseButton
                        label={twoFactor ? "Disable 2FA" : "Setup 2FA"}
                        type="submit"
                        loading={twoFactorSubmitLoading}
                        styleOptions={{ color: "secondary" }}
                      />
                    </div>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
