import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

import paLogo from "../../images/pa-analytics-logo-white.svg";
import AdminLinks from "./AdminLinks";
import AnalyticsLinks from "./AnalyticsLinks";

function Sidebar({ type, sidebarOpen, setSidebarOpen }) {
  const trigger = useRef(null);
  const sidebar = useRef(null);

  const { currentCompanyId } = useSelector((state) => state.auth);

  const { id } = useParams();

  let logoURL;
  if (currentCompanyId) {
    logoURL = `/analytics/${currentCompanyId}`;
  } else if (type === "analytics") {
    logoURL = `/analytics/${id}`;
  } else {
    logoURL = "/";
  }

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div>
      {/* Sidebar backdrop (mobile/tablet only) */}
      <div
        className={`fixed inset-0 bg-secondary bg-opacity-30 z-40 2xl:hidden 2xl:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 2xl:static 2xl:left-auto 2xl:top-auto 2xl:translate-x-0 h-screen overflow-y-scroll 2xl:overflow-y-auto no-scrollbar w-64 2xl:!w-64 shrink-0 bg-secondary p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Logo */}
          <NavLink end to={logoURL} className="block mt-3">
            <img
              className=""
              src={paLogo}
              width="200"
              height="100"
              alt="Proactive Investors"
            />
          </NavLink>
          {/* Close button */}
          <button
            ref={trigger}
            className="2xl:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
        </div>

        {type == "analytics" || currentCompanyId ? (
          <AnalyticsLinks />
        ) : (
          <AdminLinks />
        )}
      </div>
    </div>
  );
}

export default Sidebar;
