import { forwardRef } from "react";
import PropTypes from "prop-types";

// styleOptions {}
// { isFullWidth: true}
// { size: 'large'}
// { color: 'primary', 'secondary}

const BaseButton = forwardRef(
  (
    {
      className,
      label,
      type,
      onClick,
      disabled = false,
      loading = false,
      dropdown = false,
      styleOptions = {},
      ...props
    },
    ref
  ) => {
    const { isFullWidth = false, size = "", color = "primary" } = styleOptions;

    const mainClass = "btn";
    const colorClass =
      color === "primary"
        ? "bg-primary hover:bg-primary-dark text-white"
        : color === "grey"
        ? "text-dark-grey bg-light-grey-dark hover:bg-dark-grey hover:text-white"
        : "border-slate-200 hover:border-slate-300 !text-primary";
    const widthClass = isFullWidth && "w-full";
    const sizeClass = size == "large" && "h-11 px-8 py-2";
    const disabledClass =
      disabled ||
      (loading &&
        "disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed shadow-none");

    return (
      <button
        className={[
          className,
          mainClass,
          colorClass,
          widthClass,
          sizeClass,
          disabledClass,
        ].join(" ")}
        ref={ref}
        type={type}
        onClick={disabled || loading ? null : onClick}
        disabled={disabled || loading}
        {...props}
      >
        {!loading ? (
          <>
            {label}
            {dropdown && (
              <svg
                className="w-3 h-3 shrink-0 ml-1 fill-current text-white"
                viewBox="0 0 12 12"
              >
                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
              </svg>
            )}
          </>
        ) : (
          <>
            <svg
              className="animate-spin w-4 h-4 fill-current shrink-0"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
            <span className="ml-2">{label}</span>
          </>
        )}
      </button>
    );
  }
);

BaseButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  dropdown: PropTypes.bool,
  styleOptions: PropTypes.object,
};

export default BaseButton;
