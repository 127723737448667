import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({
  required = null,
  redirectPath = "/404",
  children,
}) => {

  const { isLoggedIn, userData } = useSelector((state) => state.auth);

  if (isLoggedIn && !required.includes(userData.role)) {
    return <Navigate to={redirectPath} replace />;
  } else if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;