import HighchartsReact from "highcharts-react-official";
import Highcharts, { chart } from "highcharts/highstock";
import { objectMerge } from "../../utils/Utils";

function BarChart({ options, className }) {
  options.credits = "enabled: false,";

  Highcharts.setOptions({
    chart: {
      style: {
        fontFamily: "Ubuntu, sans-serif",
      },
    },
  });

  const defaultOptions = {
    chart: {
      height: 260,
      type: "column",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    xAxis: {
      labels: {
        style: {
          fontSize: 13,
        },
      },
    },
    yAxis: {
      title: {
        style: {
          fontSize: 15,
          fontWeight: "medium",
        },
      },
      labels: {
        style: {
          fontSize: 13,
        },
      },
    },
    tooltip: {
      backgroundColor: "#FFFFFF",
      borderColor: "rgba(51,51,51,0.1)",
      borderRadius: 10,
      padding: 12,
      useHTML: true,
      style: {
        pointerEvents: "auto",
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    title: {
      text: null,
    },
    legend: {
      itemMarginBottom: 9,
      itemStyle: {
        fontSize: "16px",
        fontWeight: "normal",
      },
    },
  };

  const mergedOptions = objectMerge(defaultOptions, options);

  return (
    <div className={`grow ${className}`}>
      <div>
        <HighchartsReact highcharts={Highcharts} options={mergedOptions} />
      </div>
    </div>
  );
}

export default BarChart;
