import { useEffect } from "react";

import {
  formatThousands,
  formatObjectToArrOfObjects,
  formatObjectToArrOfArrays,
  toCamel,
  minTommss,
} from "../../utils/Utils";
import Tippy from "../../lib/Tipy";
import BaseCard from "../../components/base/BaseCard";
import BaseTable from "../../components/base/BaseTable";
import LineChart from "../../components/charts/LineChart";
import DoughnutChart from "../../components/charts/DoughnutChart";
import BarChart from "../../components/charts/BarChart";

const YoutubeDashboardDetails = ({ data }) => {
  const { chart_data: chartData, dimensions } = data;

  useEffect(() => {
    Tippy("[data-tippy-content]");
  }, []);

  const viewsdefaultSortInfo = { name: "views", dir: -1 };

  const seriesDataLineChart = {
    views: [],
    watchTime: [],
    organicViews: [],
    embeddedViews: [],
  };

  chartData?.forEach((data) => {
    seriesDataLineChart.views.push([
      new Date(data.date).getTime(),
      parseInt(data.views),
    ]);
    seriesDataLineChart.watchTime.push([
      new Date(data.date).getTime(),
      parseInt(data.watch_time),
    ]);
    seriesDataLineChart.organicViews.push([
      new Date(data.date).getTime(),
      parseInt(data.organic_views),
    ]);
    seriesDataLineChart.embeddedViews.push([
      new Date(data.date).getTime(),
      parseInt(data.embedded_views),
    ]);
  });

  const trafficChartOptions = {
    plotOptions: {
      line: {
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        threshold: null,
      },
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    legend: {
      y: 30,
      title: {
        text: '<span style="font-size: 13px; color: #666; font-weight: normal;">Click labels below to<br>toggle chart metrics</span><br>&nbsp;<br>',
      },
    },

    series: [
      {
        name: "Watch Time",
        data: seriesDataLineChart.watchTime,
        type: "line",
        color: "#405890",
        visible: false,
      },
      {
        name: "Total Views",
        data: seriesDataLineChart.views,
        type: "line",
        color: "#015366",
      },
      {
        name: "Organic Views",
        data: seriesDataLineChart.organicViews,
        type: "line",
        color: "#9c4a88",
      },
      {
        name: "Views on our site",
        data: seriesDataLineChart.embeddedViews,
        type: "line",
        color: "#cc4a4a",
      },
    ],
  };

  const trafficSourceTable = [
    { name: "source", header: "Source", defaultFlex: 7 },
    {
      name: "views",
      header: "Views",
      defaultFlex: 1.7,
      type: "number",
      render: ({ data }) => {
        return formatThousands(data.views);
      },
    },
  ];

  const playbackLocation = formatObjectToArrOfArrays(
    dimensions?.playback_location
  );

  const playbackLocationChartOptions = {
    plotOptions: {
      series: {
        minPointLength: 3,
      },
    },
    xAxis: {
      type: "category",
      crosshair: true,
      accessibility: {
        description: "Location",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: "Location",
        data: playbackLocation,
        colors: [
          "#ff5959",
          "#e74a51",
          "#cf3b4a",
          "#b82c41",
          "#a11d39",
          "#8a0c30",
        ],
        colorByPoint: true,
      },
    ],
  };

  const playbackLocationEmbeddedTable = [
    { name: "source", header: "Source", defaultFlex: 7 },
    {
      name: "views",
      header: "Views",
      defaultFlex: 1.7,
      type: "number",
      render: ({ data }) => {
        return formatThousands(data.views);
      },
    },
  ];

  const countryTable = [
    { name: "country", header: "Country", defaultFlex: 5 },
    {
      name: "views",
      header: "Views",
      defaultFlex: 1.7,
      type: "number",
      render: ({ data }) => {
        return formatThousands(data.views);
      },
    },
  ];

  const searchTermTable = [
    { name: "keyword", header: "Keyword", defaultFlex: 5 },
    {
      name: "views",
      header: "Views",
      defaultFlex: 1.7,
      type: "number",
      render: ({ data }) => {
        return formatThousands(data.views);
      },
    },
  ];

  const externalTrafficSourceTable = [
    { name: "source", header: "Source", defaultFlex: 7 },
    {
      name: "views",
      header: "Views",
      type: "number",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return formatThousands(data.views);
      },
    },
  ];

  const sharingTable = [
    { name: "type", header: "Type", defaultFlex: 5 },
    {
      name: "views",
      header: "Views",
      defaultFlex: 1.7,
      type: "number",
      render: ({ data }) => {
        return formatThousands(data.views);
      },
    },
  ];

  var ageColors = [
    "#12495D",
    "#FFA100",
    "#FF7338",
    "#FF5167",
    "#E44289",
    "#B9419D",
    "#6B4F96",
  ];

  const ageChartOptions = {
    series: [
      {
        name: "Age",
        data:
          "age_group" in dimensions
            ? Object.keys(dimensions.age_group).map((key, index) => ({
                name: toCamel(key).replace(/Age/i, ""),
                y: parseFloat(dimensions.age_group[key]),
                color: ageColors[index],
              }))
            : [],
      },
    ],
  };

  var genderColors = ["#FFA100", "#FF7338", "#FF5167"];

  const genderChartOptions = {
    series: [
      {
        name: "Gender",
        data:
          "gender" in dimensions
            ? Object.keys(dimensions.gender).map((key, index) => ({
                name: toCamel(key).replace(/Age/i, ""),
                y: parseFloat(dimensions.gender[key]),
                color: genderColors[index],
              }))
            : [],
      },
    ],
  };

  const devices = formatObjectToArrOfArrays(dimensions?.device_type);

  const devicesChartOptions = {
    xAxis: {
      type: "category",
      crosshair: true,
      accessibility: {
        description: "Devices",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
    },

    legend: {
      enabled: false,
    },
    series: [
      {
        name: "Devices",
        data: devices,
        colors: ["#369440", "#38a737", "#3db928", "#47cc00"],
        colorByPoint: true,
      },
    ],
  };

  return (
    <>
      <BaseCard>
        <div className="px-5 py-1 mb-8">
          <div className="flex flex-wrap">
            <div className="flex items-center py-2">
              <div
                className="mr-5"
                data-tippy-content="Each time a viewer intentionally initiates the playing of a video on their device and watches for at least 30 seconds"
              >
                <div className="flex items-center">
                  <div className="text-3xl font-bold lg:text-[1.7rem] text-red mr-2">
                    {formatThousands(dimensions.basic_stats.views)}
                  </div>
                </div>
                <div className="text-sm text-slate-500">Views</div>
              </div>

              <div
                className="hidden md:block w-px h-8 bg-slate-200 mr-5"
                aria-hidden="true"
              ></div>
            </div>

            <div className="flex items-center py-2">
              <div
                className="mr-5"
                data-tippy-content="The average amount of time viewers spend watching a video. Average view duration is directly proportional to how engaging and interesting our videos are (and should be considered proportional to average video length)"
              >
                <div className="flex items-center">
                  <div className="text-3xl font-bold lg:text-[1.7rem] text-red mr-2">
                    {minTommss(
                      dimensions.basic_stats.average_view_duration / 60
                    )}
                  </div>
                </div>
                <div className="text-sm text-slate-500">Avg View Duration</div>
              </div>

              <div
                className="hidden md:block w-px h-8 bg-slate-200 mr-5"
                aria-hidden="true"
              ></div>
            </div>

            <div className="flex items-center py-2">
              <div
                className="mr-5"
                data-tippy-content="The number of hours watched across our channel in the given time period"
              >
                <div className="flex items-center">
                  <div className="text-3xl font-bold lg:text-[1.7rem] text-red mr-2">
                    {formatThousands(
                      dimensions.basic_stats.estimated_hours_watched
                    )}
                  </div>
                </div>
                <div className="text-sm text-slate-500">Hours Watched</div>
              </div>
              <div
                className="hidden md:block w-px h-8 bg-slate-200 mr-5"
                aria-hidden="true"
              ></div>
            </div>

            <div className="flex items-center py-2">
              <div
                className="mr-5"
                data-tippy-content="The cumulative number of likes our channel videos received in the given time period"
              >
                <div className="flex items-center">
                  <div className="text-3xl font-bold lg:text-[1.7rem] text-red mr-2">
                    {formatThousands(dimensions.basic_stats.likes)}
                  </div>
                </div>
                <div className="text-sm text-slate-500">Likes</div>
              </div>
              <div
                className="hidden md:block w-px h-8 bg-slate-200 mr-5"
                aria-hidden="true"
              ></div>
            </div>

            <div className="flex items-center py-2">
              <div
                className="mr-5"
                data-tippy-content="The number of times a user shared a video URL using YouTubes sharing feature"
              >
                <div className="flex items-center">
                  <div className="text-3xl font-bold lg:text-[1.7rem] text-red mr-2">
                    {formatThousands(dimensions.basic_stats.shares)}
                  </div>
                </div>
                <div className="text-sm text-slate-500">Shares</div>
              </div>
              <div
                className="hidden md:block w-px h-8 bg-slate-200 mr-5"
                aria-hidden="true"
              ></div>
            </div>

            <div className="flex items-center">
              <div
                className="mr-5"
                data-tippy-content="The number of new subscribers our channel received in the given time period (we will add Lost Subscribers, and Net Gain/Loss as additional fields soon)"
              >
                <div className="flex items-center">
                  <div className="text-3xl font-bold lg:text-[1.7rem] text-red mr-2">
                    {formatThousands(
                      dimensions.basic_stats.subscribers_gained -
                        dimensions.basic_stats.subscribers_lost
                    )}
                  </div>
                </div>
                <div className="text-sm text-slate-500">Subscribers</div>
              </div>
            </div>
          </div>
        </div>

        {seriesDataLineChart?.views.length > 1 ? (
          <LineChart options={trafficChartOptions} />
        ) : (
          <div className="w-full h-[260px] flex flex-col items-center justify-center text-sm">
            Not enough data
          </div>
        )}
      </BaseCard>

      <div className="grid gap-5 md:grid-cols-2">
        <BaseCard>
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="How viewers find our videos, primarily split into views within YouTube itself (advertising, suggested videos, browse features) and External views (our video embedded into our site(s) or other web pages)"
          >
            Traffic Source
          </h4>

          <BaseTable
            idProperty="traffic-source"
            dataSource={formatObjectToArrOfObjects(
              dimensions.traffic_source,
              "source",
              "views"
            )}
            columns={trafficSourceTable}
            style={{ minHeight: 283 }}
            className="mt-6 no-border"
            scrollHide={false}
            defaultSortInfo={viewsdefaultSortInfo}
          />
        </BaseCard>
        <BaseCard>
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="A granular look at sites that both embed our videos and link to our videos"
          >
            External Traffic Source
          </h4>

          <BaseTable
            idProperty="external-traffic-source"
            dataSource={formatObjectToArrOfObjects(
              dimensions.external_traffic_source,
              "source",
              "views"
            )}
            columns={externalTrafficSourceTable}
            style={{ minHeight: 283 }}
            className="mt-6 no-border"
            scrollHide={false}
            defaultSortInfo={viewsdefaultSortInfo}
          />
        </BaseCard>
      </div>

      <div className="grid gap-5 md:grid-cols-2">
        <BaseCard className="flex flex-col">
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="Playback location shows the page, site, or device upon which your video was watched (Note: 'Other' typically combines views on TVs, Mobile and Tablet Apps)"
          >
            Playback Location
          </h4>
          {playbackLocation.length > 1 ? (
            <BarChart
              options={playbackLocationChartOptions}
              className="!grow-0 mt-auto"
            />
          ) : (
            <div className="w-full h-[340px] flex flex-col items-center justify-center text-sm">
              Not enough data
            </div>
          )}
        </BaseCard>
        <BaseCard>
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="A granular look at the sites where our embedded video was played"
          >
            Playback Location Embedded
          </h4>

          <BaseTable
            idProperty="playback-location-embedded"
            dataSource={formatObjectToArrOfObjects(
              dimensions.playback_location_embedded,
              "source",
              "views"
            )}
            columns={playbackLocationEmbeddedTable}
            style={{ minHeight: 283 }}
            className="mt-6 no-border"
            scrollHide={false}
            defaultSortInfo={viewsdefaultSortInfo}
          />
        </BaseCard>
      </div>

      <div className="grid gap-5 md:grid-cols-3">
        <BaseCard className="flex flex-col">
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="The top 10 queries users are searching for before clicking play on our videos appearing in results"
          >
            Search Term
          </h4>
          <BaseTable
            idProperty="search-term"
            dataSource={formatObjectToArrOfObjects(
              dimensions.search_term,
              "keyword",
              "views"
            )}
            columns={searchTermTable}
            style={{ minHeight: 283 }}
            className="mt-6 no-border"
            scrollHide={false}
            defaultSortInfo={viewsdefaultSortInfo}
          />
        </BaseCard>

        <BaseCard className="flex flex-col">
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="The geographic region of users watching our videos"
          >
            Country
          </h4>
          <BaseTable
            idProperty="country"
            dataSource={formatObjectToArrOfObjects(
              dimensions.country,
              "country",
              "views"
            )}
            columns={countryTable}
            style={{ minHeight: 283 }}
            className="mt-6 no-border"
            scrollHide={false}
            defaultSortInfo={viewsdefaultSortInfo}
          />
        </BaseCard>
        <BaseCard className="flex flex-col">
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="The user's declared gender, where known (not a complete dataset)"
          >
            Gender
          </h4>
          {Object.keys(dimensions?.gender).length > 1 ? (
            <DoughnutChart
              options={genderChartOptions}
              className="max-w-[420px]"
            />
          ) : (
            <div className="w-full h-full flex flex-col justify-start items-left">
              <div
                className="w-full h-full flex items-center justify-center font-bold"
                style={{
                  borderRadius: "100%",
                  border: "35px solid #f1f5f9",
                  width: "230px",
                  height: "230px",
                  marginTop: "1rem",
                  marginLeft: "1rem",
                }}
              >
                Not enough data
              </div>
            </div>
          )}
        </BaseCard>
      </div>

      <div className="grid gap-5 md:grid-cols-3">
        <BaseCard className="flex flex-col">
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="The user's age range, where known (not a complete dataset)"
          >
            Age
          </h4>
          {Object.keys(dimensions?.age_group).length > 1 ? (
            <DoughnutChart
              options={ageChartOptions}
              className="max-w-[420px]"
            />
          ) : (
            <div className="w-full h-full flex flex-col justify-start items-left">
              <div
                className="w-full h-full flex items-center justify-center font-bold"
                style={{
                  borderRadius: "100%",
                  border: "35px solid #f1f5f9",
                  width: "230px",
                  height: "230px",
                  marginTop: "1rem",
                  marginLeft: "1rem",
                }}
              >
                Not enough data
              </div>
            </div>
          )}
        </BaseCard>

        <BaseCard className="flex flex-col">
          <h4
            className="text-xl mb-4 uppercase text-violet font-bold w-fit"
            data-tippy-content="A granular look at the way users shared our videos"
          >
            Devices
          </h4>
          {devices.length > 1 ? (
            <BarChart
              options={devicesChartOptions}
              className="!grow-0 mt-auto"
            />
          ) : (
            <div className="w-full h-[340px] flex flex-col items-center justify-center text-sm">
              Not enough data
            </div>
          )}
        </BaseCard>

        <BaseCard className="flex flex-col">
          <h4 className="text-xl mb-4 uppercase text-violet font-bold w-fit">
            Sharing
          </h4>

          <BaseTable
            idProperty="sharing"
            dataSource={formatObjectToArrOfObjects(
              dimensions.sharing,
              "type",
              "views"
            )}
            columns={sharingTable}
            style={{ minHeight: 283 }}
            className="mt-6 no-border"
            scrollHide={false}
            defaultSortInfo={viewsdefaultSortInfo}
          />
        </BaseCard>
      </div>
    </>
  );
};

export default YoutubeDashboardDetails;
