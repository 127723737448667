import { useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";

import axios from "../../api/AxiosHttp";
import NewsLookupDashboardDetails from "../../partials/internal-analytics/NewsLookupDashboardDetails";

import BaseTitle from "../../components/base/BaseTitle";
import BaseCard from "../../components/base/BaseCard";
import BaseButton from "../../components/base/BaseButton";

const NewsLookupDashboard = ({ blurb }) => {
  const [loadingPage, setLoadingPage] = useState(false);
  const [newsData, setNewsData] = useState({});

  const articleID = useRef();

  const fetchNewsData = async () => {
    setLoadingPage(true);
    try {
      const endPoint = `/api/internal_news/${articleID.current.value}`;
      const { data } = await axios.get(endPoint);

      setNewsData(data);

      setLoadingPage(false);
    } catch (error) {
      setNewsData({ status: 0 });
      setLoadingPage(false);
    }
  };

  return (
    <>
      <BaseTitle heading="News Lookup" />

      {!blurb && (
        <BaseCard>
          <p className="mb-3">
            Every news article on our website has a unique identifier called a
            "News ID." This is a string of numbers that helps us keep track of
            our content. To find the News ID of an article, look at the website
            address (URL) when you're reading a news piece.
          </p>

          <p>For example:</p>
          <p className="mb-3">
            https://www.proactiveinvestors.co.uk/companies/news/
            <strong>1028782</strong>
            /ftse-100-live-stocks-climb-weak-construction-data-supports-rate-pause-1028782.html
          </p>

          <p>
            <strong>1028782</strong> is unique to this particular news item. You
            can use this ID to find view data.
          </p>
        </BaseCard>
      )}

      <div className="mb-12">
        <div className="w-96 flex items-end">
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="news-id">
              News ID
            </label>

            <input
              id="news-id"
              className="form-input w-full"
              type="number"
              placeholder="Number"
              ref={articleID}
              required
            />
          </div>
          <BaseButton
            className="ml-4"
            type="submit"
            label="Search"
            onClick={fetchNewsData}
            loading={loadingPage}
          />
        </div>
      </div>

      {!loadingPage ? (
        <>
          {newsData &&
          Object.keys(newsData).length > 0 &&
          newsData.status !== 0 ? (
            <NewsLookupDashboardDetails data={newsData} />
          ) : (
            <>
              {newsData.status === 0 && (
                <BaseCard>There is no article with this ID.</BaseCard>
              )}
            </>
          )}
        </>
      ) : (
        <div className="flex flex-grow justify-center items-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#e94a3e"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

export default NewsLookupDashboard;
